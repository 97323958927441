import { create } from "zustand"

interface ModalState {
    showModalDismissFlag: boolean;
    setShowModalDismissFlag: (showModalDismissFlag: boolean) => void

    showModalFlagUser: boolean;
    setShowModalFlagUser: (showModalFlagUser: boolean) => void

    showModalCloseJob: boolean;
    setShowModalCloseJob: (showModalCloseJob: boolean) => void;

    showModalRemoveJob: boolean;
    setShowModalRemoveJob: (showModalRemoveJob: boolean) => void

    showModalCloseAccount: boolean;
    setShowModalCloseAccount: (showModalCloseAccount: boolean) => void

    showModalRemoveUser: boolean;
    setShowModalRemoveUser: (showModalRemoveUser: boolean) => void

    showModalRemoveCompany: boolean;
    setShowModalRemoveCompany: (showModalRemoveCompany: boolean) => void;

    showModalEditCompany: boolean;
    setShowModalEditCompany: (showModalEditCompany: boolean) => void;

    showModalEditValidation: boolean;
    setShowModalEditValidation: (showModalEditValidation: boolean) => void

    showModalEditActiveType: boolean;
    setShowModalEditActiveType: (showModalEditActiveType: boolean) => void

}

export const useModalStore = create<ModalState>((set) => ({
    showModalCloseJob: false,
    setShowModalCloseJob: (modal) => set({ showModalCloseJob: !modal }),
    showModalRemoveJob: false,
    setShowModalRemoveJob: (modal) => set({ showModalRemoveJob: !modal }),
    showModalDismissFlag: false,
    setShowModalDismissFlag: (modal) => set({ showModalDismissFlag: !modal }),
    showModalFlagUser: false,
    setShowModalFlagUser: (modal) => set({ showModalFlagUser: !modal }),
    showModalCloseAccount: false,
    setShowModalCloseAccount: (modal) => set({ showModalCloseAccount: !modal }),

    showModalRemoveUser: false,
    setShowModalRemoveUser: (modal) => set({ showModalRemoveUser: !modal }),

    showModalRemoveCompany: false,
    setShowModalRemoveCompany: (modal) => set({ showModalRemoveCompany: !modal }),

    showModalEditCompany: false,
    setShowModalEditCompany: (modal) => set({ showModalEditCompany: !modal }),

    showModalEditValidation: false,
    setShowModalEditValidation: (modal) => set({ showModalEditValidation: !modal }),

    showModalEditActiveType: false,
    setShowModalEditActiveType: (modal) => set({ showModalEditActiveType: !modal })
}))