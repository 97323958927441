import React, { useRef } from "react";
import { useModalStore } from "src/Hooks/useModalStore";
import { Button, buttonVariants } from "../ui/Button";
import { X } from "lucide-react";

interface ModalSingleEmployerProps {
  type: string;
  section: string;
  children: React.ReactNode;
  onClick: (args1?: any, args2?: any) => void;
}

const ModalSingleEmployer = ({
  children,

  onClick,
  section,
  type,
}: ModalSingleEmployerProps) => {
  const [
    showModalCloseAccount,
    setShowModalCloseAccount,
    showModalRemoveUser,
    setShowModalRemoveUser,
  ] = useModalStore((state) => [
    state.showModalCloseAccount,
    state.setShowModalCloseAccount,
    state.showModalRemoveUser,
    state.setShowModalRemoveUser,
  ]);
  const modalRef = useRef<HTMLDivElement>(null);
  return (
    <div
      ref={modalRef}
      className="fixed top-0 w-full h-full bg-black/50 text-black z-50"
    >
      <div className="fixed  z-[50] w-[28%] left-[50%] shadow-xl translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white">
        <div className="flex items-center justify-between border-b px-8 py-6">
          <h1 className="text-xl font-bold">
            {section === "account-close" && (
              <>{type === "close" && "Close account"}</>
            )}
            {section === "remove-user" && (
              <>{type === "remove" && "Remove Account"}</>
            )}
          </h1>
          {showModalCloseAccount && (
            <Button
              variant="subtle"
              className="h-6 w-6 p-0 rounded-md "
              onClick={() => setShowModalCloseAccount(showModalCloseAccount)}
            >
              <X aria-label="close modal" className="h-4 w-4" />
            </Button>
          )}
          {showModalRemoveUser && (
            <Button
              variant="subtle"
              className="h-6 w-6 p-0 rounded-md "
              onClick={() => setShowModalRemoveUser(showModalRemoveUser)}
            >
              <X aria-label="close modal" className="h-4 w-4" />
            </Button>
          )}
        </div>
        <div className="mx-8 my-6">{children}</div>
        <div className="mx-8 my-6">
          {section === "account-close" && (
            <Button
              onClick={() => {
                onClick();
                setShowModalCloseAccount(showModalCloseAccount);
              }}
              className={buttonVariants({
                className: "w-full mt-4 mb-6 ",
              })}
            >
              Close Account
            </Button>
          )}
          {section === "remove-user" && (
            <Button
              onClick={() => {
                onClick();
                setShowModalRemoveUser(showModalRemoveUser);
              }}
              className={buttonVariants({
                className: "w-full mt-4 mb-6 ",
                variant: "subtle",
              })}
            >
              Remove Account
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalSingleEmployer;
