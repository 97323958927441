interface GraphData {
    name: string;
    total: number
}

export const graphData: GraphData[] = [
    { name: "Jan", total: Math.random() * 5 + 1 },
    { name: "Feb", total: Math.random() * 5 + 1 },
    { name: "Mar", total: Math.random() * 5 + 1 },
    { name: "Apr", total: Math.random() * 5 + 1 },
    { name: "May", total: Math.random() * 5 + 1 },
    { name: "Jun", total: Math.random() * 5 + 1 },
    { name: "Jul", total: Math.random() * 5 + 1 },
    { name: "Aug", total: Math.random() * 5 + 1 },
    { name: "Sep", total: Math.random() * 5 + 1 },
    { name: "Oct", total: Math.random() * 5 + 1 },
    { name: "Nov", total: Math.random() * 5 + 1 },
    { name: "Dec", total: Math.random() * 5 + 1 },
];
