import { Box, useTheme } from "@mui/material";
import React from "react";
import GeographyChart from "src/Components/Charts/GeographyChart";
import VerticalLayout from "src/layout/VerticalLayout";
import { tokens } from "src/theme";

const Geography = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <VerticalLayout>
      <Box m="20px">
        <h1 className="text-xl font my-4 font-medium">Geography</h1>

        {/* <Header title="Geography" subtitle="Simple Geography Chart" /> */}

        {/* <Box
          height="75vh"
          border={`1px solid ${colors.grey[100]}`}
          borderRadius="4px"
        >
          <GeographyChart />
        </Box> */}
      </Box>
    </VerticalLayout>
  );
};

export default Geography;
