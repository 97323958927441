export const StylesModal = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    zIndex: 200,
  },
  content: {
    position: "absolute",
    top: "-20px",
    left: "40px",
    right: "40px",
    bottom: "0px",
    border: "none",
    background: "#fff",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "4px",
    outline: "none",
    padding: "20px",
    backgroundColor: "transparent",
  },
};
