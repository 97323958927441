import { Card, CardContent, CardHeader } from "src/Components/ui/card";
import { Heading } from "src/Components/ui/heading";
import { Separator } from "src/Components/ui/separator";
import VerticalLayout from "src/layout/VerticalLayout";
import { ServicesData } from "src/types/types";
import { useState, useCallback, useRef, ElementRef } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { ReactComponent as Search } from "../assets/img/search.svg";
import { Input } from "src/Components/ui/Input";
import { getComparator, stableSort } from "src/lib/tableUtils";
import TableRowService from "src/Components/AllServices/TableRowService";
import { BarChart2, Check, XCircle } from "lucide-react";
import useServicesData from "src/api/useServicesData";
import { debounce } from "src/Utils/debounce";
import { useAtom } from "jotai";
import { serviceName, serviceStatus } from "src/atoms/atoms";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "src/Components/ui/select";

type Order = "asc" | "desc";

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ServicesData
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

interface HeadCell {
  disabledPadding: boolean;
  id: keyof ServicesData;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "status",
    disabledPadding: false,
    numeric: false,
    label: "Status",
  },
  {
    id: "service_id",
    disabledPadding: false,
    numeric: false,
    label: "Id",
  },
  {
    id: "name_service",
    disabledPadding: false,
    numeric: false,
    label: "Name",
  },
  {
    id: "last_results",
    disabledPadding: false,
    numeric: false,
    label: "Availability - Last 25d",
  },
  {
    id: "last_failed_date",
    disabledPadding: false,
    numeric: false,
    label: "Last Failed At",
  },
  {
    id: "last_run_date",
    disabledPadding: false,
    numeric: false,
    label: "Last Time",
  },
  {
    id: "actions",
    disabledPadding: false,
    numeric: false,
    label: "Actions",
  },
];

const Services = () => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof ServicesData>("service_id");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searched, setSearched] = useState("");
  const { isLoadingServices, services, rows, setRows } = useServicesData();
  const totalServices = services?.monitor?.length;
  const inputRef = useRef<ElementRef<"input">>(null);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ServicesData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const [nameService, setNameService] = useAtom(serviceName);
  const [statusService, setStatusSevice] = useAtom(serviceStatus);

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleSearch = (e: string) => {
    setNameService(e);
  };

  const optimisedSearch = useCallback(debounce(handleSearch), []);

  // useEffect(() => {
  //   //@ts-ignore
  //   setRows(services);
  // }, [services]);

  const requestSearch = (searchVal: string) => {
    setSearched(searchVal);
    const filteredRows = services?.monitor?.filter((row: any) => {
      return row.name_service.toLowerCase().includes(searchVal.toLowerCase());
    });
    //@ts-ignore
    setRows(filteredRows);
  };

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof ServicesData) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow
          sx={{
            "& th": {
              fontSize: 14,
              fontWeight: "600",
              color: "rgba(96, 96, 96, 96)",
              fontFamily: "inherit",
            },
          }}
        >
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disabledPadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <VerticalLayout>
      <div className="flex-1 space-y-4 p-8 pt-6">
        <Heading title="Services" description="Monitor all services" />
        <Separator />
        <div className="grid gap-4 grid-cols-3">
          <Card>
            <CardHeader className="flex flex-row items-center just">
              Monitors
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{totalServices}</div>
            </CardContent>
          </Card>
        </div>
        <Card>
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%" }}>
              <div className="flex items-center space-x-4 mt-3">
                {services?.monitor?.length !== 0 && (
                  <>
                    {services?.monitor?.filter(
                      (service: ServicesData) => service.status !== "1"
                    ).length === 0 ? (
                      <div className="border rounded flex items-center space-x-2 h-10 px-4 ml-4 bg-green-100">
                        <div className="h-4 w-4 rounded-full bg-green-500 flex items-center justify-center">
                          <Check className="text-white  h-3 w-3 rounded-full" />
                        </div>

                        <p className="text-sm font-medium text-[#333]">
                          All Services Up and Running
                        </p>
                      </div>
                    ) : (
                      <div className="border rounded flex items-center space-x-2 h-10 px-4 ml-4 bg-red-100">
                        <div className="h-4 w-4 rounded-full bg-red-500 flex items-center justify-center">
                          <XCircle className="text-white h-3 w-3 rounded-full" />
                        </div>
                        <p className="text-sm font-medium text-[#333]">
                          Something went wrong
                        </p>
                      </div>
                    )}
                  </>
                )}

                <div className="flex items-center justify-start relative  ">
                  <Search className="h-4 absolute left-[17rem] top-[12px] fill-[#1d90b3]" />
                  <Input
                    ref={inputRef}
                    className="w-[300px] ml-2"
                    // value={searched}
                    onChange={(e) => optimisedSearch(e.target.value)}
                    placeholder="Type to filter..."
                  />
                </div>
                <div className="">
                  <Select
                    onValueChange={(e) => {
                      setStatusSevice(e);
                    }}
                  >
                    <SelectTrigger className="w-[200px]">
                      <div className="flex items-center space-x-2">
                        <BarChart2 className=" text-green-500 h-5 w-5 rounded-full" />{" "}
                        <p>Status</p>
                      </div>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem key={"1"} value="200">
                        Passing
                      </SelectItem>
                      <SelectItem key={"0"} value="400">
                        Failure
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <TableContainer
                sx={{
                  marginTop: 2,
                  minHeight: 623,
                  // maxHeight: 840,
                  "&::-webkit-scrollbar": {
                    width: 10,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "cyan",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "lightblue",
                    borderRadius: 2,
                  },
                }}
              >
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {stableSort(rows as any, getComparator(order, orderBy))
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any, index) => {
                        const isItemSelected = isSelected(row.id as string);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRowService
                            handleClick={handleClick}
                            isItemSelected={isItemSelected}
                            labelId={labelId}
                            row={row}
                          />
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={rows?.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                component={"div"}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </Card>
      </div>
    </VerticalLayout>
  );
};

export default Services;
