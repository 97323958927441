import { BASE_URL, CITIES } from "src/Config/api.config";

export const getCities = async (name: string, token: string) => {
  const data = await fetch(
    `${BASE_URL}${CITIES}?country_code=ES${name !== "" ? `?name=${name}` : ""}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => res.json());

  return data.result;
};
