import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Eye } from "../../assets/img/eye.svg";
import { BASE_URL, LOGIN } from "../../Config/api.config";
import RequestErrorModal from "../RequestErrorPopup";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";
import { useTokenStore } from "src/Hooks/useTokenStore";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "../ui/use-toast";
import { Form, FormControl, FormField, FormItem, FormLabel } from "../ui/Form";
import { userProfileStore } from "src/Utils/useProfileStore";
interface PasswordProps {
  state: {
    username: string;
    password: string;
  };
  dispatch: React.Dispatch<{ key: string; value: string; type: string }>;
}

const formSchema = z.object({
  password: z.string().min(1),
});

const Password = ({ state, dispatch }: PasswordProps) => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const [warning, setWarning] = useState(false);
  let navigate = useNavigate();
  const [adminInfo, setAdminInfo] = useLocalStorage("adminInfo", "");
  const [isLoading, setIsLoading] = useState(false);
  const setToken = useTokenStore((s) => s.setAccessToken);
  const setProfileTypesLocal = userProfileStore((s) => s.setProfile);

  let data = new FormData();
  data.append("mode", "password");
  data.append("id", adminData.id);

  const checkPassword = (password: string) => {
    data.append("password", password);
    setIsLoading(true);
    fetch(`${BASE_URL}${LOGIN}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${adminData.uid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setAdminInfo(data);
          setProfileTypesLocal({ profileTypeToken: data?.result.type });
          console.log(data);
          setAdminData((prev: { uid: string }) => ({
            ...prev,
            uid: data.result.token,
          }));
          setToken(data.result.token);
          submit();
        } else {
          toast({
            title: "Something went wrong",
            description: `${data.result.error_msg}`,
            variant: "destructive",
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      setIsLoading(true);
      checkPassword(values.password);
    } catch (error) {
      toast({
        title: "Something went wrong",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  function submit() {
    navigate("/analytics");
  }
  return (
    <>
      <div className="absolute top-0 left-[50%]">
        <RequestErrorModal warning={warning} />
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="relative w-full mt-3">
            <Eye
              className="absolute top-[44px] left-4 w-fit h-4 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input
                      autoFocus
                      type={!showPassword ? "password" : "text"}
                      disabled={isLoading}
                      {...field}
                      className="w-full outline-none border mt-6 rounded h-10 px-12 text-sm"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <div className="pt-6 space-x-2 flex items-center justify-end w-full">
            <Button disabled={isLoading} type="submit">
              Log in
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};

export default Password;
