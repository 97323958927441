import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { AllEmployersData } from "src/types/types";

interface TableRowEmployerDataProps {
  row: AllEmployersData;
  isItemSelected: boolean;
  labelId: string;
  handleClick: (args: React.MouseEvent<unknown>, name: string) => void;
}

const TableRowEmployerData = ({
  handleClick,
  isItemSelected,
  labelId,
  row,
}: TableRowEmployerDataProps) => {
  const navigate = useNavigate();
  const [employerInfo, setEmployerInfo] = useLocalStorage("employerInfo", "");
  return (
    <TableRow
      sx={{
        "& td": {
          fontSize: 13,
          fontWeight: "400",
          // color: "#333",
          fontFamily: "inherit",
          cursor: "pointer",
        },
      }}
      hover
      onClick={() => {
        setEmployerInfo(row);
        navigate(`//all-employers/${row.user_id}`);
      }}
      // onClick={(event) => handleClick(event, row.user_id as string)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.user_id}
      selected={isItemSelected}
    >
      <TableCell>{row.title}</TableCell>
      <TableCell>{row.overview}</TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell>{row.date_created}</TableCell>
      <TableCell>{row.plan}</TableCell>
      <TableCell>{row.credits}</TableCell>
    </TableRow>
  );
};

export default TableRowEmployerData;
