import React from "react";
import { Skeleton } from "../ui/Skeleton";

const CardJobSkeleton = () => {
  return (
    <div className="flex flex-col bg-white border-border border p-4 rounded-lg w-[300px]">
      <div className="flex flex-col gap-4 mx-8 mt-4 mb-2">
        <div className="flex items-center">
          <Skeleton className="h-5 w-1/2 " />
        </div>
        <Skeleton className="h-4 w-1/3" />
        <Skeleton className="h-4 w-1/3" />
      </div>
    </div>
  );
};

export default CardJobSkeleton;
