/* eslint-disable react/jsx-pascal-case */
import React, { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { ReactComponent as Logo } from "../../assets/img/logo_2.svg";
import { IconButton } from "@mui/material";

import { ReactComponent as Logout } from "../../assets/img/logout2.svg";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, buttonVariants } from "../ui/Button";
import { cn } from "src/lib/utils";
import { BASE_URL, LOGOUT } from "src/Config/api.config";
import { useTokenStore } from "src/Hooks/useTokenStore";
import { dashboardConfig } from "src/Config/dashboard";
import SingleTab from "./SingleTab";
import { useTheme } from "@mui/material";
import { ColorModeContext } from "../../theme";
import { Icons } from "../ui/Icons";
import { userProfileStore } from "src/Utils/useProfileStore";

interface VerticalNavBarProps {
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
}

const VerticalNavBar = ({
  selectedTab,
  setSelectedTab,
}: VerticalNavBarProps) => {
  const navigate = useNavigate();
  const params = useLocation();
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const setProfileTypesLocal = userProfileStore((s) => s.setProfile);

  const setTokens = useTokenStore((s) => s.setAccessToken);
  const logoutData = new FormData();

  logoutData.append("id", adminData.id);
  useEffect(() => {
    if (params.pathname === "/analytics") {
      setSelectedTab("analytics");
    }
  }, [params]);
  // useEffect(()=>{
  //   if(location.pathname ==="/analytics"){
  //   }
  // },[location])
  const logoutUser = () => {
    try {
      fetch(`${BASE_URL}${LOGOUT}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ` + adminData.uid,
        },
        body: logoutData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.result.token === "disabled") {
            // localStorage.clear();

            setTokens("");
            setProfileTypesLocal({ profileTypeToken: null });
            navigate("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="h-screen bg-white dark:bg-background w-60 border-r border-border flex  flex-col items-center px-4 mx-auto  pt-[20px] shadow transition overflow-y-auto fixed left-0">
      <div className=" border-b pb-4 ">
        <div
          className="cursor-pointer"
          onClick={() => {
            if (window.location.pathname !== "/") {
              navigate("/analytics");
            }
            setSelectedTab("dashboard");
          }}
        >
          <Logo className="h-10 object-cover mx-auto" />
        </div>
        <h1 className=" text-sm lg:text-xl text-center font-medium mt-5">
          Admin
        </h1>
      </div>
      <div className=" w-full border-b ">
        <ul className=" tracking-wide border-b ">
          {dashboardConfig.mainNav.map((nav) => (
            <SingleTab
              key={`${nav.href}${nav.title}`}
              nav={nav}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
            />
          ))}
        </ul>
        <div className="flex items-center w-[200px] gap-1 ml-2 ">
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <Icons.moon className="h-4 w-4 text-slate-500" />
            ) : (
              <Icons.sun className="h-4 w-4 " />
            )}
          </IconButton>
          <p className="text-[15px]">Theme</p>
        </div>
      </div>
      <div className=" w-full   my-2 ">
        <Button
          onClick={() => logoutUser()}
          className={cn(
            buttonVariants({ variant: "ghost" }),
            "flex items-center gap-3 cursor-pointer"
          )}
        >
          <Logout className="h-4 w-fit " />
          <p className=" cursor-pointer text-lg tracking-wide whitespace-nowrap text-center">
            Log out
          </p>
        </Button>
      </div>
    </div>
  );
};

export default VerticalNavBar;
