import React from "react";
import { Checkbox } from "../ui/Checkbox";
import { useAtom } from "jotai";
import {
  appliedJobsAmountFilter,
  connectionsAmountFilter,
  userTypeFilter,
} from "src/atoms/atoms";

type Props = {};

const FilterUsers = (props: Props) => {
  const [userType, setUserType] = useAtom(userTypeFilter);
  const [appliedJobsAmount, setAppliedJobsAmount] = useAtom(
    appliedJobsAmountFilter
  );
  const [connectionAmount, setConnectionAmount] = useAtom(
    connectionsAmountFilter
  );
  const handleChangeUserType = (e: string) => {
    if (!userType.includes(e)) {
      setUserType((prev: string[]) => [...prev, e]);
    }
  };

  const removeChangeUserType = (e: string) => {
    setUserType(userType.filter((type) => type !== e));
  };

  return (
    <div className="flex flex-col  justify-start relative bg-white border-r py-2 gap-4 shadow">
      <div className="flex items-start justify-start gap-14  pl-4 py-2">
        {/* <h1 className="text-[14px] font-semibold">User Type</h1>
        <div className="flex items-center mt-2 gap-4 text-[14px]">
          <Checkbox />
          <p>Worker</p>
        </div> */}
        <div className="flex flex-col pl-4 py-2">
          <h1 className="text-[14px] font-semibold">User Type</h1>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox
              onCheckedChange={(checked) => {
                if (checked) {
                  handleChangeUserType("1");
                } else {
                  removeChangeUserType("1");
                }
              }}
              value="1"
            />
            <p>Remote</p>
          </div>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox
              onCheckedChange={(checked) => {
                if (checked) {
                  handleChangeUserType("2");
                } else {
                  removeChangeUserType("2");
                }
              }}
              value="2"
            />
            <p>Hybrid</p>
          </div>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox
              onCheckedChange={(checked) => {
                if (checked) {
                  handleChangeUserType("3");
                } else {
                  removeChangeUserType("3");
                }
              }}
              value="3"
            />
            <p>Presential</p>
          </div>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox
              onCheckedChange={(checked) => {
                if (checked) {
                  handleChangeUserType("4");
                } else {
                  handleChangeUserType("4");
                }
              }}
              value="4"
            />
            <p>No matter</p>
          </div>
        </div>
        {/* <div className="flex flex-col justify-center  pl-4 rounded  py-2">
          <h1 className="text-[14px] font-semibold">Category</h1>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox
              value={"20"}
              color="white"
              onCheckedChange={(e) => console.log(e)}
            />
            <p>Atención al cliente</p>
          </div>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox value={"20"} color="white" />
            <p>Agricultura, ganadería</p>
          </div>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox value={"20"} color="white" />
            <p>Hostelería y turismo</p>
          </div>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox value={"20"} color="white" />
            <p>Mantenimiento</p>
          </div>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox value={"20"} color="white" />
            <p>Cuidado de mayores</p>
          </div>
        </div> */}
        <div className="flex flex-col justify-center  pl-4 rounded  py-2">
          <h1 className="text-[14px] font-semibold">Applied Jobs</h1>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox
              value={"20"}
              color="white"
              onCheckedChange={(checked) => {
                if (checked) {
                  setAppliedJobsAmount("1");
                }
              }}
            />
            <p>1 - 3</p>
          </div>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox
              onCheckedChange={(checked) => {
                if (checked) {
                  setAppliedJobsAmount("3");
                }
              }}
              color="white"
            />
            <p>3 - 5</p>
          </div>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox
              onCheckedChange={(checked) => {
                if (checked) {
                  setAppliedJobsAmount("5");
                }
              }}
              color="white"
            />
            <p>5 - 10</p>
          </div>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox
              onCheckedChange={(checked) => {
                if (checked) {
                  setAppliedJobsAmount("10");
                }
              }}
              color="white"
            />
            <p>Más de 10</p>
          </div>
        </div>
        {/* <div className="flex flex-col justify-center  pl-4 rounded">
          <h1 className="text-[14px] font-semibold">Messaged</h1>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox
              value={"20"}
              color="white"
              onCheckedChange={(e) => console.log(e)}
            />
            <p>0 - 4 Employers</p>
          </div>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox value={"20"} color="white" />
            <p>4 - 10 Employers</p>
          </div>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox value={"20"} color="white" />
            <p>10 - 20 Employers</p>
          </div>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox value={"20"} color="white" />
            <p>20 - 40 Employers</p>
          </div>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox value={"20"} color="white" />
            <p>Más de 40 Employers</p>
          </div>
        </div> */}
        <div className="flex flex-col justify-center  pl-4 rounded py-2">
          <h1 className="text-[14px] font-semibold">Connections</h1>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox
              onCheckedChange={(checked) => {
                if (checked) {
                  setConnectionAmount("0");
                }
              }}
            />
            <p>0 - 2 </p>
          </div>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox
              onCheckedChange={(checked) => {
                if (checked) {
                  setConnectionAmount("0");
                }
              }}
            />
            <p>2 - 5 </p>
          </div>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox
              onCheckedChange={(checked) => {
                if (checked) {
                  setConnectionAmount("0");
                }
              }}
            />
            <p>5 - 10 </p>
          </div>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox
              onCheckedChange={(checked) => {
                if (checked) {
                  setConnectionAmount("0");
                }
              }}
            />
            <p>10 - 20 </p>
          </div>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <Checkbox />
            <p>Más de 20 </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterUsers;
