import React, { Dispatch, SetStateAction } from "react";
import { ReactComponent as Logo } from "../../assets/img/logo_2.svg";
import { ReactComponent as Logout } from "../../assets/img/logout2.svg";
import { Button, buttonVariants } from "../ui/Button";
import { cn } from "src/lib/utils";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { BASE_URL, LOGOUT } from "src/Config/api.config";
import { useTokenStore } from "src/Hooks/useTokenStore";
import { useNavigate } from "react-router-dom";
import { dashboardConfig } from "src/Config/dashboard";
import { userProfileStore } from "src/Utils/useProfileStore";

interface HorizontalNavBarProps {
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
}
const HorizontalNavBar = ({
  selectedTab,
  setSelectedTab,
}: HorizontalNavBarProps) => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const setTokens = useTokenStore((s) => s.setAccessToken);
  const navigate = useNavigate();
  const setProfileTypesLocal = userProfileStore((s) => s.setProfile);

  const logoutData = new FormData();

  logoutData.append("id", adminData.id);

  const logoutUser = () => {
    try {
      fetch(`${BASE_URL}${LOGOUT}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ` + adminData.uid,
        },
        body: logoutData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.result.token === "disabled") {
            setTokens("");
            // localStorage.clear();
            setProfileTypesLocal({ profileTypeToken: null });

            navigate("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex justify-between items-center w-full bg-white border min-h-[6vh] px-12">
      <div
        onClick={() => {
          if (window.location.pathname !== "/") {
            navigate("");
          }
          setSelectedTab("dashboard");
        }}
        className="cursor-pointer"
      >
        <Logo className="h-[4vh]" />
      </div>
      <ul className="flex mt-4 laptop:gap-12 gap-6 laptop:pr-6 laptop:text-lg text-base tracking-wide">
        {dashboardConfig.mainNav.map((nav) => (
          <Button
            key={`${nav.title}${nav.href}`}
            disabled={nav.disabled}
            onClick={() => setSelectedTab(nav.href)}
            className={cn(
              buttonVariants({ variant: "ghost" }),
              `flex gap-3  items-center mb-4 cursor-pointer  ${
                selectedTab === nav.href ? "font-bold" : " "
              }`
            )}
          >
            <li>{nav.title}</li>
          </Button>
        ))}

        <Button
          onClick={() => logoutUser()}
          className={cn(
            buttonVariants({ variant: "subtle" }),
            `flex gap-3 items-center mb-4 text-sm  cursor-pointer `
          )}
        >
          <Logout className="h-4 w-fit  mt-1 " />
          <li>Logout</li>
        </Button>
      </ul>
    </div>
  );
};

export default HorizontalNavBar;
