import { ColumnDef } from "@tanstack/react-table";

export type SubscriptionsTypes = {
  company_name: string;
  company_id: string;
  date_start: string;
  date_end: string;
  subscription_plan: string;
  credits: string;
};

export const columns: ColumnDef<SubscriptionsTypes>[] = [
  {
    accessorKey: "company_id",
    header: "Company Id",
  },
  {
    accessorKey: "company_name",
    header: "Company Id",
  },
  {
    accessorKey: "date_start",
    header: "Date Start",
  },
  {
    accessorKey: "date_end",
    header: "Date End",
  },
  {
    accessorKey: "subscription_plan",
    header: "Subscription Plan",
  },
  {
    accessorKey: "credits",
    header: "Credits",
  },
];
