import { AllUsersData } from "src/types/types";

export const allUsersContent: AllUsersData[] = [
    {
        user_id: "1",
        title: "Camarero",
        overview: "Soy un camarero",
        applied_jobs: "66",
        category: "Waiter",
        connections: "3",
        date_created: "2023-06-30",
        email: "ismael-camarero@gmail.com",
        first_name: "Ismael",
        last_name: "Camarero",
        messaged: "10",
        skills: ["washing dishes", "serving food"],
        validated: "1"
    },
    {
        user_id: "1",
        title: "Camarero",
        overview: "Soy un camarero",
        applied_jobs: "66",
        category: "Waiter",
        connections: "3",
        date_created: "2023-06-30",
        email: "ismael-camarero@gmail.com",
        first_name: "Ismael",
        last_name: "Camarero",
        messaged: "10",
        skills: ["washing dishes", "serving food"],
        validated: "1"
    },
    {
        user_id: "1",
        title: "Camarero",
        overview: "Soy un camarero",
        applied_jobs: "66",
        category: "Waiter",
        connections: "3",
        date_created: "2023-06-30",
        email: "ismael-camarero@gmail.com",
        first_name: "Ismael",
        last_name: "Camarero",
        messaged: "10",
        skills: ["washing dishes", "serving food"],
        validated: "1"
    },
    {
        user_id: "1",
        title: "Camarero",
        overview: "Soy un camarero",
        applied_jobs: "66",
        category: "Waiter",
        connections: "3",
        date_created: "2023-06-30",
        email: "ismael-camarero@gmail.com",
        first_name: "Ismael",
        last_name: "Camarero",
        messaged: "10",
        skills: ["washing dishes", "serving food"],
        validated: "1"
    },
    {
        user_id: "1",
        title: "Camarero",
        overview: "Soy un camarero",
        applied_jobs: "66",
        category: "Waiter",
        connections: "3",
        date_created: "2023-06-30",
        email: "ismael-camarero@gmail.com",
        first_name: "Ismael",
        last_name: "Camarero",
        messaged: "10",
        skills: ["washing dishes", "serving food"],
        validated: "1"
    }
]