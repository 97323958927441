import React, { useEffect, useState } from "react";
import { ReactComponent as CircleUp } from "../../assets/img/circle-up.svg";
import { ReactComponent as Equals } from "../../assets/img/equals.svg";
import { ReactComponent as CircleDown } from "../../assets/img/circle-down.svg";
import { BASE_URL, PRECLIENT_REGISTER } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/useLocalStorage";

interface InfoStatsProps {
  timeComparison: string;
}

const InfoStats = ({ timeComparison }: InfoStatsProps) => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const [preregisters, setPregisters] = useState<
    {
      client_name: string;
      created_at: string;
      email: string;
      id: string;
    }[]
  >([]);

  const getPregisters = async () => {
    const data = await fetch(`${BASE_URL}${PRECLIENT_REGISTER}`, {
      headers: {
        Authorization: "Bearer " + adminData?.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPregisters(data.result.preclients);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
    return data;
  };

  useEffect(() => {
    getPregisters();
  }, []);
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4">
      {/* <div className="flex flex-col "> */}
      <div className="flex items-center bg-white rounded-lg p-4 shadow w-50 justify-center">
        <div className="flex flex-col items-center ">
          <p className="xl:text-3xl text-lg font-bold text-[#1d90b3]">
            {preregisters?.length}
          </p>
          <p className="text-sm text-[#1d90b3] whitespace-nowrap">
            in <span className="font-bold">waitlist</span>
          </p>
        </div>
      </div>
      <div className="flex items-center bg-white rounded-lg p-4 shadow">
        <div className="flex flex-col items-center w-[30%] ">
          <p className="xl:text-3xl text-lg font-bold text-[#1d90b3]">120</p>
          <p className="text-sm text-[#1d90b3] whitespace-nowrap">
            new <span className="font-bold">workers</span>
          </p>
        </div>
        <div className="flex gap-3 text-[#1d90b3] items-center  w-[70%]">
          <div className="w-[50%] flex justify-end">
            <CircleUp className="xl:h-8 h-6 fill-green-500 " />
          </div>
          <div className="flex flex-col w-[50%] whitespace-nowrap justify-start">
            <p className="font-bold">+18</p>
            <p className="text-sm">than {timeComparison}</p>
          </div>
        </div>
      </div>

      <div className="flex items-center  bg-white rounded-lg shadow p-4">
        <div className="flex flex-col items-center w-[30%]">
          <p className="xl:text-3xl text-lg font-bold text-[#1d90b3]">48</p>
          <p className="text-sm text-[#1d90b3] whitespace-nowrap">
            new <span className="font-bold">employers</span>
          </p>
        </div>
        <div className="flex gap-3 text-[#1d90b3] items-center w-[70%]">
          <div className="w-[50%] flex justify-end">
            <CircleDown className="xl:h-8 h-6 fill-red-500 " />
          </div>
          <div className="flex flex-col w-[50%] whitespace-nowrap justify-start">
            <p className="font-bold">-5</p>
            <p className="text-sm">than {timeComparison}</p>
          </div>
        </div>
        {/* </div> */}
      </div>
      {/* <div className="flex flex-col "> */}
      {/* <div className="flex items-center bg-white rounded-lg shadow p-4">
        <div className="flex flex-col items-center w-[30%]">
          <p className="xl:text-3xl text-lg font-bold text-[#1d90b3]">56</p>
          <p className="text-sm text-[#1d90b3] whitespace-nowrap">
            new <span className="font-bold">job posts</span>
          </p>
        </div>
        <div className="flex gap-3 text-[#1d90b3] items-center w-[70%]">
          <div className="w-[50%] flex justify-end">
            <CircleUp className="xl:h-8 h-6 fill-green-500 " />
          </div>
          <div className="flex flex-col w-[50%] whitespace-nowrap justify-start">
            <p className="font-bold">+22</p>
            <p className="text-sm">than {timeComparison}</p>
          </div>
        </div>
      </div> */}

      <div className="flex items-center bg-white rounded-lg shadow p-4 ">
        <div className="flex flex-col items-center w-[30%]">
          <p className="xl:text-3xl text-lg font-bold text-[#1d90b3]">22</p>
          <p className="text-sm text-[#1d90b3] whitespace-nowrap">
            new <span className="font-bold">connections</span>
          </p>
        </div>
        <div className="flex gap-3 text-[#1d90b3] items-center w-[70%]">
          <div className="w-[50%] flex justify-end">
            <Equals className="xl:h-8 h-6 w-6 fill-white rounded-full bg-yellow-500 xl:w-8 p-1" />
          </div>
          <div className="flex flex-col w-[50%] whitespace-nowrap justify-start">
            <p className="font-bold">+0</p>
            <p className="text-sm">than {timeComparison}</p>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default InfoStats;
