import { TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import { BASE_URL, BASE_URL_IMG, CLIENTS_WALLET } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/useLocalStorage";
import image from "../../assets/img/shadow.png";
import { ReactComponent as Check } from "../../assets/img/check.svg";
import { ReactComponent as Cross } from "../../assets/img/cross.svg";
import { HandleMonthName } from "src/Utils/HandleMonthName";

interface TableRowEmployerDataProps {
  row: {
    id: string;
    profile_image: string;
    first_name: string;
    last_name: string;
    date_created: string;
    email: string;
    validated: string;
  };
  isItemSelected: boolean;
  labelId: string;
  handleClick: (args: React.MouseEvent<unknown>, name: string) => void;
}

const TableRowEmployerData = ({
  row,
  isItemSelected,
  labelId,
  handleClick,
}: TableRowEmployerDataProps) => {
  const [creditsUser, setCreditsUser] = useState();
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const [showCredits, setShowCredits] = useState(false);

  const getCredits = (id: string) => {
    fetch(`${BASE_URL}${CLIENTS_WALLET}?client_id=${id}&mode=balance`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${adminData.uid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setCreditsUser(data.result);
        }
        setShowCredits(true);
      })
      .catch((err) => console.log(err));
  };
  return (
    <TableRow
      sx={{
        "& td": {
          fontSize: 13,
          fontWeight: "400",
          color: "#333",
          fontFamily: "inherit",
        },
      }}
      hover
      onClick={(event) => handleClick(event, row.id as any)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      <TableCell>
        {!row.profile_image ? (
          <img
            loading="lazy"
            src={image}
            alt=""
            // width={60}
            // height={60}
            className="w-12 object-cover rounded-full"
          />
        ) : (
          <img
            width={60}
            height={60}
            src={`${BASE_URL_IMG}${row.profile_image}`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; //prevents looping
              currentTarget.src = image;
            }}
            className="w-12 object-cover rounded-full"
            alt=""
          />
        )}
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.first_name} {row.last_name}
      </TableCell>
      <TableCell>3</TableCell>

      <TableCell>{row.email}</TableCell>
      <TableCell>
        {row?.validated === "0" ? (
          <Cross className="fill-red-700 h-4 w-fit" />
        ) : (
          <Check className="fill-green-600 h-4 w-fit" />
        )}
      </TableCell>
      <TableCell>
        <div
          onClick={() => {
            if (!showCredits && !creditsUser) {
              getCredits(row.id);
            }
          }}
        >
          <p className="text-[#333] cursor-pointer flex items-center gap-2">
            {showCredits && creditsUser && creditsUser}
            {showCredits && !creditsUser && "0"}
            {!showCredits && !creditsUser && "Show Credits"}
          </p>
        </div>
      </TableCell>
      <TableCell>
        {HandleMonthName(row?.date_created.slice(5, 7)).slice(0, 3)}{" "}
        {row?.date_created.slice(8, 10)}, {row?.date_created.slice(0, 4)}
      </TableCell>
    </TableRow>
  );
};

export default TableRowEmployerData;
