import { ColumnDef } from "@tanstack/react-table";
import { CompaniesData } from "src/types/types";
import { CellAction } from "./cell-action";

export const columns: ColumnDef<CompaniesData>[] = [
  {
    accessorKey: "id",
    header: "Id",
  },
  {
    accessorKey: "name",
    header: "Company Name",
  },
  {
    accessorKey: "cif",
    header: "CIF",
  },
  {
    accessorKey: "phone",
    header: "Phone Number",
  },
  {
    accessorKey: "active",
    header: "Active Status",
  },
  {
    accessorKey: "validated",
    header: "Validated",
  },
  {
    id: "actions",
    cell: ({ row }) => <CellAction data={row.original} />,
  },
];
