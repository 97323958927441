import { BASE_URL } from "src/Config/api.config";
import useLocalStorage from "./useLocalStorage";
import {
  CLOSE_EXPIRE_JOBS,
  CLOSE_HIRED_REQUIRED_JOBS,
  DELETED_CLOSED_JOB,
  DISABLE_TOKENS,
  SERVICES_LOGOUT_CHAT,
} from "src/apiUrls";

const useServicesProcesses = () => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const getServicesLogoutChat = async () => {
    const data = await fetch(`${BASE_URL}${SERVICES_LOGOUT_CHAT}`, {
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((err) => console.error(err));
  };

  const getServicesDisableToken = async () => {
    const data = await fetch(`${BASE_URL}${DISABLE_TOKENS}`, {
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  };
  const getServicesDeleteCloseJob = async () => {
    const data = await fetch(`${BASE_URL}${DELETED_CLOSED_JOB}`, {
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  };
  const getServicesClosedHiredRequiredJobs = async () => {
    const data = await fetch(`${BASE_URL}${CLOSE_HIRED_REQUIRED_JOBS}`, {
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  };
  const getServicesClosedExpireJobs = async () => {
    const data = await fetch(`${BASE_URL}${CLOSE_EXPIRE_JOBS}`, {
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  };

  return {
    getServicesLogoutChat,
    getServicesClosedExpireJobs,
    getServicesClosedHiredRequiredJobs,
    getServicesDeleteCloseJob,
    getServicesDisableToken,
  };
};

export default useServicesProcesses;
