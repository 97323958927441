import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware";



interface ProfileStoreState {
    accessToken: string | null
}

interface ProfileStoreActions {
    setAccessToken: (accessToken: string | null) => void
}
type UseProfileStore = ProfileStoreState & ProfileStoreActions
const useTokenStore = create<UseProfileStore>()(
    persist((set) => ({
        accessToken: null,
        setAccessToken: (accessToken) => set({ accessToken: accessToken })
    }),
        {
            name: "flexi_admin_user_token",
            storage: createJSONStorage(() => localStorage)
        })
)

export { useTokenStore }
