import React, { useRef } from "react";
import { useModalStore } from "src/Hooks/useModalStore";
import Modal from "react-modal";
import { Button } from "../ui/Button";
import { X } from "lucide-react";

interface ModalCompanyProps {
  children: React.ReactNode;
  title: string;
  description: string;
  isOpen: boolean;
}

const ModalCompany = ({ children, description, title }: ModalCompanyProps) => {
  const [showModalRemoveCompany, setShowModalRemoveCompany] = useModalStore(
    (state) => [state.showModalRemoveCompany, state.setShowModalRemoveCompany]
  );
  const modalRef = useRef<HTMLDivElement>(null);

  const StylesModal: any = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.35)",
      zIndex: 200,
    },
    content: {
      position: "absolute",
      top: "-20px",
      left: "40px",
      right: "40px",
      bottom: "0px",
      border: "none",
      background: "#fff",
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      borderRadius: "4px",
      outline: "none",
      padding: "20px",
      backgroundColor: "transparent",
    },
  };

  return (
    <Modal style={StylesModal} isOpen={showModalRemoveCompany}>
      {/* <div className="fixed top-0  bg-black/50 text-black z-50"> */}
      {/* // ref={modalRef} className="fixed top-0  bg-black/50 text-black z-50"> */}
      <div className="fixed  z-[50] w-[28%] left-[50%] shadow-xl translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white">
        <div className="flex items-start justify-between border-b px-8 py-6">
          <div className="flex flex-col">
            <h1 className="text-xl font-bold">{title}</h1>
            <h3 className="mt-2 font-medium">{description}</h3>
          </div>
          <Button
            variant="subtle"
            className="h-6 w-6 p-0 rounded-md "
            onClick={() => setShowModalRemoveCompany(showModalRemoveCompany)}
          >
            <X aria-label="close modal" className="h-4 w-4" />
          </Button>
        </div>
        <div>{children}</div>
      </div>
      {/* </div> */}
    </Modal>
  );
};

export default ModalCompany;
