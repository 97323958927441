import { useAtom } from "jotai";
import { X } from "lucide-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { JobData, columns } from "src/Components/AllJobs/columns";
import { DataTable } from "src/Components/AllJobs/data-table";
import SearchCompanies from "src/Components/searchCommands/SearchCompanies";
import SearchPostcodes from "src/Components/searchCommands/SearchPostcodes";
import { Checkbox } from "src/Components/ui/Checkbox";
import { Input } from "src/Components/ui/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/Components/ui/select";
import { BASE_URL, JOBS } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { debounce } from "src/Utils/debounce";
import {
  applicantsAmountFilter,
  categoriesFilter,
  citiesFilter,
  companyFilter,
  connectionsAmountFilter,
  jobStatusFilter,
  jobTypeFilter,
  postcodeFilter,
} from "src/atoms/atoms";
import { getCategories } from "src/getContent/get-categories";
import { getCities } from "src/getContent/get-cities";
import VerticalLayout from "src/layout/VerticalLayout";
import { AllCategories, AllCities } from "src/types/types";
import dayjs, { Dayjs } from "dayjs";

import { useOnClickOutside } from "src/Hooks/useOnClickOutside";
import { Card, CardTitle } from "src/Components/ui/card";
import { Heading } from "src/Components/ui/heading";
import { Separator } from "src/Components/ui/separator";
import { DatePickerWithRange } from "src/Components/ui/date-rangepicker";
import { useQuery } from "@tanstack/react-query";

const INITIAL_DATE_STATE = "2023-06-29";

const AlJobsTable = () => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const [selectedSort, setSelectedSort] = useState("applicants");
  const [allJobs, setAllJobs] = useState<JobData[]>([]);

  const [categories, setCategories] = useAtom(categoriesFilter);
  const [categoriesIds, setCategoryIds] = useState<string[]>([]);
  const [categoriesToShow, setCategoriesToShow] = useState<AllCategories[]>([]);

  const [cities, setCities] = useAtom(citiesFilter);
  const [citiesId, setCitiesId] = useState<string[]>([]);
  const [citiesToShow, setCitiesToShow] = useState<AllCities[]>([]);

  const [postcodeSelected, setPostcodeSelected] = useAtom(postcodeFilter);
  const [companySelected, setCompanySelected] = useAtom(companyFilter);

  const [jobStatus, setJobStatus] = useAtom(jobStatusFilter);
  const [jobType, setJobType] = useAtom(jobTypeFilter);

  const [applicantsAmount, setApplicantAmount] = useAtom(
    applicantsAmountFilter
  );
  const [connectionsAmount, setConnectionAmount] = useAtom(
    connectionsAmountFilter
  );

  const [showStartDate, setShowStartData] = useState(false);
  const [startDateValue, setStartDateValue] = useState<Dayjs | null>(
    dayjs(INITIAL_DATE_STATE)
  );
  const [showEndDate, setShowEndData] = useState(false);
  const [endDateValue, setEndDateValue] = useState<Dayjs | null>(
    dayjs(INITIAL_DATE_STATE)
  );

  const [jobTitleInput, setJobTitleInput] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const dateRef = useRef(null);

  useOnClickOutside(dateRef, () => {
    setShowStartData(false);
    setShowEndData(false);
  });

  const getAllJobs = async () => {
    const data = await fetch(
      `${BASE_URL}${JOBS}?order=${selectedSort}&job_title=${jobTitleInput}&job_status_id_list=${jobStatus}&job_type_id_list=${jobType}&job_categories_list=${categoriesIds}&city_id_list=${citiesId}&applicats_amount_since=${applicantsAmount}&connections_amount_since=${connectionsAmount}&post_code_id_list=${
        postcodeSelected.id
      }&company_id_list=${companySelected.id}&date_created_start=${
        startDateValue?.format("YYYY-MM-DD") !== INITIAL_DATE_STATE
          ? startDateValue?.format("YYYY-MM-DD")
          : ""
      }&date_created_end=${endDateValue?.format("YYYY-MM-DD")}`,
      {
        headers: {
          Authorization: "Bearer " + adminData.uid,
        },
      }
    )
      .then((res) => res.json())
      // .then((data) => setAllJobs(data.result))
      .catch((err) => console.log(err));

    return data.result;
  };
  const { data: jobs, isLoading } = useQuery(
    [
      "jobs",
      selectedSort,
      jobTitleInput,
      postcodeSelected,
      jobStatus,
      jobType,
      categoriesIds,
      citiesId,
      applicantsAmount,
      connectionsAmount,
      companySelected,
      startDateValue,
      endDateValue,
    ],
    getAllJobs
  );

  useEffect(() => {
    if (!isLoading) {
      setAllJobs(jobs);
    }
  }, [jobs]);

  const getAllCategories = async () => {
    const data = await getCategories("", adminData.uid);

    setCategories(data);
  };
  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCities = async () => {
    const data = await getCities("", adminData.uid);
    setCities(data);
  };

  useEffect(() => {
    getAllCities();
  }, []);

  const requestSearch = (e: string) => {
    setJobTitleInput(e);
  };

  const optimisedSearch = useCallback(debounce(requestSearch), []);

  const handleChangeJobCategory = (categoryId: string) => {
    if (!categoriesIds.includes(categoryId)) {
      setCategoryIds((prev) => [...prev, categoryId]);
    } else {
      setCategoryIds(categoriesIds.filter((id) => id !== categoryId));
    }
  };

  const handleFilterJobCategory = (categoryId: string) => {
    const selectedCategory: any = categories.find(
      (category) => category.category_id === categoryId
    );
    setCategoriesToShow((prev) => [...prev, selectedCategory]);
  };

  const handleRemoveJobCategory = (categoryId: string) => {
    setCategoryIds(categoriesIds.filter((category) => category !== categoryId));
    setCategoriesToShow(
      categoriesToShow.filter((category) => category.category_id !== categoryId)
    );
  };

  const handleChangeJobCity = (cityId: string) => {
    if (!citiesId.includes(cityId)) {
      setCitiesId((prev) => [...prev, cityId]);
    } else {
      setCitiesId(citiesId.filter((id) => id !== cityId));
    }
  };

  const handleFilterJobCity = (cityId: string) => {
    const selectedCity: any = cities.find((city) => city.id === cityId);
    setCitiesToShow((prev) => [...prev, selectedCity]);
  };

  const handleRemoveJobCity = (cityId: string) => {
    setCitiesId(citiesId.filter((city) => city !== cityId));
    setCitiesToShow(citiesToShow.filter((city) => city.id !== cityId));
  };

  const handleChangeJobStatus = (e: string) => {
    if (!jobStatus.includes(e)) {
      setJobStatus((prev: any) => [...prev, e]);
    }
  };
  const removeChangeJobStatus = (e: string) => {
    setJobStatus(jobStatus.filter((status: string) => status !== e));
  };

  const handleChangeJobType = (e: string) => {
    if (!jobType.includes(e)) {
      setJobType((prev: string[]) => [...prev, e]);
    }
  };

  const removeChangeJobType = (e: string) => {
    setJobType(jobType.filter((type: string) => type !== e));
  };

  return (
    <VerticalLayout>
      <div className="flex-1 space-y-4 p-8 pt-6">
        <Heading
          title="Job Postings"
          description="Here you can view all the details of the job posts"
        />
        <Separator />
        {/* <div className="container mx-auto py-10 bg-white mt-10"> */}
        <Card className="p-4">
          <CardTitle className="text-[15px]  font-medium mb-1">
            Search Jobs
          </CardTitle>

          <Input
            ref={inputRef}
            className="w-[300px] "
            onChange={(e) => optimisedSearch(e.target.value)}
            placeholder="Search Job"
          />
        </Card>
        <Card className="flex w-full items-center justify-start relative mb-4 pb-2 gap-4 p-4 ">
          <div className=" ">
            <h1 className="text-[15px]  font-medium mb-1">Sort by</h1>

            <Select
              onValueChange={(e) => {
                setSelectedSort(e);
              }}
            >
              <SelectTrigger className="w-[200px] bg-white">
                <SelectValue placeholder="Sort type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="date_created">Date Created</SelectItem>
                <SelectItem value="applicants">Applicants</SelectItem>
                <SelectItem value="connections">Connections</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="">
            <h1 className="text-[15px]  font-medium mb-1">Categories</h1>
            <Select
              onValueChange={(e) => {
                handleChangeJobCategory(e);
                handleFilterJobCategory(e);
              }}
            >
              <SelectTrigger className="bg-white w-[200px]">
                <SelectValue placeholder="Select Category" />
              </SelectTrigger>
              <SelectContent>
                {categories.map((option) => (
                  <SelectItem
                    key={option.category_id}
                    value={option.category_id}
                  >
                    {option.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="mb-2">
            <h1 className="text-[15px]  font-medium mb-1">Cities</h1>
            <Select
              onValueChange={(e) => {
                handleChangeJobCity(e);

                handleFilterJobCity(e);
              }}
            >
              <SelectTrigger className="bg-white w-[200px]">
                <SelectValue placeholder="Select City" />
              </SelectTrigger>
              <SelectContent>
                {cities.map((option) => (
                  <SelectItem key={option.id} value={option.id}>
                    {option.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="mb-2">
            <h1 className="text-[15px]  font-medium mb-1">Postcodes</h1>
            <SearchPostcodes className="bg-white" />
          </div>
          <div className="mb-2">
            <h1 className="text-[15px] font-medium mb-1"> Companies</h1>
            <SearchCompanies className="bg-white" />
          </div>
        </Card>
        {categoriesToShow?.length > 0 ||
          (citiesToShow?.length > 0 && (
            <Card className="flex w-full items-center justify-start relative mb-2 gap-4 p-4">
              {categoriesToShow?.length > 0 && (
                <div className="my-5">
                  <p className="text-sm mb-2 font-medium">Categories</p>
                  <div className="flex flew-wrap items-center gap-4">
                    {categoriesToShow?.map((category) => (
                      <div
                        onClick={() =>
                          handleRemoveJobCategory(category.category_id)
                        }
                        className="bg-gray-200 cursor-pointer px-2 rounded flex items-center gap-2 py-1"
                      >
                        <p className="text-sm">{category.name}</p>
                        <X className="h-4 w-4" />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {citiesToShow?.length > 0 && (
                <div className="my-5">
                  <p className="text-sm mb-2 font-medium">Cities</p>
                  <div className="flex flex-wrap items-center gap-4">
                    {citiesToShow?.map((city) => (
                      <div
                        onClick={() => handleRemoveJobCity(city.id)}
                        className="bg-gray-200 cursor-pointer px-2 rounded flex items-center gap-2 py-1"
                      >
                        <p className="text-sm">{city.name}</p>
                        <X className="h-4 w-4" />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Card>
          ))}

        <Card className="flex w-full items-start justify-between relative mb-2 gap-4 p-4  ">
          <div className="flex flex-col pl-4 py-2">
            <h1 className="text-[14px] font-semibold">Job Status</h1>
            <div className="flex items-center mt-2 gap-4 text-[14px]">
              <Checkbox
                onCheckedChange={(checked) => {
                  if (checked) {
                    handleChangeJobStatus("1");
                  } else {
                    removeChangeJobStatus("1");
                  }
                }}
                checked={jobStatus.includes("1")}
              />
              <p>Posts</p>
            </div>
            <div className="flex items-center mt-2 gap-4 text-[14px]">
              <Checkbox
                onCheckedChange={(checked) => {
                  if (checked) {
                    handleChangeJobStatus("2");
                  } else {
                    removeChangeJobStatus("2");
                  }
                }}
                checked={jobStatus.includes("2")}
                // checked={jobStatus === "2"}
              />
              <p>Drafts</p>
            </div>
            <div className="flex items-center mt-2 gap-4 text-[14px]">
              <Checkbox
                onCheckedChange={(checked) => {
                  if (checked) {
                    handleChangeJobStatus("3");
                  } else {
                    removeChangeJobStatus("3");
                  }
                }}
                checked={jobStatus.includes("3")}
              />
              <p>Closed</p>
            </div>
          </div>
          <div className="flex flex-col pl-4 py-2">
            <h1 className="text-[14px] font-semibold">Job Type</h1>
            <div className="flex items-center mt-2 gap-4 text-[14px]">
              <Checkbox
                onCheckedChange={(checked) => {
                  if (checked) {
                    handleChangeJobType("2");
                  } else {
                    removeChangeJobType("2");
                  }
                }}
                checked={jobType.includes("2")}
                // value="1"
              />
              <p>Remote</p>
            </div>
            <div className="flex items-center mt-2 gap-4 text-[14px]">
              <Checkbox
                onCheckedChange={(checked) => {
                  if (checked) {
                    handleChangeJobType("3");
                  } else {
                    removeChangeJobType("3");
                  }
                }}
                checked={jobType.includes("3")}
                // value="2"
              />
              <p>Hybrid</p>
            </div>
            <div className="flex items-center mt-2 gap-4 text-[14px]">
              <Checkbox
                onCheckedChange={(checked) => {
                  if (checked) {
                    handleChangeJobType("1");
                  } else {
                    removeChangeJobType("1");
                  }
                }}
                checked={jobType.includes("1")}
                // value="3"
              />
              <p>In - Person</p>
            </div>
            <div className="flex items-center mt-2 gap-4 text-[14px]">
              <Checkbox
                onCheckedChange={(checked) => {
                  if (checked) {
                    handleChangeJobType("4");
                  } else {
                    removeChangeJobType("4");
                  }
                }}
                checked={jobType.includes("4")}
                // value="4"
              />
              <p>No matter</p>
            </div>
          </div>
          <div className="flex flex-col justify-center  pl-4 rounded  py-2">
            <h1 className="text-[14px] font-semibold">Applicants</h1>
            <div className="flex items-center mt-2 gap-4 text-[14px]">
              <Checkbox
                onCheckedChange={(checked) => {
                  if (checked) {
                    setApplicantAmount("0");
                  }
                }}
                checked={applicantsAmount === "0"}
              />
              <p>0 - 2 </p>
            </div>
            <div className="flex items-center mt-2 gap-4 text-[14px]">
              <Checkbox
                onCheckedChange={(checked) => {
                  if (checked) {
                    setApplicantAmount("3");
                  }
                }}
                checked={applicantsAmount === "3"}
              />
              <p>3 - 5 </p>
            </div>
            <div className="flex items-center mt-2 gap-4 text-[14px]">
              <Checkbox
                onCheckedChange={(checked) => {
                  if (checked) {
                    setApplicantAmount("5");
                  }
                }}
                checked={applicantsAmount === "5"}
              />
              <p>5 - 10 </p>
            </div>
            <div className="flex items-center mt-2 gap-4 text-[14px]">
              <Checkbox
                onCheckedChange={(checked) => {
                  if (checked) {
                    setApplicantAmount("10");
                  }
                }}
                checked={applicantsAmount === "10"}
              />
              <p>Más de 10 </p>
            </div>
          </div>
          <div className="flex flex-col justify-center  pl-4 rounded py-2">
            <h1 className="text-[14px] font-semibold">Connections</h1>
            <div className="flex items-center mt-2 gap-4 text-[14px]">
              <Checkbox
                onCheckedChange={(checked) => {
                  if (checked) {
                    setConnectionAmount("0");
                  }
                }}
                checked={connectionsAmount === "0"}
              />
              <p>0 - 2 </p>
            </div>
            <div className="flex items-center mt-2 gap-4 text-[14px]">
              <Checkbox
                onCheckedChange={(checked) => {
                  if (checked) {
                    setConnectionAmount("2");
                  }
                }}
                checked={connectionsAmount === "2"}
              />
              <p>2 - 5 </p>
            </div>
            <div className="flex items-center mt-2 gap-4 text-[14px]">
              <Checkbox
                onCheckedChange={(checked) => {
                  if (checked) {
                    setConnectionAmount("5");
                  }
                }}
                checked={connectionsAmount === "5"}
              />
              <p>5 - 10 </p>
            </div>
            <div className="flex items-center mt-2 gap-4 text-[14px]">
              <Checkbox
                onCheckedChange={(checked) => {
                  if (checked) {
                    setConnectionAmount("10");
                  }
                }}
                checked={connectionsAmount === "10"}
              />
              <p>10 - 20 </p>
            </div>
            <div className="flex items-center mt-2 gap-4 text-[14px]">
              <Checkbox
                onCheckedChange={(checked) => {
                  if (checked) {
                    setConnectionAmount("20");
                  }
                }}
                checked={connectionsAmount === "20"}
              />
              <p>Más de 20 </p>
            </div>
          </div>
          <DatePickerWithRange />
          {/* <div ref={dateRef} className="relative w-[250px] mb-2">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <h1 className="text-[15px] font-medium mb-1">Date</h1>
              <div className="flex flex-col items-start gap-4">
                <div className="flex flex-col items-start">
                  <p className="text-[14px] mb-1"> From</p>
                  <Button
                    variant={"subtle"}
                    onClick={() => setShowStartData(!showStartDate)}
                  >
                    {startDateValue?.format("DD/MM/YYYY")}
                  </Button>
                </div>
                <div className="absolute top-10 right-0 z-10 bg-white">
                  {showStartDate && (
                    <DateCalendar
                      value={startDateValue}
                      onChange={(newValue) => setStartDateValue(newValue)}
                    />
                  )}
                </div>
                <div className="flex flex-col items-start">
                  <p className="text-[14px] mb-1">To</p>
                  <Button
                    variant={"subtle"}
                    onClick={() => setShowEndData(!showEndDate)}
                  >
                    {endDateValue?.format("DD/MM/YYYY")}
                  </Button>
                </div>
                <div className="absolute top-10 right-0 z-10 bg-white">
                  {showEndDate && (
                    <DateCalendar
                      value={endDateValue}
                      onChange={(newValue) => setEndDateValue(newValue)}
                    />
                  )}
                </div>
              </div>
            </LocalizationProvider>
          </div> */}
        </Card>

        <DataTable columns={columns} data={allJobs} />
      </div>
    </VerticalLayout>
  );
};

export default AlJobsTable;
