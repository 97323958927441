import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CreditsData } from "src/types/types";

interface TableRowCreditsProps {
  row: CreditsData;
  isItemSelected: boolean;
  labelId: string;
  handleClick: (args: React.MouseEvent<unknown>, name: string) => void;
}

const TableRowCredits = ({
  row,
  isItemSelected,
  labelId,
  handleClick,
}: TableRowCreditsProps) => {
  const navigate = useNavigate();
  return (
    <TableRow
      sx={{
        "& td": {
          fontSize: 13,
          fontWeight: "400",
          // color: "#333",
          fontFamily: "inherit",
          cursor: "pointer",
        },
      }}
      hover
      onClick={() => {
        // setJobInfo(row);
        // navigate(`//all-jobs/${row.user_id}`);
      }}
      // onClick={(event) => handleClick(event, row.job_id as string)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.user_id}
      selected={isItemSelected}
    >
      <TableCell>{row.email}</TableCell>
      <TableCell>{row.date_created}</TableCell>
      <TableCell>{row.subscription}</TableCell>
      <TableCell>{row.credits}</TableCell>
    </TableRow>
  );
};

export default TableRowCredits;
