import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { allUsersContent } from "src/mockData/users";
import { AllUsersData } from "src/types/types";
import { visuallyHidden } from "@mui/utils";
import { Input } from "../ui/Input";
import { ReactComponent as Search } from "../../assets/img/search.svg";
import { getComparator, stableSort } from "src/lib/tableUtils";
import TableRowUserData from "./TableRowUserData";

type Order = "asc" | "desc";

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof AllUsersData
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

interface HeadCell {
  disabledPadding: boolean;
  id: keyof AllUsersData;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  // {
  //   id: "job_id",
  //   disabledPadding: false,
  //   numeric: false,
  //   label: "Job Id",
  // },
  {
    id: "title",
    disabledPadding: false,
    numeric: false,
    label: "Profile Title",
  },
  {
    id: "overview",
    disabledPadding: false,
    numeric: false,
    label: "Overview",
  },
  {
    id: "email",
    disabledPadding: false,
    numeric: false,
    label: "Email",
  },
  {
    id: "date_created",
    disabledPadding: false,
    numeric: false,
    label: "Date Created",
  },
  {
    id: "applied_jobs",
    disabledPadding: false,
    numeric: false,
    label: "Applied jobs",
  },
  {
    id: "category",
    disabledPadding: false,
    numeric: false,
    label: "Category",
  },
  {
    id: "skills",
    disabledPadding: false,
    numeric: false,
    label: "Skills",
  },
];

type Props = {
  data: any;
};

const TableAllUsers = ({ data }: Props) => {
  const [rows, setRows] = useState(allUsersContent);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof AllUsersData>("user_id");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searched, setSearched] = useState<string>("");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof AllUsersData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  useEffect(() => {
    setRows(allUsersContent);
  }, [allUsersContent]);

  const requestSearch = (searchVal: string) => {
    setSearched(searchVal);
    const filteredRows = allUsersContent.filter((row: any) => {
      return row.first_name.toLowerCase().includes(searchVal.toLowerCase());
    });

    setRows(filteredRows);
  };

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;

    const createSortHandler =
      (property: keyof AllUsersData) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow
          sx={{
            "& th": {
              fontSize: 14,
              fontWeight: "600",
              color: "rgba(96, 96, 96)",
              fontFamily: "inherit",
            },
          }}
        >
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disabledPadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <div className="w-full flex items-center justify-start relative mt-3">
          <Search className="h-4 absolute left-[17rem] top-[28px] fill-[#1d90b3]" />

          <Input
            className="w-[300px] mt-4 ml-2"
            value={searched}
            onChange={(e) => requestSearch(e.target.value)}
            placeholder="Search Worker"
          />
        </div>
        <TableContainer
          sx={{
            // marginTop: 2,
            minHeight: 683,
            // maxHeight: 840,
            "&::-webkit-scrollbar": {
              width: 10,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "cyan",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "lightblue",
              borderRadius: 2,
            },
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 730 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows as any, getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row: any, index) => {
                  const isItemSelected = isSelected(row.job_id as string);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRowUserData
                      key={row.user_id}
                      row={row}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      handleClick={handleClick}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default TableAllUsers;
