import { AvatarProps } from "@radix-ui/react-avatar";

import { Avatar, AvatarFallback } from "./Avatar";
import { User } from "src/types/types";
import { Icons } from "./Icons";

interface UserAvatarProps extends AvatarProps {
  user: Pick<User, "image" | "name">;
}

export function UserAvatar({ user, ...props }: UserAvatarProps) {
  return (
    <Avatar {...props}>
      {user.image ? (
        <div className="relative aspect-square h-full w-full">
          <img src={user.image} alt="profile" referrerPolicy="no-referrer" />
        </div>
      ) : (
        <AvatarFallback>
          <span className="sr-only">{user?.name}</span>
          <Icons.user className="h-4 w-4" />
          {/* <Icons.user className="h-4 w-4" /> */}
        </AvatarFallback>
      )}
    </Avatar>
  );
}
