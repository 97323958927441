import { ChevronLeftIcon, HomeIcon } from "lucide-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import HorizontalNavBar from "src/Components/NavBars/HorizontalNavBar";
import VerticalNavBar from "src/Components/NavBars/VerticalNavBar";
import UserProfile from "src/Components/Reports/UserProfile";
import { Button, buttonVariants } from "src/Components/ui/Button";
import useLocalStorage from "src/Hooks/useLocalStorage";

const UserPage = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useLocalStorage("tabSelected", "");
  const [showModalDeleteUser, setShowModalDeleteUser] = useState(false);

  return (
    <div className="xl:flex-row flex flex-col bg-[#F3F3F4] min-h-screen h-full w-screen">
      <div className="xl:flex flex-[0_0_15%] hidden  h-full w-full  overflow-hidden">
        <VerticalNavBar
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
      <div className="xl:hidden w-full">
        <HorizontalNavBar
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
      <div className="container max-w-6xl mx-auto h-full pt-12">
        <div
          onClick={() => {
            navigate("/reports/users");
          }}
          className="flex items-center my-10 cursor-pointer"
        >
          <ChevronLeftIcon className="h-4 w-4" />
          <h3>Back</h3>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-y-4 md:gap-x-4 py-6">
          <UserProfile />
          <div className="overflow-hidden h-fit rounded-lg border border-gray-200 bg-white order-first md:order-last">
            <div className="bg-emerald-100 px-6 py-4">
              <p className="font-semibold py-3 flex items-center gap-1.5">
                <HomeIcon className="h-4 w-4" />
                Home
              </p>
            </div>
            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
              <div className="flex justify-between gap-x-4 py-3">
                <p className="text-zinc-500">
                  This job has been reported. Come here to check the users that
                  reported this post.
                </p>
              </div>

              <Button
                onClick={() => setShowModalDeleteUser(showModalDeleteUser)}
                className={buttonVariants({
                  className: "w-full mt-4 mb-6",
                })}
              >
                Delete user
              </Button>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPage;
