import React, { Dispatch, SetStateAction, useEffect } from "react";
import { ReactComponent as Search } from "../../assets/img/search.svg";
import { ReactComponent as Check } from "../../assets/img/check.svg";
import { ReactComponent as Cross } from "../../assets/img/cross.svg";
import { ClientDataTypes, SortOption } from "src/types/types";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Input } from "../ui/Input";
import { BASE_URL_IMG } from "src/Config/api.config";
import { HandleMonthName } from "src/Utils/HandleMonthName";

interface AllWorkersDataProps {
  clientData: ClientDataTypes;
  setClientData: Dispatch<SetStateAction<ClientDataTypes>>;
}

const AllWorkersData = ({ clientData, setClientData }: AllWorkersDataProps) => {
  const filterBySearch = (e: string) => {
    const query = e;
    var updatedList = [...clientData.filteredWorkers];
    updatedList = updatedList.filter((item) => {
      return (
        (
          item.first_name.toLowerCase() +
          " " +
          item.last_name.toLowerCase()
        ).indexOf(query.toLowerCase()) !== -1
      );
    });
    setClientData((prev) => ({ ...prev, workers: updatedList }));
  };

  const sortOptions: SortOption[] = [
    {
      label: "Closed Jobs",
      value: "0",
    },
    {
      label: "Applied Jobs",
      value: "1",
    },
    {
      label: "Reported Msg",
      value: "2",
    },
    {
      label: "Validated ",
      value: "3",
    },
    {
      label: "Joined on",
      value: "4",
    },
  ];

  return (
    <div className="px-6 pb-4">
      <div className="relative">
        <p className="absolute text-xs font-bold bg-white text-[#1d90b3] -top-2 left-3 w-fit px-1">
          Worker
        </p>
        <Search className="h-4 absolute left-[12rem] top-[13px] fill-[#1d90b3]" />
        <div className="flex items-center w-1/3 gap-10">
          <Input
            type="text"
            onChange={(e) => filterBySearch(e.target.value)}
            placeholder="Search by name"
            // className="bg-white outline-none border border-[#1d90b3] text-sm px-10 py-3 rounded w-[250px]"
          />
          <Select>
            <SelectTrigger>
              <SelectValue placeholder="Sort by" />
            </SelectTrigger>
            <SelectContent>
              {sortOptions.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="flex w-full mt-8 text-sm font-bold px-3">
        <p className="flex-[0_0_40%]">Worker</p>
        <p className="flex-[0_0_12%]">Closed Jobs</p>
        <p className="flex-[0_0_12%]">Applied Jobs</p>
        {/* <p className="flex-[0_0_12%]">Reported Msg</p> */}
        <p className="flex-[0_0_12%]">Email</p>
        <p className="flex-[0_0_12%]">Validated</p>
        <p className="flex-[0_0_12%]">Joined on</p>
      </div>
      <div className="max-h-[500px] overflow-auto jobpost">
        {clientData?.workers
          ?.slice(0)
          .reverse()
          .map((worker) => {
            return (
              <div key={worker.id} className="bg-[#e8e8e8]  rounded-lg">
                <div className="flex w-full items-center text-sm px-3 py-2 mt-2">
                  <div className="flex whitespace-nowrap items-center  gap-3 flex-[0_0_40%]">
                    {worker?.profile_image ? (
                      <img
                        src={`${BASE_URL_IMG}${worker?.profile_image}`}
                        alt="img"
                        className="h-7 w-7 object-cover rounded-full cursor-default"
                      />
                    ) : (
                      <div className="rounded-full border border-black h-7 w-7 items-center flex justify-center">
                        <p>
                          {worker?.first_name.slice(0, 1).toUpperCase()}
                          {worker?.last_name.slice(0, 1).toUpperCase()}
                        </p>
                      </div>
                    )}
                    <p className="font-bold">
                      {worker?.first_name} {worker?.last_name}
                    </p>
                  </div>
                  <p className="flex-[0_0_12%]">3</p>
                  <p className="flex-[0_0_12%]">12</p>
                  <p className="flex-[0_0_12%]">
                    0
                    {/* <span className="text-xs mr-10">{worker.email}</span> */}
                  </p>
                  <div className="flex-[0_0_12%] ">
                    {worker?.validated === "0" ? (
                      <Cross className="fill-red-700 h-4 w-fit" />
                    ) : (
                      <Check className="fill-green-600 h-4 w-fit" />
                    )}
                  </div>
                  <p className="flex-[0_0_12%]">
                    {HandleMonthName(worker?.date_created.slice(5, 7)).slice(
                      0,
                      3
                    )}{" "}
                    {worker?.date_created.slice(8, 10)},{" "}
                    {worker?.date_created.slice(0, 4)}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AllWorkersData;
