import React, { useEffect, useRef, useState } from "react";

import { useOnClickOutside } from "src/Hooks/useOnClickOutside";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "../ui/Command";
import { useAtom } from "jotai";
import { postcodeFilter } from "src/atoms/atoms";
import { Check, ChevronsUpDown, Map, X } from "lucide-react";
import useManagementData from "src/api/useManagementData";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Button } from "../ui/Button";
import { cn } from "src/lib/utils";
// import { debounce } from "src/Utils/debounce";

type PopoverTriggerProps = React.ComponentPropsWithoutRef<
  typeof PopoverTrigger
>;

interface PopoverProps extends PopoverTriggerProps {}

const SearchPostcodes: React.FC<PopoverProps> = ({ className }) => {
  const [input, setInput] = useState<string>("");
  const commandRef = useRef<HTMLDivElement>(null);
  const [postcodeSelected, setPostcodeSelected] = useAtom(postcodeFilter);
  const { allPostcodes, getPostcodes } = useManagementData();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (input !== "") {
      getPostcodes(input);
    } else {
      getPostcodes("");
    }
  }, [input]);

  useOnClickOutside(commandRef, () => {
    setInput("");
  });

  useEffect(() => {
    setInput("");
  }, []);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          role="combobox"
          aria-expanded={open}
          aria-label="Select a store"
          className={cn("w-[200px] justify-between ", className)}
        >
          <Map className="mr-2 h-4 w-4" />
          {postcodeSelected?.locality === ""
            ? "Select a Postcode"
            : postcodeSelected.locality}
          <ChevronsUpDown className="ml-auto h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0 bg-white">
        <Command>
          <CommandList>
            <CommandInput isLoading={false} placeholder="Search postcode..." />
            <CommandEmpty>No postcode found.</CommandEmpty>
            <CommandGroup heading="Postcodes">
              {allPostcodes.map((postcode) => (
                <CommandItem
                  key={postcode.id}
                  onSelect={() => setPostcodeSelected(postcode)}
                  className="text-sm"
                >
                  <Map className="mr-2 h-4 w-4" />
                  {postcode.locality}
                  <Check
                    className={cn(
                      "ml-auto h-4 w-4",
                      postcodeSelected?.id === postcode.id
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
          <CommandSeparator />
          <CommandList>
            <CommandGroup>
              <CommandItem
                onSelect={() => {
                  setOpen(false);
                  setPostcodeSelected({
                    id: "",
                    locality: "",
                    postcode: "",
                    state: "",
                  });
                }}
              >
                <X className="mr-2 h-5 w-5" />
                Clear Selection
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default SearchPostcodes;
