import React, { useState, useRef, useEffect } from "react";
import { useOnClickOutside } from "src/Hooks/useOnClickOutside";
import useManagementData from "src/api/useManagementData";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Button } from "../ui/Button";
import { cn } from "src/lib/utils";
import { Building2, Check, ChevronsUpDown, X } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "../ui/Command";
import { useAtom } from "jotai";
import { companyFilter } from "src/atoms/atoms";

type PopoverTriggerProps = React.ComponentPropsWithoutRef<
  typeof PopoverTrigger
>;

const SearchCompanies = ({ className }: PopoverTriggerProps) => {
  const [input, setInput] = useState<string>("");
  const commandRef = useRef<HTMLDivElement>(null);
  const [companySelected, setCompanySelected] = useAtom(companyFilter);
  const { getAdmCompanies, companies } = useManagementData();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (input !== "") {
      getAdmCompanies(input);
    } else {
      getAdmCompanies("");
    }
  }, [input]);

  useOnClickOutside(commandRef, () => {
    setInput("");
  });

  useEffect(() => {
    setInput("");
  }, []);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          role="combobox"
          aria-expanded={open}
          aria-label="Select a store"
          className={cn("w-[200px] justify-between ", className)}
        >
          <Building2 className="mr-2 h-4 w-4" />

          {companySelected?.name === ""
            ? "Select a Company"
            : companySelected?.name}
          <ChevronsUpDown className="ml-auto h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0 bg-white">
        <Command>
          <CommandList>
            <CommandInput isLoading={false} placeholder="Search company..." />
            <CommandEmpty>No company found.</CommandEmpty>
            <CommandGroup heading="Companies">
              {companies.map((company: any) => (
                <CommandItem
                  key={company.id}
                  onSelect={() => setCompanySelected(company)}
                  className="text-sm"
                >
                  <Building2 className="mr-2 h-4 w-4" />
                  {company.name}
                  <Check
                    className={cn(
                      "ml-auto h-4 w-4",
                      companySelected?.id === company.id
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
          <CommandSeparator />
          <CommandList>
            <CommandGroup>
              <CommandItem
                onSelect={() => {
                  setOpen(false);
                  setCompanySelected({
                    name: "",
                    active: "",
                    cif: "",
                    company_logo: "",
                    country_code: "",
                    date_created: "",
                    description: "",
                    id: "",
                    phone: "",
                    post_code_id: "",
                    prefix_phone: "",
                    validated: "",
                  });
                }}
              >
                <X className="mr-2 h-5 w-5" />
                Clear Selection
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default SearchCompanies;
