import { ChevronLeftIcon } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import SettingsDetails from "src/Components/Settings/SettingDetails";
import { Button, buttonVariants } from "src/Components/ui/Button";
import { Icons } from "src/Components/ui/Icons";
import { BASE_URL, LOGOUT } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { useTokenStore } from "src/Hooks/useTokenStore";
import { profileTypeToken, userProfileStore } from "src/Utils/useProfileStore";
import VerticalLayout from "src/layout/VerticalLayout";

type Props = {};

const Settings = (props: Props) => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const setTokens = useTokenStore((s) => s.setAccessToken);
  const navigate = useNavigate();
  const setProfileTypesLocal = userProfileStore((s) => s.setProfile);

  const logoutData = new FormData();
  logoutData.append("id", adminData.id);

  const logoutUser = () => {
    try {
      fetch(`${BASE_URL}${LOGOUT}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ` + adminData.uid,
        },
        body: logoutData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.result.token === "disabled") {
            setTokens("");
            // localStorage.clear();
            setProfileTypesLocal({ profileTypeToken: null });
            navigate("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <VerticalLayout>
      <div className="container max-w-6xl mx-auto h-full pt-12">
        <div
          onClick={() => {
            navigate(-1);
          }}
          className="flex items-center my-10 cursor-pointer"
        >
          <ChevronLeftIcon className="h-4 w-4" />
          <h3>Back</h3>
        </div>
        <h1 className="font-bold text-3xl ">Settings</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-4 py-6">
          <SettingsDetails />
          <div className="overflow-hidden h-fit rounded-lg border border-gray-200 bg-white order-first md:order-last">
            <div className="bg-emerald-100 px-6 py-4">
              <p className="font-semibold py-3 flex items-center gap-1.5">
                <Icons.settings className="h-4 w-4" />
                Admin Settings
              </p>
            </div>
            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
              <div className="flex justify-between gap-x-4 py-3">
                <p className="text-zinc-500">
                  Come here to check the information about the job.
                </p>
              </div>

              <Button
                onClick={() => logoutUser()}
                // onClick={() => setShowModalCloseAccount(showModalCloseAccount)}
                // onClick={() => setShowModal(!showModal)}
                className={buttonVariants({
                  className: "w-full mt-4 mb-6",
                })}
              >
                Sign out
              </Button>
              <Button
                // onClick={() => setShowModalRemoveUser(showModalRemoveUser)}
                // onClick={() => setShowModal(!showModal)}
                className={buttonVariants({
                  className: "w-full mt-4 mb-6",
                  variant: "subtle",
                })}
              >
                Close account
              </Button>
            </dl>
          </div>
        </div>
      </div>
    </VerticalLayout>
  );
};

export default Settings;
