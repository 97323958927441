import { useAtom } from "jotai";
import React, { useEffect, useReducer, useState } from "react";
import RequestErrorModal from "src/Components/RequestErrorPopup";
import useLocalStorage from "src/Hooks/useLocalStorage";
import {
  categorySelection,
  creditsAtom,
  editFunctionLoading,
  inputCategory,
} from "src/atoms/atoms";
import VerticalLayout from "src/layout/VerticalLayout";
import CardManagementCategories from "src/Components/Management/CardManagementCategories";
import {
  AllCategories,
  AllPostcodes,
  AllSkills,
  City,
  CreditsTypes,
  ParamsData,
} from "src/types/types";
import CardManagementSkills from "src/Components/Management/CardManagementSkills";
import CardManagementCities from "src/Components/Management/CardManagementCities";
import CardManagementPostcodes from "src/Components/Management/CardManagementPostcodes";
import {
  ACTIONS,
  BASE_URL,
  CITIES,
  GENERAL_PARAMS,
  JOB_CATEGORIES,
  JOB_SKILLS,
  POSTCODES,
} from "src/Config/api.config";
import useManagementData from "src/api/useManagementData";
import ModalManagement from "src/Components/Modals/ModalManagement";
import { ShowItem, ShowModal } from "./ManagementOthers";
import { Heading } from "src/Components/ui/heading";
import { Separator } from "src/Components/ui/separator";
import CardManagementCredits from "src/Components/Management/CardManagementCredits";
import useManagementGeneral from "src/Hooks/useManagementGeneral";
import CardManagementParams from "src/Components/Management/CardManagementParams";
import { decryptArray } from "src/Utils/decryptArray";

const initialState = {
  newSkill: "",
  categorySkill: "",
  newCategory: "",
  showSkillInput: "",
  showCatInput: "",
  showCitiesInput: "",
  newCity: "",
  newProvince: "",
  newPostcode: "",
  newLocality: "",
  showPostcodesInput: "",
  showInappropiateReasonInput: "",
  newInappropiateReason: "",
  showInappropiateReasonWorkerInput: "",
  newIappropiateWorkerReason: "",
};

const INITIAL_SKILL_STATE = {
  label: "",
  name: "",
  skill_id: "",
  value: "",
};

const INITIAL_CITY_STATE = {
  id: "",
  name: "",
  province: "",
};

const INITIAL_POSTCODE_STATE = {
  id: "",
  postcode: "",
  locality: "",
  state: "",
};

const INITIAL_CREDIT_STATE = {
  action_id: "",
  credits_employer: "",
  credits_company: "",
  credits_worker: "",
  name: "",
};

const INITIAL_PARAMS_STATE = {
  id: "",
  name: "",
  value: "",
};

const ManagementGeneral = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    allCities,
    allPostcodes,
    getPostcodes,
    getCities,
    credits,
    params,
    getCredits,
    getParams,
  } = useManagementData();
  const [showModal, setShowModal] = useState<ShowModal>({
    edit: false,
    delete: false,
    section: "",
  });
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const [showItem, setShowItem] = useState<ShowItem>({
    edit: -1,
    trash: -1,
    where: "",
  });
  const [alert, setAlert] = useState(false);
  const [allSkills, setAllSkills] = useState<AllSkills[]>([]);
  const [allCategories, setAllCategories] = useState<AllCategories[]>([]);
  const [typeOfAlert, setTypeOfAlert] = useState("");
  const [warning, setWarning] = useState<string | boolean>(false);
  const [loading, setLoading] = useAtom(editFunctionLoading);
  const [refreshData, setRefreshData] = useState(false);
  const [selectedCategory, setSelectedCategory] = useAtom(categorySelection);
  const [selectedSkill, setSelectedSkill] =
    useState<AllSkills>(INITIAL_SKILL_STATE);
  const [selectedCity, setSelectedCity] = useState<City>(INITIAL_CITY_STATE);
  const [selectedPostcode, setSelectedPostcode] = useState<AllPostcodes>(
    INITIAL_POSTCODE_STATE
  );

  const [cityInput, setCityInput] = useState("");
  const [categoryInput, setCategoryInput] = useAtom(inputCategory);
  const [postcodeInput, setPostcodeInput] = useState("");

  const [creditsInput, setCreditsInput] = useState("");

  const [paramsInput, setParamsInput] = useState("");
  const [creditsType, setCreditsType] = useAtom(creditsAtom);

  const [selectedCredit, setSelectedCredit] =
    useState<CreditsTypes>(INITIAL_CREDIT_STATE);

  const [selectedParams, setSelectedParams] =
    useState<ParamsData>(INITIAL_PARAMS_STATE);

  function reducer(state: any, action: any) {
    switch (action.type) {
      case "ADD":
        return {
          ...state,
          [action.key]: action.value,
        };
      case "TOGGLE_INPUT":
        return {
          ...state,
          [action.key]: action.value,
        };
      default:
        return state;
    }
  }
  const { skillsCategory, getSkillsFromCategory, categories } =
    useManagementGeneral();

  useEffect(() => {
    convertToSelect(skillsCategory, "skills");
  }, [skillsCategory]);
  useEffect(() => {
    convertToSelect(categories, "categories");
  }, [categories]);

  let editCategoryData = new FormData();
  editCategoryData.append("_method", "PUT");

  const editCategory = (id: string, category: string) => {
    setLoading(true);
    editCategoryData.append("category_id", id);
    editCategoryData.append("name", category);
    fetch(`${BASE_URL}${JOB_CATEGORIES}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${adminData.uid}`,
      },
      body: editCategoryData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setRefreshData(true);
        } else {
          setWarning(data.result.error_msg);
          setTimeout(() => {
            setWarning(false);
          }, 3800);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAddition = (type: string) => {
    if (type === "skill") {
      setTypeOfAlert("Skill added successfully");
    } else if (type === "category") {
      setTypeOfAlert("Category added successfully");
    } else if (type === "city") {
      setTypeOfAlert("City added successfully");
    } else if (type === "postcode") {
      setTypeOfAlert("Postcode added successfully");
    }
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 2500);
  };

  //DELETE CATEGORY

  const deleteCategory = (id: string) => {
    fetch(`${BASE_URL}${JOB_CATEGORIES}?category_id=${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${adminData.uid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setRefreshData(true);
        } else {
          setWarning(data.result.error_msg);
          setTimeout(() => {
            setWarning(false);
          }, 3800);
        }
      })
      .catch((err) => console.log(err));
  };

  //DELETE SKILL

  const deleteSkill = (id: string) => {
    setLoading(true);
    fetch(`${BASE_URL}${JOB_SKILLS}?skill_id=${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${adminData.uid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          getSkillsFromCategory();
        } else {
          setWarning(data.result.error_msg);
          setTimeout(() => {
            setWarning(false);
          }, 3800);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  //EDIT SKILL

  let editSkillData = new FormData();
  editSkillData.append("_method", "PUT");

  const editSkill = (id: string, skill: string) => {
    setLoading(true);
    if (skill.length === 0) {
      setWarning("Enter a valid name");
      setTimeout(() => {
        setWarning(false);
      }, 3800);
    } else {
      editSkillData.append("skill_id", id);
      editSkillData.append("category_id", selectedCategory.category_id);
      editSkillData.append("name", skill);
      fetch(`${BASE_URL}${JOB_SKILLS}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${adminData.uid}`,
        },
        body: editSkillData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "ok") {
            getSkillsFromCategory();
          } else {
            setWarning(data.result.error_msg);
            setTimeout(() => {
              setWarning(false);
            }, 3800);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // CITY ENDPOINTS

  useEffect(() => {
    if (cityInput !== "") {
      getCities(cityInput);
    } else {
      getCities("");
    }
  }, [cityInput]);

  const handleCitySearch = (e: string) => {
    setCityInput(e);
  };

  const handlePostcodeSearch = (e: string) => {
    setPostcodeInput(e);
  };

  const handleCreditsChange = (e: string) => {
    setCreditsInput(e);
  };

  const handleParamsChange = (e: string) => {
    setParamsInput(e);
  };

  // DELETE CITY

  const deleteCity = (id: string) => {
    setLoading(true);
    fetch(`${BASE_URL}${CITIES}?city_id=${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${adminData.uid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          getCities("");
        } else {
          setWarning(data.result.error_msg);
          setTimeout(() => {
            setWarning(false);
          }, 3800);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  //DELETE POSTCODE

  const deletePostcode = (id: string) => {
    setLoading(true);
    fetch(`${BASE_URL}${POSTCODES}?post_code_id=${id}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          getPostcodes("");
        } else {
          setWarning(data.result.error_msg);
          setTimeout(() => {
            setWarning(false);
          }, 3800);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //EDIT CITY

  let editCityData = new FormData();
  editCityData.append("_method", "PUT");

  const editCity = (id: string, province: string, city: string) => {
    setLoading(true);
    if (city.length === 0) {
      setWarning("Enter a valid name");
      setTimeout(() => {
        setWarning(false);
      });
    } else {
      editCityData.append("country_code", "ES");
      editCityData.append("city_id", id);
      editCityData.append("province", province);
      editCityData.append("name", city);

      fetch(`${BASE_URL}${CITIES}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${adminData.uid}`,
        },
        body: editCityData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "ok") {
            getCities("");
          } else {
            setWarning(data.result.error_msg);
            setTimeout(() => {
              setWarning(false);
            }, 3800);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getCredits("");
  }, []);

  useEffect(() => {
    if (paramsInput) {
      getParams(paramsInput);
    } else {
      getParams("");
    }
  }, [paramsInput]);

  //EDIT POSTCODE

  let editPostcodeData = new FormData();
  editPostcodeData.append("_method", "PUT");

  const editPostcode = (
    id: string,
    province: string,
    postcode: string,
    locality: string
  ) => {
    setLoading(true);
    if (locality.length === 0) {
      setWarning("Enter a valid name");
      setTimeout(() => {
        setWarning(false);
      });
    } else {
      editPostcodeData.append("country_code", "ES");
      editPostcodeData.append("post_code_id", id);
      editPostcodeData.append("postcode", postcode);
      editPostcodeData.append("state", province);
      editPostcodeData.append("locality", locality);

      fetch(`${BASE_URL}${POSTCODES}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${adminData.uid}`,
        },
        body: editPostcodeData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "ok") {
            getPostcodes("");
          } else {
            setWarning(data.result.error_msg);
            setTimeout(() => {
              setWarning(false);
            }, 3800);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const editCreditData = new FormData();
  editCreditData.append("_method", "PUT");

  const editCredit = (id: string, name: string, credits: string) => {
    editCreditData.append("action_id", id);
    editCreditData.append("name", name);
    if (creditsType.toLowerCase() === "company") {
      editCreditData.append("credits_company", credits);
    } else if (creditsType.toLowerCase() === "particular") {
      editCreditData.append("credits_employer", credits);
    } else if (creditsType.toLowerCase() === "worker") {
      editCreditData.append("credits_worker", credits);
    }

    fetch(`${BASE_URL}${ACTIONS}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
      body: editCreditData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          getCredits("");
        } else {
          setWarning(data.result.error_msg);
          setTimeout(() => {
            setWarning(false);
          }, 3800);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteCredit = (id: string) => {
    setLoading(true);
    fetch(`${BASE_URL}${ACTIONS}?action_id=${id}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          getCredits("");
        } else {
          setWarning(data.result.error_msg);
          setTimeout(() => {
            setWarning(false);
          }, 3800);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editParamData = new FormData();
  editParamData.append("_method", "PUT");

  const editParam = (id: string, name: string, value: string) => {
    editCreditData.append("param_id", id);
    editCreditData.append("name", name);
    editCreditData.append("value", value);

    fetch(`${BASE_URL}${GENERAL_PARAMS}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
      body: editCreditData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          getParams("");
        } else {
          setWarning(data.result.error_msg);
          setTimeout(() => {
            setWarning(false);
          }, 3800);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (postcodeInput !== "") {
      getPostcodes(postcodeInput);
    } else {
      getPostcodes("");
    }
  }, [postcodeInput]);

  //REACT SELECT OPTIONS
  const convertToSelect = (
    data: AllCategories[] & AllSkills[],
    target: string
  ) => {
    if (target === "skills") {
      setAllSkills(
        data?.map(function (row: AllSkills) {
          return {
            value: row.name,
            label: row.name,
            skill_id: row.skill_id,
            name: row.name,
          };
        })
      );
    } else if (target === "categories") {
      setAllCategories(
        data?.map(function (row) {
          return {
            value: row.name,
            label: row.name,
            category_id: row.category_id,
            name: row.name,
          };
        })
      );
    }
  };
  useEffect(() => {
    dispatch({ type: "TOGGLE_INPUT", value: false, key: "showCatInput" });
  }, [selectedCategory]);

  //HANDLE SEARCH
  const handleCategorySearch = (e: string) => {
    setCategoryInput(e);
  };

  return (
    <VerticalLayout>
      <div className="flex-1 space-y-4 p-8 pt-6">
        <div
          className={`flex justify-center transition-opacity duration-500 ${
            !alert ? "opacity-0 " : "opacity-100 "
          }`}
        >
          <div className="bg-green-600 text-white font-bold text-sm rounded-lg py-3.5 tracking-wide px-6 mt-6 fixed items-start shadow-lg">
            <div className="flex justify-between gap-[78px] items-center">
              <div className="flex gap-4 items-center">
                <p>{typeOfAlert}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute top-7 left-[50%]">
          <RequestErrorModal warning={warning} />
        </div>
        {/* <div> */}
        <Heading
          title="Management"
          description="Manage reasons related to general aspects of the platform, such as postcode, categories, skills, cities, etc...
          "
        />
        <Separator />

        <div className=" mt-12 flex xl:flex-row flex-col gap-12">
          <CardManagementCategories
            allCategories={allCategories}
            setRefreshData={setRefreshData}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            setShowItem={setShowItem}
            showItem={showItem}
            setShowModal={setShowModal}
            handleCategorySearch={handleCategorySearch}
          />
          <CardManagementSkills
            allSkills={allSkills}
            handleAddition={handleAddition}
            setWarning={setWarning}
            allCategories={allCategories}
            setSelectedSkill={setSelectedSkill}
            setShowItem={setShowItem}
            showItem={showItem}
            setShowModal={setShowModal}
            getSkillsFromCategory={getSkillsFromCategory}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        </div>
        <div className=" mt-12 flex xl:flex-row flex-col gap-12">
          <CardManagementCities
            setShowItem={setShowItem}
            setShowModal={setShowModal}
            showItem={showItem}
            handleCitySearch={handleCitySearch}
            getCities={getCities}
            setSelectedCity={setSelectedCity}
            allCities={allCities}
            setSelectedCategory={setSelectedCategory}
          />
          <CardManagementPostcodes
            dispatch={dispatch}
            setShowItem={setShowItem}
            setShowModal={setShowModal}
            showItem={showItem}
            allPostcodes={allPostcodes}
            getPostcodes={getPostcodes}
            setSelectedPostcode={setSelectedPostcode}
            handlePostcodeSearch={handlePostcodeSearch}
            setSelectedCategory={setSelectedCategory}
          />
        </div>
        <div className="mt-12  xl:flex-row flex-col grid grid-cols-2 gap-12 ">
          <CardManagementCredits
            setCreditsType={setCreditsType}
            creditsType={creditsType}
            handleCreditsChange={handleCreditsChange}
            allCredits={credits?.actions}
            setShowItem={setShowItem}
            showItem={showItem}
            setShowModal={setShowModal}
            getCredits={getCredits}
            setSelectedCredit={setSelectedCredit}
          />
          <CardManagementParams
            handleParamsChange={handleParamsChange}
            allParams={params}
            setShowItem={setShowItem}
            showItem={showItem}
            setShowModal={setShowModal}
            getParams={getParams}
            setSelectedParams={setSelectedParams}
          />
        </div>
        {showModal.delete && showModal.section === "category" && (
          <ModalManagement
            type={"delete"}
            section={"category"}
            showModal={showModal}
            setShowModal={setShowModal}
            selectedCategory={selectedCategory}
            handleAction={deleteCategory}
          />
        )}
        {showModal.edit && showModal.section === "category" && (
          <ModalManagement
            type={"edit"}
            section={"category"}
            showModal={showModal}
            setShowModal={setShowModal}
            selectedCategory={selectedCategory}
            handleAction={editCategory}
          />
        )}
        {showModal.delete && showModal.section === "skill" && (
          <ModalManagement
            type={"delete"}
            section={"skill"}
            showModal={showModal}
            setShowModal={setShowModal}
            selectedSkill={selectedSkill}
            handleAction={deleteSkill}
          />
        )}
        {showModal.edit && showModal.section === "skill" && (
          <ModalManagement
            type={"edit"}
            section={"skill"}
            showModal={showModal}
            setShowModal={setShowModal}
            selectedSkill={selectedSkill}
            handleAction={editSkill}
          />
        )}

        {showModal.delete && showModal.section === "city" && (
          <ModalManagement
            type={"delete"}
            section={"city"}
            showModal={showModal}
            setShowModal={setShowModal}
            handleAction={deleteCity}
            selectedCity={selectedCity}
          />
        )}

        {showModal.edit && showModal.section === "city" && (
          <ModalManagement
            section="city"
            type={"edit"}
            showModal={showModal}
            setShowModal={setShowModal}
            handleAction={editCity}
            selectedCity={selectedCity}
          />
        )}
        {showModal.delete && showModal.section === "postcode" && (
          <ModalManagement
            type={"delete"}
            section={"postcode"}
            showModal={showModal}
            setShowModal={setShowModal}
            handleAction={deletePostcode}
            selectedPostcode={selectedPostcode}
          />
        )}

        {showModal.edit && showModal.section === "postcode" && (
          <ModalManagement
            section="postcode"
            type={"edit"}
            showModal={showModal}
            setShowModal={setShowModal}
            handleAction={editPostcode}
            selectedPostcode={selectedPostcode}
          />
        )}

        {showModal.edit && showModal.section === "credit" && (
          <ModalManagement
            type="edit"
            handleAction={editCredit}
            setShowModal={setShowModal}
            section="credit"
            selectedCredit={selectedCredit}
            showModal={showModal}
          />
        )}

        {showModal.delete && showModal.section === "credit" && (
          <ModalManagement
            section="credit"
            type="delete"
            showModal={showModal}
            setShowModal={setShowModal}
            handleAction={deleteCredit}
            selectedCredit={selectedCredit}
          />
        )}

        {showModal.edit && showModal.section === "param" && (
          <ModalManagement
            type="edit"
            handleAction={editParam}
            setShowModal={setShowModal}
            section="param"
            selectedParam={selectedParams}
            showModal={showModal}
          />
        )}
      </div>
    </VerticalLayout>
  );
};

export default ManagementGeneral;
