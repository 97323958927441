import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HorizontalNavBar from "src/Components/NavBars/HorizontalNavBar";
import VerticalNavBar from "src/Components/NavBars/VerticalNavBar";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { useTokenStore } from "src/Hooks/useTokenStore";

interface VerticalLayoutProps {
  children: React.ReactNode;
}

const VerticalLayout = ({ children }: VerticalLayoutProps) => {
  const [selectedTab, setSelectedTab] = useLocalStorage("tabSelected", "");
  const accessToken = useTokenStore((s) => s.accessToken);
  const navigate = useNavigate();
  useEffect(() => {
    if (accessToken === "") {
      navigate("/login");
    }
  }, [accessToken]);
  return (
    <div className="xl:flex-row flex flex-col bg-[#F3F3F4] min-h-screen h-full w-screen  ">
      <div className="xl:flex flex-[0_0_15%] hidden  h-full w-full  overflow-hidden">
        <VerticalNavBar
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
      <div className="xl:hidden w-full">
        <HorizontalNavBar
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
      <div className="flex flex-col xl:flex-[0_0_85%] flex-[0_0_100%] h-screen overflow-y-auto  ">
        {children}
      </div>
    </div>
  );
};

export default VerticalLayout;
