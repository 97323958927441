import React, { useEffect, useState } from "react";
import { Input } from "../ui/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { AllInappropiateReasons, InappropiateUserTypes } from "src/types/types";
import ReportUserCard from "./ReportUserCard";
import useLocalStorage from "src/Hooks/useLocalStorage";
import {
  BASE_URL,
  INAPROPIATE_WORKER_PROFILE_REASONS,
} from "src/Config/api.config";
import { Card } from "../ui/card";
import { DatePickerWithRange } from "../ui/date-rangepicker";

interface ReportUserProps {
  inappropiateUsers: InappropiateUserTypes[];
}

const ReportUsers = ({ inappropiateUsers }: ReportUserProps) => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const [allInappropiateReasons, setAllInappropiateReasons] = useState<
    AllInappropiateReasons[]
  >([]);

  const getAdmInappropiateReasons = (name: string) => {
    fetch(`${BASE_URL}${INAPROPIATE_WORKER_PROFILE_REASONS}?name=${name}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllInappropiateReasons(data.result))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAdmInappropiateReasons("");
  }, []);
  return (
    <div className="flex flex-col w-full pb-12 pt-4 ">
      <div className="flex items-center justify-start gap-20 my-6 ml-1">
        <Input placeholder="Enter username" className="bg-white w-[225px]" />
      </div>
      <Card className="flex items-center gap-10 p-4">
        <div className="py-4 ml-1">
          <h1 className="text-[15px] mb-4 font-medium">Filter by</h1>
          <Select>
            <SelectTrigger className="w-[300px] bg-white">
              <SelectValue placeholder="Select a Reason" />
            </SelectTrigger>
            <SelectContent>
              {allInappropiateReasons.map((reason) => (
                <SelectItem value={reason.id}>{reason.name}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="py-4">
          <h1 className="text-[15px] mb-4 font-medium">Sort by</h1>
          <Select>
            <SelectTrigger className="w-[300px] bg-white">
              <SelectValue placeholder="Alphabetically" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="ASC">Ascendente</SelectItem>
              <SelectItem value="DESC">Descendente</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="py-4">
          <h1 className="text-[15px] mb-4 font-medium">Period Date</h1>
          <DatePickerWithRange />
        </div>
      </Card>
      <div className="grid grid-cols-1 md:grid-cols-5  gap-10 my-10">
        {inappropiateUsers.map((user) => (
          <ReportUserCard user={user} />
        ))}
      </div>
    </div>
  );
};

export default ReportUsers;
