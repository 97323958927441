import React from "react";
import { BASE_URL } from "src/Config/api.config";
import useLocalStorage from "./useLocalStorage";
import { useQuery } from "@tanstack/react-query";

export const useAllClients = () => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const getClients = async () => {
    const data = await fetch(`${BASE_URL}adm.clients`, {
      headers: {
        Authorization: `Bearer ${adminData.uid}`,
      },
    }).then((res) => res.json());

    return data.result;
  };

  const {
    data: clientsData,
    isLoading: isClientsDataLoading,
    refetch: refetchClients,
  } = useQuery(["clients"], getClients);

  return { clientsData, refetchClients, isClientsDataLoading };
};

export const useAllWorkers = () => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");

  const getWorkers = async () => {
    const data = await fetch(`${BASE_URL}adm.workers`, {
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    }).then((res) => res.json());
    return data.result;
  };

  const { data: workersData, isLoading: isWorkersLoading } = useQuery(
    ["workers"],
    getWorkers
  );

  return { workersData };
};

export const useAllEmployers = () => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");

  const getAllEmployers = async () => {
    const data = await fetch(`${BASE_URL}adm.employers`, {
      headers: {
        Authorization: "Bearer" + adminData.uid,
      },
    }).then((res) => res.json());

    return data.result;
  };

  const { data: employersData, isLoading: isEmployersLoading } = useQuery(
    ["employers"],
    getAllEmployers
  );

  return { employersData, isEmployersLoading };
};

export const useJobsData = () => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");

  const getJobsData = async () => {
    const data = await fetch(`${BASE_URL}adm.jobs.graph`, {
      headers: {
        Authorization: "Bearer" + adminData.uid,
      },
    }).then((res) => res.json());

    return data.result;
  };

  const { data: jobsData, isLoading: isJobsGraphLoading } = useQuery(
    ["jobsData"],
    getJobsData
  );

  return { jobsData, isJobsGraphLoading };
};
