import { useEffect, useState } from "react";
import { ClientsData, PieData } from "src/types/types";
interface ClientsProps {
  statsData: ClientsData;
}

export const INITIAL_STATE = {
  datasets: [],
  labels: [""],
};

export const useClientsData = ({ statsData }: ClientsProps) => {
  const [data, setData] = useState<PieData>(INITIAL_STATE);
  const [activeStatusData, setActiveStatusData] =
    useState<PieData>(INITIAL_STATE);
  const [topCitiesData, setTopCitiesData] = useState<PieData>(INITIAL_STATE);
  const [validatedStatusData, setValidatedStatusData] =
    useState<PieData>(INITIAL_STATE);

  useEffect(() => {
    setData({
      labels: statsData?.totalClients?.map((clients) => clients.client_type),
      datasets: [
        {
          label: "# of Accounts",
          data: statsData?.totalClients?.map((clients) => clients.cant),
          backgroundColor: ["#30638A", "#D0AD6E", "#63B6C0"],
          borderColor: ["#234763", "#a68956", "#48838a"],
          borderWidth: 2,
        },
      ],
    });
    setActiveStatusData({
      labels: statsData?.activeStatus?.map((clients) => clients.active_status),
      datasets: [
        {
          label: "# of Accounts",
          data: statsData?.activeStatus?.map((clients) => clients.cant),
          backgroundColor: ["#30638A", "#C8D1D4"],
          borderColor: ["#234763", "#9ba1a3"],
          borderWidth: 2,
        },
      ],
    });
    setTopCitiesData({
      labels: statsData?.topCities?.map((clients) => clients.city_name),
      datasets: [
        {
          label: "# of Accounts",
          data: statsData?.topCities?.map((clients) => clients.cant),
          backgroundColor: [
            "#7B3D45",
            "#30638A",
            "#85C1B8",
            "#63B6C0",
            "#99E5B3",
            "#D9F2EE",
            "#B8E6DB",
            "#3B9699",
            "#D0AD6E",
            "#41705E",
          ],
          borderColor: [
            "#5c2e34",
            "#234763",
            "#68968f",
            "#4d8f96",
            "#7dba92",
            "#b8ccc9",
            "#95bab1",
            "#2b6e70",
            "#a68956",
            "#264238",
          ],
          borderWidth: 2,
        },
      ],
    });
    setValidatedStatusData({
      labels: statsData?.validatedStatus?.map(
        (clients) => clients.validated_status
      ),
      datasets: [
        {
          label: "# of Accounts",
          data: statsData?.validatedStatus?.map((clients) => clients.cant),
          backgroundColor: ["#30638A", "#D0AD6E"],
          borderColor: ["#234763", "#a68956"],
          borderWidth: 2,
        },
      ],
    });
  }, [statsData]);

  return { data, validatedStatusData, activeStatusData, topCitiesData };
};
