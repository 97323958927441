import React from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { InappropiateUserTypes } from "src/types/types";
import { ReactComponent as Edit } from "../../assets/img/edit.svg";

interface ReportUserCardProps {
  user: InappropiateUserTypes;
}

const ReportUserCard = ({ user }: ReportUserCardProps) => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");
  return (
    <div className="bg-white rounded-lg border p-4 hover:border-gray-400 transition cursor-pointer w-[300px]">
      <h1 className="text-lg font-medium">
        {user.first_name}
        {user.last_name}
      </h1>
      <ul>
        {user.flagList.slice(0, 3).map((flag, i) => (
          <li
            className="text-gray-500 my-1 text-[14px] flex items-start w-[260px]"
            key={flag.inapropiate_user_reason_id}
          >
            <span className="font-semibold mr-1">{flag.countreason}</span>
            {flag.reason_name}
            {user.flagList.length > 3 && i === user.flagList.length - 1 && (
              <p>...</p>
            )}
          </li>
        ))}
        {user.flagList.length > 3 && (
          <p
            className="text-xs text-blue-300 cursor-pointer w-full"
            onClick={() => {
              navigate(`//reports/users/${user.user_id}`);
            }}
          >
            Show more
          </p>
        )}
      </ul>
      <div className="flex items-center gap-3 justify-end">
        <div
          onClick={() => {
            navigate(`//reports/users/${user.user_id}`);
            setUserInfo(user);
          }}
          className="h-[24px] w-[26px] hover:bg-slate-200 flex items-center justify-center rounded cursor-pointer"
        >
          <Edit className="h-[14px] fill-slate-700" />
        </div>
      </div>
    </div>
  );
};

export default ReportUserCard;
