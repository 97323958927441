import React from "react";
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";

type Props = {};

const SettingsDetails = (props: Props) => {
  return (
    <div className="h-full flex flex-col col-span-2 space-y-3">
      <div className="w-full bg-white p-4 rounded-sm">
        <div className="flex flex-col py-2">
          <p className="mb-2">Email</p>
          <Input type="email" readOnly value={"support@flexihours.com"} />
        </div>
        <div className="flex flex-col py-2">
          <p className="mb-2">Password</p>
          <Input type="password" readOnly value={"support@flexihours.com"} />
        </div>
      </div>
      <div className="w-full flex items-center gap-4 justify-end">
        <div>
          <Button className="bg-white text-black hover:bg-gray-50 font-medium">
            Modify Password
          </Button>
        </div>
        <div>
          <Button className="bg-blue-400 font-medium hover:bg-blue-500">
            Add Account
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SettingsDetails;
