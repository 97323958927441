import React from "react";
import { FlaggedUserReports } from "src/types/types";
import { UserAvatar } from "../ui/UserAvatar";
import { cn, formatTimeToNow } from "src/lib/utils";
import { Button, buttonVariants } from "../ui/Button";

interface UserReportProps {
  report: FlaggedUserReports;
}

const UserReport = ({ report }: UserReportProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        <UserAvatar
          user={{
            name: report.first_name,
            // image: report.author.image,
          }}
          className="h-6 w-6"
        />
        <div className="ml-2 flex items-center gap-x-2">
          <p className="text-sm font-medium text-gray-900">
            {report.first_name ?? "Anonymous"}
          </p>
          <p className="max-h-40 truncate text-xs text-zinc-500">
            {formatTimeToNow(new Date(report.date_created))}
          </p>
        </div>
      </div>
      <p className="text-sm text-zinc-900 mt-2">{report.reason_name}</p>
      <div className="flex gap-2 items-center justify-end">
        <Button
          //   onClick={() => setShowModalFlagUser(showModalFlagUser)}
          className={cn(buttonVariants({ variant: "ghost" }))}
        >
          Delete User
        </Button>
        <Button
          //   onClick={() => {
          //     setShowModalDismissFlag(showModalDismissFlag);
          //     setSelectWorkerId(report.client_id_worker);
          //   }}
          className={cn(buttonVariants({ variant: "subtle" }))}
        >
          Remove Flag
        </Button>
      </div>
    </div>
  );
};

export default UserReport;
