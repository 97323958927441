import React from "react";
import { ReactComponent as Edit } from "../../assets/img/edit.svg";
import { ReactComponent as Trash } from "../../assets/img/trash-can.svg";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { JobInappropiatePost } from "src/types/types";

import CardJobSkeleton from "../Skeleton/CardJobSkeleton";

interface ReportJobCardProps {
  job: JobInappropiatePost;
  isLoading: boolean;
}

const ReportJobCard = ({ job, isLoading }: ReportJobCardProps) => {
  const navigate = useNavigate();
  const [jobInfo, setJobInfo] = useLocalStorage("jobInfo", "");

  if (isLoading) return <CardJobSkeleton />;
  return (
    <div
      onClick={() => {
        navigate(`//reports/jobs/${job.job_id}`);

        setJobInfo(job);
      }}
      className="bg-white rounded-lg border p-4 hover:border-gray-400 transition cursor-pointer w-[300px] relative"
    >
      <h1 className="text-lg font-medium">{job.title}</h1>
      {/* <h3>{formatTimeToNow(new Date(job.date_created))}</h3> */}
      {/* <h3 className="text-[15px] font-normal">{job.description}</h3> */}

      <ul>
        {job.flagList.slice(0, 3).map((flag, i) => (
          // <div className="flex items-center">
          <li
            key={flag.inapropiate_job_reason_id}
            className="text-gray-500 my-1 text-[14px] flex items-start w-[260px]"
          >
            <span className="font-semibold mr-1">{flag.countreason}</span>
            {flag.reason_name}
            {job.flagList.length > 3 && i === job.flagList.length - 1 && (
              <p>...</p>
            )}
          </li>
          // </div>
        ))}
        {job.flagList.length > 3 && (
          <p
            className="text-xs text-blue-500 cursor-pointer w-full"
            onClick={() => {
              navigate(`//reports/jobs/${job.job_id}`);

              setJobInfo(job);
            }}
          >
            Show more
          </p>
        )}
      </ul>
      {/* <p className="text-gray-500 my-4 text-[14px]">{job.name}</p> */}
      <div className="absolute bottom-2 right-4">
        <div
          onClick={() => {
            navigate(`//reports/jobs/${job.job_id}`);

            setJobInfo(job);
          }}
          className="h-[24px] w-[26px] hover:bg-slate-200 flex items-center justify-center rounded cursor-pointer"
        >
          <Edit className="h-[14px] fill-slate-700" />
        </div>
        {/* <div className="h-[24px] w-[26px] hover:bg-slate-200 flex items-center justify-center rounded cursor-pointer">
          <Trash
            className="h-[14px] fill-slate-700"
      
          />
        </div> */}
      </div>
    </div>
  );
};

export default ReportJobCard;
