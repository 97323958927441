import React from "react";
import { CreditCard, DollarSign, Package } from "lucide-react";
import { Heading } from "src/Components/ui/heading";
import { Separator } from "src/Components/ui/separator";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "src/Components/ui/card";
import { Overview } from "src/Components/Charts/Overview";
import { formatter } from "src/lib/utils";
import { graphData } from "src/mockData/graphData";
import VerticalLayout from "src/layout/VerticalLayout";

type Props = {};

const OverviewDashboard = (props: Props) => {
  const totalRevenue = 10000000;
  const salesCount = 10000;
  const stockCount = 1000;
  return (
    <VerticalLayout>
      <div className="flex-col">
        <div className="flex-col">
          <div className="flex-1 space-y-4 p-8 pt-6">
            <Heading title="Dashboard" description="Overview of Flexihours  " />
            <Separator />
            <div className="grid gap-4 grid-cols-3">
              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Total Revenue
                  </CardTitle>
                  <DollarSign className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">
                    {formatter.format(totalRevenue)}
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">Sales</CardTitle>
                  <CreditCard className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">+{salesCount}</div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Products In Stock
                  </CardTitle>
                  <Package className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">{stockCount}</div>
                </CardContent>
              </Card>
            </div>
            <Card className="col-span-4">
              <CardHeader>
                <CardTitle>Overview</CardTitle>
              </CardHeader>
              <CardContent className="pl-2">
                <Overview data={graphData} />
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </VerticalLayout>
  );
};

export default OverviewDashboard;
