import { CreditsData } from "src/types/types";

export const allCreditsData: CreditsData[] = [
    {
        user_id: "1",
        date_created: "2023-06-30",
        credits: "33",
        subscription: "Premium",
        email: "ismael-company@gmail.com"
    },
    {
        user_id: "1",

        date_created: "2023-06-30",
        credits: "33",
        subscription: "Premium",

        email: "ismael-company@gmail.com"
    },
    {
        user_id: "1",

        date_created: "2023-06-30",
        credits: "33",
        subscription: "Premium",
        email: "ismael-company@gmail.com"
    },
    {
        user_id: "1",
        subscription: "Premium",
        date_created: "2023-06-30",
        credits: "33",
        email: "ismael-company@gmail.com"
    },
    {
        user_id: "1",
        subscription: "Premium",
        date_created: "2023-06-30",
        credits: "33",
        email: "ismael-company@gmail.com"
    }
]