import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { AllUsersData } from "src/types/types";

interface TableRowUserDataProps {
  row: AllUsersData;
  isItemSelected: boolean;
  labelId: string;
  handleClick: (args: React.MouseEvent<unknown>, name: string) => void;
}

const TableRowUserData = ({
  handleClick,
  isItemSelected,
  labelId,
  row,
}: TableRowUserDataProps) => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");
  return (
    <TableRow
      sx={{
        "& td": {
          fontSize: 13,
          fontWeight: "400",
          // color: "#333",
          fontFamily: "inherit",
          cursor: "pointer",
        },
      }}
      hover
      onClick={() => {
        setUserInfo(row);
        navigate(`//all-users/${row.user_id}`);
      }}
      // onClick={(event) => handleClick(event, row.user_id as string)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.user_id}
      selected={isItemSelected}
    >
      <TableCell>{row.title}</TableCell>
      <TableCell>{row.overview}</TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell>{row.date_created}</TableCell>
      <TableCell>{row.applied_jobs}</TableCell>
      <TableCell>{row.category}</TableCell>
      <TableCell>{row.skills.map((skill) => skill)}</TableCell>
    </TableRow>
  );
};

export default TableRowUserData;
