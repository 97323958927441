/* eslint-disable react/jsx-pascal-case */
import React from "react";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { FlaggedJob } from "src/types/types";
import PostReport from "./PostComment";
import { Icons } from "../ui/Icons";

interface JobPostFeedProps {
  infoJob: FlaggedJob[];
  isLoading: boolean;
}

const JobPostFeed = ({ infoJob, isLoading }: JobPostFeedProps) => {
  const [jobInfo, setJobInfo] = useLocalStorage("jobInfo", "");

  return (
    <div className="h-full flex flex-col  col-span-2 space-y-6 ">
      <div className=" w-full bg-white p-4 rounded-sm ">
        <p className="max-h-40 mt-1 truncate text-xs text-gray-500">
          Posted by {jobInfo.author}
          {/* <p className="max-h-40 truncate text-xs text-zinc-500">
            {formatTimeToNow(new Date(jobInfo.date_created))}
          </p> */}
        </p>
        <h1 className="text-xl font-semibold py-2 leading-6 text-gray-900">
          {jobInfo.title}
        </h1>
        <div className="flex flex-wrap items-center gap-4">
          {jobInfo.flagList.map(
            (reason: {
              countreason: string;
              inappropiate_job_reason_id: string;
              reason_name: string;
            }) => (
              <div
                key={reason.inappropiate_job_reason_id}
                className="bg-gray-100 text-gray-800 px-2 py-1 rounded flex items-center"
              >
                <p className="text-sm">{reason.reason_name}</p>
                <div className="flex items-center px-2 gap-1 text-gray-500">
                  <p className="text-sm">{reason.countreason}</p>
                  <Icons.error className="h-5 w-4" />
                </div>
              </div>
            )
          )}
        </div>
        <div className="flex flex-col gap-y-4 mt-4">
          <hr className="w-full h-px my-6" />
          <div className="flex flex-col gap-y-6 mt-4">
            {infoJob?.map((report) => (
              <div
                key={report.client_id_worker}
                className="ml-2 py-2 pl-4 border-l-2 border-zinc-200"
              >
                <PostReport
                  key={report.client_id_worker}
                  report={report}
                  isLoading={isLoading}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPostFeed;
