/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { ReactComponent as ArrowDown } from "../../assets/img/arrow-down-solid.svg";
import { ReactComponent as People } from "../../assets/img/people.svg";
import { ReactComponent as Cross } from "../../assets/img/cross.svg";
import { BASE_URL } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/useLocalStorage";
import AllWorkersData from "./AllWorkersData";
import AllEmployersData from "./AllEmployersData";
import Balance, { ChartDataProps } from "./Balance";
import Clients from "./Clients";
import JobsStats from "./JobsStats";
import { ClientDataTypes, ClientsData, PieData } from "src/types/types";
import TableWorkersData from "./TableWorkersData";
import TableEmployerData from "./TableEmployerData";
import InfoStats from "./InfoStats";
import {
  useAllClients,
  useAllEmployers,
  useAllWorkers,
  useJobsData,
} from "src/Hooks/useControlDashboard";
import { Check, Pause, XCircle } from "lucide-react";
import useServicesData from "src/api/useServicesData";
import { Pie } from "react-chartjs-2";
import { INITIAL_STATE } from "src/Hooks/useClientsData";
ChartJS.register(ArcElement, Tooltip, Legend);

const INITIAL_CLIENT_DATA: ClientDataTypes = {
  employers: [],
  filteredEmployers: [],
  filteredWorkers: [],
  workers: [],
};

const INITIAL_STATS_DATA: ClientsData = {
  activeStatus: [],
  jobStatus: [],
  jobsTimeState: { expired: "", ondate: "" },
  jobsTypes: [],
  top10jobs: [],
  topCities: [],
  totalClients: [],
  totalJobs: "",
  validatedStatus: [],
  start_date: 30,
  showClients: "all",
};

const INITIAL_CHART_DATA: ChartDataProps = {
  start_date: 30,
  days: [""],
  fetchedEmployersData: [],
  fetchedWorkersData: [],
};

const ControlDashboard = () => {
  const [timeComparison, setTimeComparison] = useState("last month");

  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  // const [statsData, setStatsData] = useState<ClientsData>({ showClients: "all" });
  const [statsData, setStatsData] = useState<ClientsData>(INITIAL_STATS_DATA);
  const [clientData, setClientData] =
    useState<ClientDataTypes>(INITIAL_CLIENT_DATA);
  // const [chartData, setChartData] = useState<ClientsData>({ start_date: 30 });
  const [chartData, setChartData] =
    useState<ChartDataProps>(INITIAL_CHART_DATA);
  const [showTab, setShowTab] = useState({
    statistics: true,
    workers: true,
    employers: true,
    balance: true,
    clients: true,
    jobs: true,
  });
  var d = new Date();
  useEffect(() => {
    let date = new Date(d.setDate(d.getDate() - chartData?.start_date!))
      .toISOString()
      .split("T")[0];
    getNumberOfNewEmployers(date);
    getNumberOfNewWorkers(date);
  }, [chartData?.start_date]);
  const { clientsData, refetchClients } = useAllClients();
  const { workersData } = useAllWorkers();
  const { employersData, isEmployersLoading } = useAllEmployers();
  const { isJobsGraphLoading, jobsData } = useJobsData();
  const [servicesData, setServicesData] = useState<PieData>(INITIAL_STATE);

  const { services } = useServicesData();

  const getNumberOfNewWorkers = (date: string) => {
    let yesterday = new Date();
    fetch(
      `${BASE_URL}adm.graph.clients?date_start=${date}&date_end=${new Date(
        yesterday.setDate(yesterday.getDate() - 1)
      )
        .toISOString()
        .slice(0, 10)}&client_type=1`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${adminData.uid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setChartData((prev) => ({
          ...prev,
          fetchedWorkersData: data.result.clients,
        }));
      })
      .catch((err) => console.log(err));
  };

  const getNumberOfNewEmployers = (date: string) => {
    let yesterday = new Date();
    fetch(
      `${BASE_URL}adm.graph.clients?date_start=${date}&date_end=${new Date(
        yesterday.setDate(yesterday.getDate() - 1)
      )
        .toISOString()
        .slice(0, 10)}&client_type=2`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${adminData.uid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setChartData((prev) => ({
          ...prev,
          fetchedEmployersData: data.result.clients,
        }));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setStatsData((prev) => ({
      ...prev,
      totalClients: clientsData?.clientsType,
      validatedStatus: clientsData?.validatedStatus,
      topCities: clientsData?.topCities,
      activeStatus: clientsData?.activeStatus,
    }));
  }, [clientsData]);

  useEffect(() => {
    setClientData((prev) => ({
      ...prev,
      workers: workersData,
      filteredWorkers: workersData,
    }));
  }, [workersData]);

  useEffect(() => {
    setClientData((prev) => ({
      ...prev,
      employers: employersData,
      filteredEmployers: employersData,
    }));
  }, [employersData]);

  useEffect(() => {
    setStatsData((prev) => ({
      ...prev,
      jobStatus: jobsData?.jobsStatus,
      jobsTimeState: jobsData?.jobsTimeState,
      jobsTypes: jobsData?.jobsTypes,
      top10jobs: jobsData?.top10_jobsCategories,
      totalJobs: jobsData?.totalJobs,
    }));
  }, [jobsData]);

  useEffect(() => {
    getNumberOfNewWorkers("");
    getNumberOfNewEmployers("");
  }, []);

  useEffect(() => {
    if (
      services?.monitor?.length > 0 &&
      services.totalEnabledServices !== "0"
    ) {
      setServicesData({
        labels: ["Failing", "Successful"],
        datasets: [
          {
            label: "Total of Services",
            data: [services.totalErrorServices, services.totalOkServices],
            borderWidth: 2,
            borderColor: ["white", "#fff"],
            backgroundColor: ["red", "#D0AD6E"],
          },
        ],
      });
    }
  }, [services]);

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          usePointStyle: true,
          padding: 5,
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 50,
        top: 0,
        bottom: 0,
      },
    },
  };

  return (
    <div className="pb-12 pt-4 px-6 flex flex-col w-full">
      <div className="flex items-center gap-8 justify-start">
        <People className="h-10 w-fit" />
        <p className="font-bold tracking-wider">ANALYTICS</p>
      </div>
      <div className=" mt-6">
        <div className="bg-white basis-1 flex items-center rounded-xl shadow max-h-[700px] jobpost overflow-auto mb-[16px]">
          <div className="w-[90%]">
            <Balance
              setTimeComparison={setTimeComparison}
              timeComparison={timeComparison}
              setChartData={setChartData}
              chartData={chartData}
            />
          </div>
          <div className="w-[30%] shadow mr-10 border-2 rounded-lg p-4">
            <h1 className="text-xl font-semibold pb-4">Services Status</h1>
            {services?.totalEnabledServices !== "0" && (
              <div className="xl:w-[350px] xl:h-[250px] laptop:w-[300px]  flex flex-col items-end laptop:h-[225px] w-[350px] h-[270px] py-4  relative">
                <Pie data={servicesData} options={options} />
                <div className="flex  items-center  absolute top-16">
                  {/* <div className="bg-sky-400 h-5 w-5 rounded-full mr-1"></div> */}
                  <p className="text-sm">
                    <span className="font-medium">
                      {services?.totalEnabledServices}
                    </span>{" "}
                    Total Services
                  </p>
                </div>
              </div>
            )}
            {/* <div className="flex flex-col items-start space-y-10">
              <div className="flex items-center space-x-2">
                <div className="h-10 w-10 bg-red-400 rounded-full flex items-center justify-center">
                  <XCircle className="h-6 w-6 text-white" />
                </div>
                <p className="text-sm">
                  <span className="font-semibold">3</span> Failed
                </p>
              </div>
              <div className="flex items-center space-x-2">
                <div className="h-10 w-10 bg-yellow-400 rounded-full flex items-center justify-center">
                  <Pause className="h-6 w-6 text-white" />
                </div>
                <p className="text-sm">
                  <span className="font-semibold">6</span> Paused
                </p>
              </div>
              <div className="flex items-center space-x-2">
                <div className="h-10 w-10 bg-green-400 rounded-full flex items-center justify-center">
                  <Check className="h-6 w-6 text-white" />
                </div>
                <p className="text-sm">
                  <span className="font-semibold">9</span> Successful
                </p>
              </div>
            </div> */}
          </div>
        </div>
        <div className="">
          <InfoStats timeComparison={timeComparison} />
        </div>

        <div className="flex xl:flex-row flex-col gap-4">
          <div className="basis-1/2 mt-6 bg-white flex flex-col rounded-xl shadow h-fit jobpost overflow-hidden">
            <Clients statsData={statsData} />
          </div>
          <div className="basis-1/2 mt-6 bg-white flex flex-col h-fit rounded-xl shadow  jobpost overflow-hidden">
            <JobsStats statsData={statsData} />
          </div>
        </div>
        <div className="bg-white  rounded-xl shadow">
          <div
            className={`flex justify-between items-center gap-4 mb-4  pt-2 mt-8 ${
              showTab.workers && " border-b border-slate-500"
            } `}
          >
            <div className="flex justify-between items-center w-full px-6 mb-2">
              <p className=" tracking-wide text-base font-semibold text-slate-500">
                Filter by Worker
              </p>
              <div className="flex items-center gap-6">
                {/* <Cross className="h-3 fill-slate-500" /> */}
                <ArrowDown
                  className={`h-3 fill-slate-500 cursor-pointer ${
                    showTab.workers
                      ? "animate-toArrowUp rotate-180"
                      : "animate-toArrowDown"
                  } `}
                  onClick={() =>
                    setShowTab((prev) => ({
                      ...prev,
                      workers: !showTab.workers,
                    }))
                  }
                />
              </div>
            </div>
          </div>
          {showTab.workers && (
            <AllWorkersData
              clientData={clientData}
              setClientData={setClientData}
            />
          )}
          <div className="mx-6 my-10">
            <TableWorkersData data={clientData.workers} />
          </div>
        </div>
        <div className="bg-white rounded-xl shadow">
          <div
            className={`flex justify-between items-center gap-4 mb-4  pt-2 mt-8 ${
              showTab.employers && "border-b border-slate-500"
            }`}
          >
            <div className="flex justify-between items-center w-full px-6 mb-2 ">
              <p className=" tracking-wide text-base font-semibold text-slate-500">
                Filter by Employer
              </p>
              <div className="flex items-center gap-6">
                <Cross className="h-3 fill-slate-500" />
                <ArrowDown
                  className={`h-3 fill-slate-500 cursor-pointer ${
                    showTab.employers
                      ? "animate-toArrowUp rotate-180"
                      : "animate-toArrowDown"
                  } `}
                  onClick={() =>
                    setShowTab((prev) => ({
                      ...prev,
                      employers: !showTab.employers,
                    }))
                  }
                />
              </div>
            </div>
          </div>
          {showTab.employers && (
            <AllEmployersData
              clientData={clientData}
              setClientData={setClientData}
            />
          )}
          <div className="mx-6 my-10">
            <TableEmployerData data={clientData.employers} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlDashboard;
