import { ColumnDef } from "@tanstack/react-table";
import CellAction from "./cell-action";

export type JobData = {
  job_id: string;
  title: string;
  applicants: string;
  category_name: string;
  connections: string;
  type_name: string;
  job_status: string;
};

export const columns: ColumnDef<JobData>[] = [
  {
    accessorKey: "job_id",
    header: "Id",
  },
  {
    accessorKey: "title",
    header: "Title",
  },
  {
    accessorKey: "category_name",
    header: "Category",
  },
  {
    accessorKey: "applicants",
    header: "Applicants",
  },
  {
    accessorKey: "connections",
    header: "Connections",
  },
  {
    accessorKey: "type_name",
    header: "Type of Job",
  },
  {
    accessorKey: "job_status",
    header: "Job Status",
  },
  {
    accessorKey: "actions",
    cell: ({ row }) => <CellAction data={row.original} />,
  },
];
