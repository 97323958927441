import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { Button } from "../ui/Button";
import { ReactComponent as Edit } from "../../assets/img/edit.svg";
import { ReactComponent as Trash } from "../../assets/img/trash-can.svg";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { BASE_URL, JOB_SKILLS } from "src/Config/api.config";
import { AllCategories, AllSkills, StateReducer } from "src/types/types";

import ModalAddManagement from "../Modals/ModalAddManagement";
import { ShowItem, ShowModal } from "src/Pages/ManagementOthers";

interface CardManagementSkillsProps {
  allSkills: AllSkills[];
  handleAddition: (args: string) => void;
  setWarning: Dispatch<SetStateAction<string | boolean>>;
  selectedCategory: AllCategories;
  setSelectedCategory: Dispatch<SetStateAction<AllCategories>>;
  setShowItem: Dispatch<SetStateAction<ShowItem>>;
  showItem: ShowItem;
  setShowModal: Dispatch<SetStateAction<ShowModal>>;
  allCategories: AllCategories[];
  getSkillsFromCategory: () => void;
  setSelectedSkill: Dispatch<SetStateAction<AllSkills>>;
}

const CardManagementSkills = ({
  allSkills,
  handleAddition,
  selectedCategory,
  setSelectedCategory,
  setShowItem,
  setShowModal,
  setWarning,
  allCategories,
  showItem,
  getSkillsFromCategory,
  setSelectedSkill,
}: CardManagementSkillsProps) => {
  const skillRef = useRef<HTMLInputElement>(null);
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const [showAddModal, setShowAddModal] = useState(false);

  let addSkillData = new FormData();
  addSkillData.append("_method", "POST");
  addSkillData.append("category_id", selectedCategory?.category_id);
  const addSkill = (skill: string) => {
    addSkillData.append("name", skill);
    fetch(`${BASE_URL}${JOB_SKILLS}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${adminData.uid}`,
      },
      body: addSkillData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          handleAddition("skill");
          getSkillsFromCategory();
          setShowAddModal(false);
        } else {
          setWarning(data.result.error_msg);
          setTimeout(() => {
            setWarning(false);
          }, 3800);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-4 cursor-pointer">
        <p className="font-bold text-lg">Skills</p>
        <Button type="button" onClick={() => setShowAddModal(true)}>
          + Add a skill
        </Button>
      </div>
      <div className="bg-white border flex justify-between rounded shadow dark:bg-background">
        <div className="w-full px-4 py-4 text-sm h-96 overflow-y-auto flex justify-between text-black">
          <div className="flex flex-col w-full overflow-auto jobpost ">
            {allSkills
              ?.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
              })
              .map((skill, i) => {
                return (
                  <div
                    key={skill.skill_id}
                    className="hover:bg-slate-100 w-full cursor-pointer py-2 border-b px-2 flex justify-between items-center"
                    onMouseEnter={() => {
                      setShowItem((prev) => ({
                        ...prev,
                        trash: i,
                        edit: i,
                        where: "skill",
                      }));
                    }}
                    onMouseLeave={() => {
                      setShowItem((prev) => ({
                        ...prev,
                        trash: -1,
                        edit: -1,
                        where: "",
                      }));
                    }}
                  >
                    <p>{skill.name}</p>
                    <div className="flex items-center gap-3">
                      {showItem.edit === i && showItem.where === "skill" && (
                        <div
                          onClick={(prev) => {
                            setSelectedSkill(skill);
                            setShowModal({
                              ...prev,
                              edit: true,
                              section: "skill",
                            });
                          }}
                          className="h-[20px] w-[22px] hover:bg-slate-200 flex items-center justify-center rounded"
                        >
                          <Edit className="h-[10px] fill-slate-700" />
                        </div>
                      )}
                      {showItem.trash === i && showItem.where === "skill" && (
                        <div
                          onClick={(prev) => {
                            setSelectedSkill(skill);
                            setShowModal({
                              ...prev,
                              delete: true,
                              section: "skill",
                              edit: false,
                            });
                          }}
                          className="h-[20px] w-[22px] hover:bg-slate-200 flex items-center justify-center rounded"
                        >
                          <Trash className="h-[14px] fill-slate-700" />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {showAddModal && (
        <ModalAddManagement
          handleAction={addSkill}
          section="skill"
          setShowModal={setShowAddModal}
          type="add"
          setSelectedCategory={setSelectedCategory}
          allCategories={allCategories}
        />
      )}
    </div>
  );
};

export default CardManagementSkills;
