import dayjs, { Dayjs } from "dayjs";
import React, { useRef, useState } from "react";
import TableAllEmployers from "src/Components/AllEmployers/TableAllEmployers";
import FilterEmployers from "src/Components/Filters/FilterEmployers";

import { Card } from "src/Components/ui/card";

import { Heading } from "src/Components/ui/heading";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/Components/ui/select";
import { Separator } from "src/Components/ui/separator";
import { useOnClickOutside } from "src/Hooks/useOnClickOutside";
import VerticalLayout from "src/layout/VerticalLayout";

type Props = {};

const AllEmployers = (props: Props) => {
  const dateRef = useRef(null);
  const [showStartDate, setShowStartData] = useState(false);

  const [showEndDate, setShowEndData] = useState(false);

  useOnClickOutside(dateRef, () => {
    setShowStartData(false);
    setShowEndData(false);
  });

  return (
    <VerticalLayout>
      <div className="flex-1 space-y-4 p-8 pt-6">
        <Heading
          title="Employers / Enterprise"
          description="Here you can view all the details related to the employers"
        />
        <Separator />

        <Card className="w-full my-10 p-4">
          <div className="flex w-full items-center justify-start relative ">
            <div className="mb-2 w-[250px]">
              <h1 className="text-[15px] font-medium mb-1">Sort by</h1>
              <Select>
                <SelectTrigger className="w-[200px] bg-white">
                  <SelectValue placeholder="Sort type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="1">Profile Title</SelectItem>
                  <SelectItem value="2">Applied Jobs</SelectItem>
                  <SelectItem value="3">Messaged</SelectItem>
                  <SelectItem value="4">Connections</SelectItem>
                  <SelectItem value="5">Credits</SelectItem>
                  <SelectItem value="6">Plan</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <FilterEmployers />
          <TableAllEmployers />
        </Card>
      </div>
    </VerticalLayout>
  );
};

export default AllEmployers;
