import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import SingleEmployer from "src/Components/AllEmployers/SingleEmployer";
import AllCompanies from "src/Pages/AllCompanies";
import AllEmployers from "src/Pages/AllEmployers";
import AllUsers from "src/Pages/AllUsers";
import Credits from "src/Pages/Credits";
import DashboardAnalytics from "src/Pages/DashboardAnalytics";
import DashboardManagement from "src/Pages/DashboardManagement";
import Geography from "src/Pages/Geography";
import JobPage from "src/Pages/JobPage";
import ManagementGeneral from "src/Pages/ManagementGeneral";
import ManagementOthers from "src/Pages/ManagementOthers";
import OverviewDashboard from "src/Pages/OverviewDashboard";
import ReportPage from "src/Pages/ReportPage";
import ReportUserPage from "src/Pages/ReportUserPage";
import Services from "src/Pages/Services";
import SingleCompany from "src/Pages/SingleCompany";
import SingleJob from "src/Pages/SingleJob";
import SingleUser from "src/Pages/SingleUser";
import Subscriptions from "src/Pages/Subscriptions";
import UserPage from "src/Pages/UserPage";
import AllJobsTable from "src/Pages/AlJobsTable";
import Settings from "src/Pages/Settings";
import PreRegisters from "src/Pages/PreRegisters";
import AdminAccess from "src/Pages/AdminAccess";

const Layout = () => (
  <>
    <Outlet />
  </>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route path="/login" element={<AdminAccess />} />
      <Route path="/analytics" element={<DashboardAnalytics />} />
      <Route path="" element={<OverviewDashboard />} />
      <Route path="/management" element={<DashboardManagement />} />
      <Route path="/management/general" element={<ManagementGeneral />} />
      <Route path="/management/others" element={<ManagementOthers />} />

      <Route path="/reports/jobs/:id" element={<JobPage />} />
      <Route path="/reports/jobs" element={<ReportPage />} />
      <Route path="/reports/users" element={<ReportUserPage />} />
      <Route path="/reports/users/:id" element={<UserPage />} />
      {/* ) : ( */}
      <Route path="/geography" element={<Geography />} />
      {/* <Route path="/all-jobs" element={<AllJobs />} /> */}
      <Route path="/all-jobs" element={<AllJobsTable />} />
      <Route path="/all-jobs/:id" element={<SingleJob />} />
      <Route path="/all-users" element={<AllUsers />} />
      <Route path="/all-users/:id" element={<SingleUser />} />
      <Route path="/all-employers" element={<AllEmployers />} />
      <Route path="/all-employers/:id" element={<SingleEmployer />} />

      <Route path="/all-companies" element={<AllCompanies />} />
      <Route path="/all-companies/:id" element={<SingleCompany />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/credits" element={<Credits />} />
      <Route path="/subscriptions" element={<Subscriptions />} />
      <Route path="/services" element={<Services />} />
      <Route path="/pre-registers" element={<PreRegisters />} />
    </Route>
  )
);

export default function AdminNavigation() {
  return <RouterProvider router={router} />;
}
