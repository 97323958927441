import { Check, Pencil, X } from "lucide-react";
import React from "react";
import { BASE_URL_IMG } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { Button, buttonVariants } from "../ui/Button";
import { cn } from "src/lib/utils";
import { useModalStore } from "src/Hooks/useModalStore";
import { CompaniesData } from "src/types/types";

interface CompanyDetailsProps {
  company: CompaniesData;
}

const CompanyDetails = ({ company }: CompanyDetailsProps) => {
  // const [companyInfo, setCompanyInfo] = useLocalStorage("companyInfo", "");
  const [
    showModalEditCompany,
    setShowModalEditCompany,
    showModalEditValidation,
    setShowModalEditValidation,
    showModalEditActiveType,
    setShowModalEditActiveType,
  ] = useModalStore((state) => [
    state.showModalEditCompany,
    state.setShowModalEditCompany,
    state.showModalEditValidation,
    state.setShowModalEditValidation,
    state.showModalEditActiveType,
    state.setShowModalEditActiveType,
  ]);

  return (
    <div className="h-full flex flex-col col-span-2 space-y-3">
      <div className="w-full bg-white p-4 rounded-sm">
        <div className="flex items-center justify-between">
          <p className="max-h-40 truncate text-xs text-gray-500">
            Created on {company.date_created}
          </p>
          <img
            alt="logo"
            src={BASE_URL_IMG + company.company_logo}
            className="h-10 w-10 object-cover rounded-full"
          />
        </div>
        <h1 className="text-xl font-semibold py-2 leading-6 text-gray-900">
          {company.name}
        </h1>
        <h3>{company.description}</h3>
      </div>
      <div className="bg-white p-4 rounded-sm flex items-center justify-between">
        <div>
          <h1 className="text-sm text-gray-500">CIF Number</h1>
          <p>{company.cif}</p>
        </div>
        <div>
          <h1 className="text-sm text-gray-500">Phone Number</h1>
          <p>
            +{company.prefix_phone} {company.phone}
          </p>
        </div>
        <div className="flex relative items-start gap-2">
          <div className="flex flex-col relative">
            <h1 className="text-sm text-gray-500">Validated</h1>
            <p>
              {company.validated === "0" ? (
                <X className="text-gray-900" />
              ) : (
                <Check className="text-green-500" />
              )}
            </p>
          </div>
          <div
            onClick={() => setShowModalEditValidation(showModalEditValidation)}
            className=" hover:bg-gray-100 p-1 rounded cursor-pointer flex items-center"
          >
            <Pencil className="h-4 w-4" />
          </div>
        </div>

        <div className="flex relative items-start gap-2">
          <div className="flex flex-col relative">
            <h1 className="text-sm text-gray-500">Active</h1>
            <p>
              {company.active === "0" ? (
                <X className="text-gray-900" />
              ) : (
                <Check className="text-green-500" />
              )}
            </p>
          </div>
          <div
            onClick={() => setShowModalEditActiveType(showModalEditActiveType)}
            className=" hover:bg-gray-100 p-1 rounded cursor-pointer flex items-center"
          >
            <Pencil className="h-4 w-4" />
          </div>
        </div>
      </div>
      <div className="bg-white p-4 rounded-sm flex items-center justify-between">
        <div>
          <h1 className="text-gray-500">Email</h1>
          {/* <p>{company.email}</p> */}
        </div>
      </div>
      <div className="flex w-full justify-end">
        <Button
          onClick={() => setShowModalEditCompany(showModalEditCompany)}
          className={cn(
            buttonVariants({ variant: "default" }),
            "flex items-center gap-2"
          )}
        >
          <Pencil className="h-4 w-4" />
          Edit
        </Button>
      </div>
    </div>
  );
};

export default CompanyDetails;
