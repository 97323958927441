import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from "react";
import { ReactComponent as Edit } from "../../assets/img/edit.svg";
import { ReactComponent as Trash } from "../../assets/img/trash-can.svg";
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";
import { debounce } from "src/Utils/debounce";
import { BASE_URL, CITIES } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { AllCategories, AllCities, City, StateReducer } from "src/types/types";
import ModalAddManagement from "../Modals/ModalAddManagement";
import { toast } from "../ui/use-toast";
import { ShowItem, ShowModal } from "src/Pages/ManagementOthers";

interface CardManagementCitiesProps {
  setShowItem: Dispatch<SetStateAction<ShowItem>>;
  showItem: ShowItem;
  setShowModal: Dispatch<SetStateAction<ShowModal>>;
  handleCitySearch: (args: string) => void;
  getCities: (args: string) => void;
  setSelectedCity: Dispatch<SetStateAction<City>>;
  allCities: AllCities[];
  setSelectedCategory: Dispatch<SetStateAction<AllCategories>>;
}

const CardManagementCities = ({
  setShowItem,
  setShowModal,
  showItem,
  handleCitySearch,
  setSelectedCity,
  allCities,
  getCities,
  setSelectedCategory,
}: CardManagementCitiesProps) => {
  const provinceRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const [showAddModal, setShowAddModal] = useState(false);

  const [adminData, setAdminData] = useLocalStorage("adminData", "");

  const optimisedSearch = useCallback(debounce(handleCitySearch), []);

  let addCityData = new FormData();
  addCityData.append("_method", "POST");
  addCityData.append("country_code", "ES");

  const addCity = (city: string, province?: string) => {
    addCityData.append("name", city);
    addCityData.append("province", province!);
    fetch(`${BASE_URL}${CITIES}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${adminData.uid}`,
      },
      body: addCityData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.cityAdded === "ok") {
          // handleAddition("city");
          toast({
            title: "City Añadida",
            description: `${city} / Province ${province} `,
            variant: "default",
          });
          getCities("");
          setShowAddModal(false);
        } else {
          toast({
            title: data.result.error_msg,
            variant: "destructive",
          });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="w-full">
      <div className="flex items-center w-full justify-between mb-4 cursor-pointer">
        <div className="flex items-center">
          <p className="font-bold text-lg">Cities</p>
          <Input
            ref={cityRef}
            className={`border border-border outline-none text-sm ml-6 h-full w-[205px] bg-white mr-4 px-4 pr-11 `}
            placeholder="Search City"
            type="text"
            onChange={(e) => optimisedSearch(e.target.value)}
          />
        </div>
        <Button onClick={() => setShowAddModal(true)} type="button">
          + Add a city
        </Button>
      </div>
      <div className="bg-white border flex justify-between rounded dark:bg-background">
        <div className="w-full px-4 py-4 text-sm h-96 overflow-y-auto  flex justify-between text-black">
          <div className="flex flex-col w-full overflow-auto jobpost">
            <div className="w-full flex items-center justify-between bg-gray-50 py-2 px-2 rounded-t">
              <div className="flex items-center justify-between w-[80%]">
                <p className="font-medium text-[15px]">City</p>
                <p className="font-medium text-[15px]">State/Province</p>
              </div>
            </div>
            {allCities
              ?.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
              })
              .map((city, i) => {
                return (
                  <div
                    key={city.id}
                    className="hover:bg-slate-100 w-full cursor-pointer rounded  px-2 flex justify-between items-center border-b py-2"
                    onMouseEnter={() => {
                      setShowItem((prev) => ({
                        ...prev,
                        trash: i,
                        edit: i,
                        where: "city",
                      }));
                    }}
                    onMouseLeave={() => {
                      setShowItem((prev) => ({
                        ...prev,
                        trash: -1,
                        edit: -1,
                        where: "",
                      }));
                    }}
                  >
                    <div className="flex items-center justify-between w-[80%]">
                      <p>{city.name}</p>
                      <p>{city.province}</p>
                    </div>
                    <div className="flex items-center gap-3">
                      {showItem.edit === i && showItem.where === "city" && (
                        <div
                          onClick={(prev) => {
                            setSelectedCity(city);
                            setShowModal({
                              ...prev,
                              edit: true,
                              section: "city",
                            });
                          }}
                          className="h-[20px] w-[22px] hover:bg-slate-200 flex items-center justify-center rounded"
                        >
                          <Edit className="h-[10px] fill-slate-700" />
                        </div>
                      )}
                      {showItem.trash === i && showItem.where === "city" && (
                        <div
                          onClick={(prev) => {
                            setSelectedCity(city);
                            setShowModal({
                              ...prev,
                              delete: true,
                              section: "city",
                              edit: false,
                            });
                          }}
                          className="h-[20px] w-[22px] hover:bg-slate-200 flex items-center justify-center rounded"
                        >
                          <Trash className="h-[10px] fill-slate-700" />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {showAddModal && (
        <ModalAddManagement
          handleAction={addCity}
          section="city"
          setShowModal={setShowAddModal}
          type="add"
          setSelectedCategory={setSelectedCategory}
        />
      )}
    </div>
  );
};

export default CardManagementCities;
