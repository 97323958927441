import { CreditCard, DollarSign, Users2 } from "lucide-react";
import React from "react";

import TableCredits from "src/Components/AllCredits/TableCredits";

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "src/Components/ui/card";
import { DatePickerWithRange } from "src/Components/ui/date-rangepicker";
import { Heading } from "src/Components/ui/heading";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/Components/ui/select";
import { Separator } from "src/Components/ui/separator";
import VerticalLayout from "src/layout/VerticalLayout";
import { formatter } from "src/lib/utils";

type Props = {};

const Credits = (props: Props) => {
  const totalRevenue = 1000000;
  const totalCredits = 100000;
  const usersTotal = 666;
  return (
    <VerticalLayout>
      <div className="flex-1 space-y-4 p-8 pt-6">
        <Heading title="Credits" description="Manage all credits movements" />

        <Separator />
        <div className="grid gap-4 grid-cols-3">
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Total Revenue
              </CardTitle>
              <DollarSign className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {formatter.format(totalRevenue)}
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Total Credits
              </CardTitle>
              <CreditCard className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {totalCredits}
                {/* {formatter.format(totalCredits)} */}
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Total Users</CardTitle>
              <Users2 className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {usersTotal}
                {/* {formatter.format(totalRevenue)} */}
              </div>
            </CardContent>
          </Card>
        </div>
        <Card className="w-full my-10 mt-24 p-4">
          <div className="flex w-full items-center justify-start relative">
            <div className="mb-2 w-[250px]">
              <h1 className="text-[15px]  font-medium mb-1">Sort by</h1>
              <Select>
                <SelectTrigger className="w-[200px] bg-white">
                  <SelectValue placeholder="Sort type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="0">Employer</SelectItem>
                  <SelectItem value="1">Job Title</SelectItem>
                  <SelectItem value="2">Date Created</SelectItem>
                  <SelectItem value="3">Applicants</SelectItem>
                  <SelectItem value="4">Messaged</SelectItem>
                  <SelectItem value="5">Connections</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="mt-4">
              <DatePickerWithRange />
            </div>
          </div>

          <TableCredits />
        </Card>
      </div>
    </VerticalLayout>
  );
};

export default Credits;
