import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { visuallyHidden } from "@mui/utils";
import { getComparator, stableSort } from "src/lib/tableUtils";
import { ReactComponent as Search } from "../../assets/img/search.svg";

import { Input } from "../ui/Input";
import TableRowEmployerData from "./TableRowEmployerData";

type Order = "asc" | "desc";

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

interface Data {
  id: string;
  worker: string;
  //   closed_jobs: string;
  posted_jobs: string;
  email: string;
  validated: string;
  credits: string;
  joined_on: string;
}

interface HeadCell {
  disabledPadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    disabledPadding: false,
    numeric: false,
    label: "Image",
  },
  {
    id: "worker",
    disabledPadding: false,
    numeric: false,
    label: "Worker",
  },
  {
    id: "posted_jobs",
    disabledPadding: false,
    numeric: false,
    label: "Applied Jobs",
  },
  {
    id: "email",
    disabledPadding: false,
    label: "Email",
    numeric: false,
  },
  {
    id: "validated",
    disabledPadding: false,
    label: "Validated Account",
    numeric: false,
  },
  {
    id: "credits",
    disabledPadding: false,
    label: "Credits",
    numeric: false,
  },
  {
    id: "joined_on",
    disabledPadding: false,
    label: "Joined on",
    numeric: false,
  },
];

const TableEmployerData = ({ data }: any) => {
  const [rows, setRows] = useState(data);

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("id");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [animationParent] = useAutoAnimate<any>();
  const [searched, setSearched] = useState<string>("");

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  useEffect(() => {
    setRows(data);
  }, [data]);

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const requestSearch = (searchVal: string) => {
    setSearched(searchVal);
    const filteredRows = data.filter((row: any) => {
      return row.first_name.toLowerCase().includes(searchVal.toLowerCase());
    });

    setRows(filteredRows);
  };

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;

    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow
          sx={{
            "& th": {
              fontSize: 14,
              fontWeight: "600",
              color: "rgba(96, 96, 96)",
              fontFamily: "inherit",
            },
          }}
        >
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disabledPadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <div className=" w-full flex items-center justify-start relative">
          {/* <SearchBar
            className="w-[300px]"
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            placeholder={"Search Employer"}
          /> */}

          <Search className="h-4 absolute left-[17rem] top-[28px] fill-[#1d90b3]" />

          <Input
            className="w-[300px] mt-4 ml-2"
            value={searched}
            onChange={(e) => requestSearch(e.target.value)}
            placeholder="Search Employer"
          />
        </div>
        <TableContainer
          sx={{
            marginTop: 2,
            maxHeight: 840,
            "&::-webkit-scrollbar": {
              width: 10,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "cyan",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "lightblue",
              borderRadius: 2,
            },
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            <TableBody>
              {/* <TableBody ref={animationParent}> */}
              {stableSort(rows as any, getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row: any, index) => {
                  const isItemSelected = isSelected(row.id as any);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRowEmployerData
                      key={row.id}
                      row={row}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      handleClick={handleClick}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default TableEmployerData;
