import { ChevronLeftIcon } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import UserDetails from "src/Components/AllUsers/UserDetails";
import ModalSingleUser from "src/Components/Modals/ModalSingleUser";
import { Button, buttonVariants } from "src/Components/ui/Button";
import { Icons } from "src/Components/ui/Icons";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { useModalStore } from "src/Hooks/useModalStore";
import VerticalLayout from "src/layout/VerticalLayout";

type Props = {};

const SingleUser = (props: Props) => {
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");
  const navigate = useNavigate();
  const [
    showModalCloseAccount,
    setShowModalCloseAccount,
    showModalRemoveUser,
    setShowModalRemoveUser,
  ] = useModalStore((state) => [
    state.showModalCloseAccount,
    state.setShowModalCloseAccount,
    state.showModalRemoveUser,
    state.setShowModalRemoveUser,
  ]);

  const closeAccount = () => console.log("close account");
  const removeUser = () => console.log("remove user");
  return (
    <VerticalLayout>
      <div className="container max-w-6xl mx-auto h-full pt-12">
        <div
          onClick={() => {
            navigate("/all-users");
          }}
          className="flex items-center my-10 cursor-pointer"
        >
          <ChevronLeftIcon className="h-4 w-4" />
          <h3>Back</h3>
        </div>
        <h1 className="font-bold text-3xl md:text-4xl">First name Last Name</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-4 py-6">
          <UserDetails />
          <div className="overflow-hidden h-fit rounded-lg border border-gray-200 bg-white order-first md:order-last">
            <div className="bg-emerald-100 px-6 py-4">
              <p className="font-semibold py-3 flex items-center gap-1.5">
                <Icons.user className="h-4 w-4" />
                User Profile
              </p>
            </div>
            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
              <div className="flex justify-between gap-x-4 py-3">
                <p className="text-zinc-500">
                  Come here to check the information about the job.
                </p>
              </div>

              <Button
                onClick={() => setShowModalCloseAccount(showModalCloseAccount)}
                // onClick={() => setShowModal(!showModal)}
                className={buttonVariants({
                  className: "w-full mt-4 mb-6",
                })}
              >
                Close Account
              </Button>
              <Button
                onClick={() => setShowModalRemoveUser(showModalRemoveUser)}
                // onClick={() => setShowModal(!showModal)}
                className={buttonVariants({
                  className: "w-full mt-4 mb-6",
                  variant: "subtle",
                })}
              >
                Remove Account
              </Button>
            </dl>
          </div>
        </div>
      </div>
      {showModalCloseAccount && (
        <ModalSingleUser
          type="close"
          section="account-close"
          onClick={closeAccount}
        >
          <div>
            <p>
              Are you sure you want to close this account{" "}
              <span className="font-medium">{userInfo.title} ?</span>{" "}
            </p>
          </div>
        </ModalSingleUser>
      )}

      {showModalRemoveUser && (
        <ModalSingleUser
          type="remove"
          section="remove-user"
          onClick={removeUser}
        >
          <div>
            <p>
              Are you sure you want to remove the user{" "}
              <span className="font-medium"> {userInfo.title}?</span>
            </p>
            <p> Once the user is removed, there is no going back.</p>
          </div>
        </ModalSingleUser>
      )}
    </VerticalLayout>
  );
};

export default SingleUser;
