import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { BASE_URL } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { serviceName, serviceStatus } from "src/atoms/atoms";
import { useState, useEffect } from "react";

import { ServicesData } from "src/types/types";

const useServicesData = () => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const [nameService, setNameService] = useAtom(serviceName);
  const [rows, setRows] = useState<ServicesData[]>([]);
  const [statusService, setStatusService] = useAtom(serviceStatus);

  const getServices = async () => {
    const data = await fetch(
      `${BASE_URL}adm.sys.services?name_service=${nameService}&result_id=${statusService}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + adminData.uid,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: services, isLoading: isLoadingServices } = useQuery({
    queryKey: ["services", nameService, statusService],
    queryFn: getServices,
  });

  useEffect(() => {
    if (services?.monitor?.length! > 0) {
      setRows(services?.monitor);
    }
  }, [services]);

  return { services, isLoadingServices, setRows, rows };
};

export default useServicesData;
