import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { ReactComponent as ArrowDown } from "../../assets/img/arrow-down-solid.svg";
import { ReactComponent as Cross } from "../../assets/img/cross.svg";
import Select from "react-select";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  Select as SelectUI,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { DisplayData } from "src/types/types";
import { colourStyles } from "src/Pages/ManagementOthers";
import { HandleMonthName } from "src/Utils/HandleMonthName";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const timesBalance = [
  { value: "last month", label: "Last month", number: 30 },
  { value: "last 3 months", label: "Last 3 months", number: 90 },
  { value: "last 6 months", label: "Last 6 months", number: 180 },
];

type EmployerFetchData = {
  date: string;
  cant: string;
};

export interface ChartDataProps {
  days: string[];
  fetchedEmployersData: EmployerFetchData[];
  fetchedWorkersData: EmployerFetchData[];
  start_date: number;
}

interface BalanceProps {
  chartData: ChartDataProps;
  setChartData: Dispatch<SetStateAction<ChartDataProps>>;
  timeComparison: string;
  setTimeComparison: Dispatch<SetStateAction<string>>;
}

const Balance = ({
  chartData,
  setChartData,
  timeComparison,
  setTimeComparison,
}: BalanceProps) => {
  const [displayWorkerData, setDisplayWorkerData] = useState<DisplayData[]>([]);
  const [displayEmployerData, setDisplayEmployerData] = useState<DisplayData[]>(
    []
  );
  const [compressed, setCompressed] = useState(true);
  const [typeWorker, setTypeWorker] = useState("0");

  var getDaysArray = function (start: string, end: string) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt).toISOString().slice(0, 10));
    }
    return arr;
  };

  useEffect(() => {
    var d = new Date();
    let yesterday = new Date();

    setChartData((prev) => ({
      ...prev,
      days: getDaysArray(
        new Date(d.setDate(d.getDate() - 30)).toISOString().split("T")[0],
        new Date(yesterday.setDate(yesterday.getDate() - 1))
          .toISOString()
          .slice(0, 10)
      ),
    }));
  }, []);

  useEffect(() => {
    if (chartData?.days && chartData?.fetchedWorkersData) {
      for (let i = 0; i < chartData?.days?.length; i++) {
        let join = false;
        for (let j = 0; j < chartData?.fetchedWorkersData?.length; j++) {
          if (
            chartData?.days[i] === chartData.fetchedWorkersData[j]?.date &&
            chartData?.days[i] !== undefined
          ) {
            setDisplayWorkerData((current) => [
              ...current,
              chartData.fetchedWorkersData[j],
            ]);
          } else {
            join = true;
          }
        }
        if (join) {
          setDisplayWorkerData((current) => [
            ...current,
            { date: chartData.days[i], cant: "0" },
          ]);
        }
      }
    }
  }, [chartData]);

  useEffect(() => {
    if (chartData?.days && chartData?.fetchedEmployersData) {
      for (let i = 0; i < chartData?.days?.length; i++) {
        let join = false;
        for (let j = 0; j <= chartData?.fetchedEmployersData?.length; j++) {
          if (
            chartData?.days[i] === chartData.fetchedEmployersData[j]?.date &&
            chartData?.days[i] !== undefined
          ) {
            setDisplayEmployerData((current) => [
              ...current,
              chartData.fetchedEmployersData[j],
            ]);
          } else {
            join = true;
          }
        }
        if (join) {
          setDisplayEmployerData((current) => [
            ...current,
            { date: chartData.days[i], cant: "0" },
          ]);
        }
      }
    }
  }, [chartData]);

  //LINE CHART
  const optionsLine = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `${timeComparison} data`,
      },
    },
    scales: {
      y: {
        suggestedMin: 0,
        ticks: {
          precision: 0,
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 20,
      },
    },
  };
  const labels = chartData?.days?.map(
    (v) => HandleMonthName(v.slice(5, 7)).slice(0, 3) + "-" + v.slice(8, 10)
  );
  const dataLine = {
    labels,
    datasets: [
      {
        label: "New Workers",
        data: displayEmployerData?.map((data) => data?.cant),
        borderColor: "#7CB5EC",
        backgroundColor: "#5f8cb8",
      },
      {
        label: "New Employers",
        data: displayWorkerData?.map((data) => data?.cant),
        borderColor: "#FF66A9",
        backgroundColor: "#c44d81",
      },
    ],
  };

  const dataLineWorker = {
    labels,
    datasets: [
      {
        label: "New Workers",
        data: displayEmployerData?.map((data) => data?.cant),
        borderColor: "#7CB5EC",
        backgroundColor: "#5f8cb8",
      },
    ],
  };

  const dataLineEmployer = {
    labels,
    datasets: [
      {
        label: "New Employers",
        data: displayWorkerData?.map((data) => data?.cant),
        borderColor: "#FF66A9",
        backgroundColor: "#c44d81",
      },
    ],
  };

  function modifyStatsDates(daysAgo: any) {
    var d = new Date();
    let yesterday = new Date();
    setChartData((prev) => ({
      ...prev,
      days: getDaysArray(
        new Date(d.setDate(d.getDate() - daysAgo)).toISOString().split("T")[0],
        new Date(yesterday.setDate(yesterday.getDate() - 1))
          .toISOString()
          .slice(0, 10)
      ),
    }));
  }

  return (
    <div className="flex justify-around w-full">
      <div className="flex-1">
        <div
          className={`${
            compressed && "border-b border-slate-500"
          } pt-2 pb-2 px-4 flex justify-between items-center`}
        >
          <p className=" text-base tracking-wide font-semibold text-slate-500">
            Balance
          </p>
          <div className="flex items-center gap-6">
            <Cross className="h-3 fill-slate-500" />
            <ArrowDown
              className={`h-3 cursor-pointer fill-slate-500 ${
                compressed
                  ? "animate-toArrowUp rotate-180"
                  : "animate-toArrowDown"
              }`}
              onClick={() => setCompressed(!compressed)}
            />
          </div>
        </div>
        {compressed && (
          <div className=" flex flex-col  gap-4 p-4">
            <div className="px-4 py-4 h-full flex xl:flex-col flex-row  xl:w-[20%] w-full justify-between ">
              <div className="flex items-center gap-4 pb-12 pt-6 w-fit">
                <Select
                  defaultValue={{
                    value: "last month",
                    label: "Last month",
                    number: 30,
                  }}
                  styles={colourStyles}
                  options={timesBalance}
                  isSearchable={false}
                  className="text-sm "
                  onChange={(e: any) => {
                    setDisplayEmployerData([]);
                    setDisplayWorkerData([]);
                    setTimeComparison(e.value);
                    modifyStatsDates(e.number);
                    setChartData((prev) => ({ ...prev, start_date: e.number }));
                  }}
                />
                {/* <SelectUI>
                  <SelectTrigger>
                    <SelectValue placeholder="Select period" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="30">Last month</SelectItem>
                    <SelectItem value="90">Last 3 months</SelectItem>
                    <SelectItem value="180">Last 6 months</SelectItem>
                  </SelectContent>
                </SelectUI> */}
                <SelectUI onValueChange={(e) => setTypeWorker(e)}>
                  <SelectTrigger className="w-[200px]">
                    <SelectValue placeholder="Workers/Employers" />
                  </SelectTrigger>
                  <SelectContent className="w-[200px]">
                    <SelectItem value="0">Workers/Employers</SelectItem>
                    <SelectItem value="1">Employers</SelectItem>
                    <SelectItem value="2">Workers</SelectItem>
                  </SelectContent>
                </SelectUI>
              </div>
            </div>
            <div className="xl:w-[80%] w-full  xl:mt-8">
              {typeWorker === "0" && (
                <Line
                  //@ts-expect-error

                  options={optionsLine}
                  data={dataLine}
                  // maintainAspectRatio={true}
                  width={"100%"}
                  height={"30%"}
                />
              )}

              {typeWorker === "1" && (
                <Line
                  //@ts-expect-error
                  options={optionsLine}
                  data={dataLineEmployer}
                  // maintainAspectRatio={true}
                  width={"100%"}
                  height={"30%"}
                />
              )}
              {typeWorker === "2" && (
                <Line
                  //@ts-expect-error

                  options={optionsLine}
                  data={dataLineWorker}
                  // maintainAspectRatio={true}
                  width={"100%"}
                  height={"30%"}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Balance;
