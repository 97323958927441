import AuthNavigation from "./AuthNavigation";
import AdminNavigation from "./AdminNavigation";
import { useTokenStore } from "src/Hooks/useTokenStore";

const AuthenticationSwitch = () => {
  const token = useTokenStore((s) => s.accessToken);

  if (token === null) {
    return <AuthNavigation />;
  }
  return <AdminNavigation />;
};

export default AuthenticationSwitch;
