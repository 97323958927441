import { useQuery } from "@tanstack/react-query";
import { Building2, ChevronLeftIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CompanyDetails from "src/Components/AllCompanies/CompanyDetails";
import ModalSingleCompany from "src/Components/Modals/ModalSingleCompany";
import { Button, buttonVariants } from "src/Components/ui/Button";
import { BASE_URL, COMPANIES } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { useModalStore } from "src/Hooks/useModalStore";
import { INITIAL_COMPANY_STATE } from "src/atoms/atoms";
import VerticalLayout from "src/layout/VerticalLayout";
import { CompaniesData } from "src/types/types";

type Props = {};

const SingleCompany = (props: Props) => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const params = useParams();
  const [selectedCompany, setSelectedCompany] = useState<CompaniesData>(
    INITIAL_COMPANY_STATE
  );
  const [
    showModalCloseAccount,
    setShowModalCloseAccount,
    showModalRemoveCompany,
    setShowModalRemoveCompany,
    showModalEditCompany,
    showModalEditValidation,
    showModalEditActiveType,
  ] = useModalStore((state) => [
    state.showModalCloseAccount,
    state.setShowModalCloseAccount,
    state.showModalRemoveCompany,
    state.setShowModalRemoveCompany,
    state.showModalEditCompany,
    state.showModalEditValidation,
    state.showModalEditActiveType,
  ]);

  const getAdmSingleCompany = async () => {
    const data = await fetch(
      `${BASE_URL}${COMPANIES}?company_id=${params.id}`,
      {
        headers: {
          Authorization: "Bearer " + adminData.uid,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result;
  };
  const { data: singleCompany } = useQuery(
    ["single_company"],
    getAdmSingleCompany
  );

  useEffect(() => {
    setSelectedCompany(singleCompany.companies[0]);
  }, [singleCompany]);

  const navigate = useNavigate();
  return (
    <VerticalLayout>
      <div className="container max-w-6xl mx-auto h-full pt-12">
        <div
          onClick={() => {
            navigate("/all-companies");
          }}
          className="flex items-center my-10 cursor-pointer"
        >
          <ChevronLeftIcon className="h-4 w-4" />
          <h3>Back</h3>
        </div>
        <h1 className="font-bold text-3xl md:text-4xl">
          {selectedCompany.name}
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-4 py-6">
          <CompanyDetails company={selectedCompany} />
          <div className="overflow-hidden h-fit rounded-lg border border-gray-200 bg-white order-first md:order-last">
            <div className="bg-emerald-100 px-6 py-4">
              <p className="font-semibold py-3 flex items-center gap-1.5">
                <Building2 className="h-4 w-4" />
                Company Profile
              </p>
            </div>
            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
              <div className="flex justify-between gap-x-4 py-3">
                <p className="text-zinc-500">
                  Come here to check the information about the company.
                </p>
              </div>

              <Button
                onClick={() => setShowModalCloseAccount(showModalCloseAccount)}
                // onClick={() => setShowModal(!showModal)}
                className={buttonVariants({
                  className: "w-full mt-4 mb-6",
                })}
              >
                Close Account
              </Button>
              <Button
                onClick={() =>
                  setShowModalRemoveCompany(showModalRemoveCompany)
                }
                className={buttonVariants({
                  className: "w-full mt-4 mb-6",
                  variant: "subtle",
                })}
              >
                Remove Account
              </Button>
            </dl>
          </div>
        </div>
      </div>
      {showModalEditCompany && (
        <ModalSingleCompany
          section="company"
          onClick={() => console.log("edit ")}
          type="edit"
          selectedCompany={selectedCompany}
          title="Edit company"
          description="Here you can edit the company"
        />
      )}
      {showModalEditValidation && (
        <ModalSingleCompany
          section="company"
          onClick={() => console.log("edit ")}
          type="validation"
          selectedCompany={selectedCompany}
          title="Edit validation"
          description="Here you can change if its validated or not"
        />
      )}
      {showModalEditActiveType && (
        <ModalSingleCompany
          section="company"
          onClick={() => console.log("edit ")}
          type="active"
          selectedCompany={selectedCompany}
          title="Edit Active Status"
          description="Here you can change if its active or not"
        />
      )}
    </VerticalLayout>
  );
};

export default SingleCompany;
