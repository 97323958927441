import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import AdminAccess from "src/Pages/AdminAccess";

const Layout = () => (
  <>
    <Outlet />
  </>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route path="*" element={<AdminAccess />} />
    </Route>
  )
);

export default function AuthNavigation() {
  return <RouterProvider router={router} />;
}
