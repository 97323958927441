import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from "react";
import { Button } from "../ui/Button";
import { Input } from "../ui/Input";
import { AllCategories, AllPostcodes } from "src/types/types";
import { ReactComponent as Edit } from "../../assets/img/edit.svg";
import { ReactComponent as Trash } from "../../assets/img/trash-can.svg";
import { cn } from "src/lib/utils";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { debounce } from "src/Utils/debounce";
import { BASE_URL, POSTCODES } from "src/Config/api.config";
import ModalAddManagement from "../Modals/ModalAddManagement";
import { toast } from "../ui/use-toast";
import { ShowItem, ShowModal } from "src/Pages/ManagementOthers";

interface CardManagementPostcodesProps {
  showItem: ShowItem;
  setShowItem: Dispatch<SetStateAction<ShowItem>>;
  setShowModal: Dispatch<SetStateAction<ShowModal>>;
  allPostcodes: AllPostcodes[];
  setSelectedPostcode: Dispatch<SetStateAction<AllPostcodes>>;
  handlePostcodeSearch: (e: string) => void;
  getPostcodes: (args: string) => void;
  dispatch: Dispatch<any>;
  setSelectedCategory: Dispatch<SetStateAction<AllCategories>>;
}

const CardManagementPostcodes = ({
  setShowItem,
  setShowModal,
  showItem,
  allPostcodes,
  setSelectedPostcode,
  getPostcodes,
  handlePostcodeSearch,
  setSelectedCategory,
}: CardManagementPostcodesProps) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const postcodeRef = useRef<HTMLInputElement>(null);

  const [adminData, setAdminData] = useLocalStorage("adminData", "");

  const optimisedSearch = useCallback(debounce(handlePostcodeSearch), []);

  let addPostcodeData = new FormData();
  addPostcodeData.append("_method", "POST");
  addPostcodeData.append("country_code", "ES");

  const addPostcode = (
    postcode: string,
    locality?: string,
    province?: string
  ) => {
    addPostcodeData.append("postcode", postcode);
    addPostcodeData.append("locality", locality!);
    addPostcodeData.append("state", province!);
    fetch(`${BASE_URL}${POSTCODES}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${adminData.uid}`,
      },
      body: addPostcodeData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.postcodeAdded === "ok") {
          toast({
            title: "Postcode Añadida",
            description: `${locality} / Province ${province} `,
            variant: "default",
          });
          setShowAddModal(false);
          getPostcodes("");
        } else {
          toast({
            title: data.result.error_msg,
            variant: "destructive",
          });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="w-full">
      <div
        className={cn(
          `flex  items-center w-full justify-between mb-4 cursor-pointer`
          // state.showPostcodesInput ? "flex-col items-start gap-4" : ""
        )}
      >
        <div className="flex items-center">
          <p className="font-bold text-lg">Postcodes</p>
          <Input
            ref={postcodeRef}
            className={`border border-border outline-none text-sm ml-6 h-full w-[205px] bg-white mr-4 px-4 pr-11 `}
            placeholder="Search Postcode"
            type="text"
            onChange={(e) => optimisedSearch(e.target.value)}
          />
        </div>

        {/* {!state.showPostcodesInput && ( */}
        <Button onClick={() => setShowAddModal(true)} type="button">
          + Add a postcode
        </Button>
        {/* )} */}
      </div>
      <div className="bg-white border flex justify-between rounded dark:bg-background">
        <div className="w-full px-4 py-4 text-sm h-96 overflow-y-auto flex justify-between text-black">
          <div className="flex flex-col w-full overflow-auto jobpost">
            <div className="w-full flex items-center justify-between bg-gray-50 py-2 px-2 rounded-t">
              <div className="flex items-center justify-between w-[80%]">
                <p className="font-medium text-[15px]">State/Region</p>
                <p className="font-medium text-[15px]">Locality</p>
                <p className="font-medium text-[15px]">Postcode</p>
              </div>
            </div>
            {allPostcodes
              ?.sort(function (a, b) {
                if (a.locality.toLowerCase() < b.locality.toLowerCase())
                  return -1;
                if (a.locality.toLowerCase() > b.locality.toLowerCase())
                  return 1;
                return 0;
              })
              .map((postcode, i) => {
                return (
                  <div
                    key={postcode.id}
                    className="hover:bg-slate-100 w-full border-b cursor-pointer rounded py-2 px-2 flex justify-between items-center"
                    onMouseEnter={() => {
                      setShowItem((prev) => ({
                        ...prev,
                        trash: i,
                        edit: i,
                        where: "postcode",
                      }));
                    }}
                    onMouseLeave={() => {
                      setShowItem((prev) => ({
                        ...prev,
                        trash: -1,
                        edit: -1,
                        where: "",
                      }));
                    }}
                  >
                    <div className="flex items-center justify-between w-[80%]">
                      <p> {postcode.state}</p>{" "}
                      {/* <span className="text-gray-500 mx-10">|</span>{" "} */}
                      <p>{postcode.locality}</p>{" "}
                      {/* <span className="text-gray-500 mx-10">|</span>{" "} */}
                      {postcode.postcode}
                    </div>
                    <div className="flex items-center gap-3">
                      {showItem.edit === i && showItem.where === "postcode" && (
                        <div
                          onClick={(prev) => {
                            setSelectedPostcode(postcode);
                            setShowModal({
                              ...prev,
                              edit: true,
                              section: "postcode",
                            });
                          }}
                          className="h-[20px] w-[22px] hover:bg-slate-200 flex items-center justify-center rounded "
                        >
                          <Edit className="h-[10px] fill-slate-700 " />
                        </div>
                      )}
                      {showItem.trash === i &&
                        showItem.where === "postcode" && (
                          <div
                            onClick={(prev) => {
                              setSelectedPostcode(postcode);
                              setShowModal({
                                ...prev,
                                delete: true,
                                section: "postcode",
                                edit: false,
                              });
                            }}
                            className="h-[20px] w-[22px] hover:bg-slate-200 flex items-center justify-center rounded"
                          >
                            <Trash className="h-[14px] fill-slate-700 " />
                          </div>
                        )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {showAddModal && (
        <ModalAddManagement
          handleAction={addPostcode}
          section="postcode"
          setShowModal={setShowAddModal}
          type="add"
          setSelectedCategory={setSelectedCategory}
        />
      )}
    </div>
  );
};

export default CardManagementPostcodes;
