import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, buttonVariants } from "../ui/Button";
import { NavItem } from "src/types/types";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { cn } from "src/lib/utils";
import { ChevronDown } from "lucide-react";
import { Icons } from "../ui/Icons";

interface SingleTabProps {
  nav: NavItem;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  selectedTab: string;
}

const SingleTab = ({ nav, setSelectedTab, selectedTab }: SingleTabProps) => {
  const navigate = useNavigate();
  //   const [selectedTab, setSelectedTab] = useLocalStorage("tabSelected", "");
  const [showMore, setShowMore] = useState(false);

  //@ts-ignore
  const Icon = Icons[nav.icon || "arrowright"];

  useEffect(() => {
    if (
      nav.options &&
      nav.options.find((option) => option.href === window.location.pathname)
    ) {
      setShowMore(true);
    }
  }, [nav]);

  return (
    <div className={cn("", nav.spacing && "border-b border-border mb-4")}>
      <Button
        key={nav.href}
        onClick={() => {
          // if (window.location.pathname !== "/") {
          //   if (!nav.options) {
          //     navigate("");
          //     setSelectedTab(nav.href);
          //   }
          // }
          if (!nav.options) {
            setSelectedTab(nav.href);
          }
          if (nav.options) {
            setShowMore(!showMore);
          }
          if (nav.href.includes("/")) {
            navigate(nav.href);
          }
        }}
        disabled={nav.disabled}
        className={cn(
          buttonVariants({ variant: "ghost" }),
          `flex gap-3 items-center justify-between mb-4 cursor-pointer text-[16px] w-[200px]   
          `,
          window.location.pathname === nav.href && "font-bold bg-gray-100",
          // ${
          //   window.location.pathname === "/" &&
          //   window.location.pathname === nav.href &&
          //   "font-bold"
          // }
          nav.options &&
            nav.options.find(
              (option) => option.href === window.location.pathname
            ) &&
            "font-bold"
          // nav.options && window.location.pathname.
        )}
      >
        <div className="flex items-center gap-2">
          <Icon className="h-4 w-4" />
          <li className="text-[15px]">{nav.title}</li>
        </div>
        {nav.options && nav.options?.length > 0 && (
          <ChevronDown
            className={cn(
              "h-4 w-4 text-gray-500",
              showMore && "rotate-180 transition"
            )}
          />
        )}
      </Button>
      {showMore && (
        <div className="flex flex-col items-start">
          {nav.options?.map((option) => (
            <Button
              key={option.href}
              className={cn(
                buttonVariants({ variant: "ghost" }),
                `text-[14px] w-[150px] text-left items-start justify-start mb-1`,
                window.location.pathname === option.href &&
                  "font-semibold bg-gray-100"
              )}
              onClick={() => navigate(option.href)}
            >
              {option.title}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SingleTab;
