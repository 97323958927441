import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Input } from "../ui/Input";
import { Button, buttonVariants } from "../ui/Button";
import { cn } from "src/lib/utils";
import {
  AllCategories,
  AllDeclineOfferReasons,
  AllDeclineReasons,
  AllEndWorkReasons,
  AllInappropiateReasons,
  AllPostcodes,
  AllQuitReasons,
  AllSkills,
  City,
  CreditsTypes,
  ParamsData,
} from "src/types/types";
import { useAtom } from "jotai";
import { creditsAtom, editFunctionLoading } from "src/atoms/atoms";
import { ShowModal } from "src/Pages/ManagementOthers";

interface ModalManagementProps {
  type: string;
  showModal: ShowModal;
  setShowModal: Dispatch<SetStateAction<ShowModal>>;
  selectedCategory?: AllCategories;
  selectedPostcode?: AllPostcodes;
  selectedSkill?: AllSkills;
  selectedCity?: City;
  selectedInappropiateReason?: AllInappropiateReasons;
  selectedInappropiateWorkerReason?: AllInappropiateReasons;
  selectedDeclineReason?: AllDeclineReasons;
  selectedDeclineOfferReason?: AllDeclineOfferReasons;
  selectedQuitReason?: AllQuitReasons;
  selectedEndWorkReason?: AllEndWorkReasons;
  selectedCredit?: CreditsTypes;
  selectedParam?: ParamsData;
  handleAction: (args?: any, args2?: any, args3?: any, args4?: any) => void;
  section: string;
}

const ModalManagement = ({
  type,
  showModal,
  setShowModal,
  selectedCategory,
  selectedSkill,
  selectedCity,
  handleAction,
  selectedPostcode,
  section,
  selectedInappropiateReason,
  selectedInappropiateWorkerReason,
  selectedDeclineReason,
  selectedDeclineOfferReason,
  selectedEndWorkReason,
  selectedQuitReason,
  selectedCredit,
  selectedParam,
}: ModalManagementProps) => {
  const [newName, setNewName] = useState("");
  const [postcodeInput, setPostcodeInput] = useState("");
  const [localityInput, setLocalityInput] = useState("");
  const [stateInput, setStateInput] = useState("");
  const [creditNameInput, setCreditNameInput] = useState("");
  const [creditNumberInput, setCreditNumberInput] = useState("");
  const [paramNameInput, setParamNameInput] = useState("");
  const [paramNumberInput, setParamNumberInput] = useState("");
  const [isLoading, setIsLoading] = useAtom(editFunctionLoading);

  const [creditsType, setCreditsType] = useAtom(creditsAtom);

  useEffect(() => {
    if (selectedPostcode && type === "edit") {
      setPostcodeInput(selectedPostcode.postcode);
      setLocalityInput(selectedPostcode.locality);
      setStateInput(selectedPostcode.state);
    }
  }, [selectedPostcode, type]);

  useEffect(() => {
    if (selectedCredit && type === "edit") {
      setCreditNameInput(selectedCredit.name);
      if (creditsType.toLowerCase() === "company") {
        setCreditNumberInput(selectedCredit.credits_company);
      } else if (creditsType.toLowerCase() === "particular") {
        setCreditNumberInput(selectedCredit.credits_employer);
      } else if (creditsType.toLowerCase() === "worker") {
        setCreditNumberInput(selectedCredit.credits_worker);
      }
    }
  }, [selectedCredit, type, creditsType]);

  useEffect(() => {
    if (selectedParam && type === "edit") {
      setParamNameInput(selectedParam.name);
      setParamNumberInput(selectedParam.value);
    }
  }, [selectedParam, type]);

  const handleContinue = () => {
    if (section === "category") {
      if (type === "delete") {
        handleAction(selectedCategory?.category_id);
      } else {
        handleAction(selectedCategory?.category_id, newName);
      }
    } else if (section === "skill") {
      if (type === "delete") {
        handleAction(selectedSkill?.skill_id);
      } else {
        handleAction(selectedSkill?.skill_id, newName);
      }
    } else if (section === "city") {
      if (type === "delete") {
        handleAction(selectedCity?.id);
      } else {
        handleAction(selectedCity?.id, selectedCity?.province, newName);
      }
    } else if (section === "postcode") {
      if (type === "delete") {
        handleAction(selectedPostcode?.id);
      } else {
        handleAction(
          selectedPostcode?.id,
          stateInput,
          postcodeInput,
          localityInput
        );
      }
    } else if (section === "credit") {
      if (type === "delete") {
        handleAction(selectedCredit?.action_id);
      } else {
        handleAction(
          selectedCredit?.action_id,
          creditNameInput,
          creditNumberInput
        );
      }
    } else if (section === "param") {
      if (type === "delete") {
        console.log("delete not available");
      } else {
        handleAction(selectedParam?.id, paramNameInput, paramNumberInput);
      }
    } else if (section === "inappropiate-reason") {
      if (type === "delete") {
        handleAction(selectedInappropiateReason?.id);
      } else {
        handleAction(selectedInappropiateReason?.id, newName);
      }
    } else if (section === "inappropiateworker-reason") {
      if (type === "delete") {
        handleAction(selectedInappropiateWorkerReason?.id);
      } else {
        handleAction(selectedInappropiateWorkerReason?.id, newName);
      }
    } else if (section === "decline-reason") {
      if (type === "delete") {
        handleAction(selectedDeclineReason?.id);
      } else {
        handleAction(selectedDeclineReason?.id, newName);
      }
    } else if (section === "declineoffer-reason") {
      if (type === "delete") {
        handleAction(selectedDeclineOfferReason?.id);
      } else {
        handleAction(selectedDeclineOfferReason?.id, newName);
      }
    } else if (section === "quitwork-reason") {
      if (type === "delete") {
        handleAction(selectedQuitReason?.id);
      } else {
        handleAction(selectedQuitReason?.id, newName);
      }
    } else if (section === "endwork-reason") {
      if (type === "delete") {
        handleAction(selectedEndWorkReason?.id);
      } else {
        handleAction(selectedEndWorkReason?.id, newName);
      }
    }
    setShowModal((prev: any) => ({ ...prev, edit: false, delete: false }));
  };

  useEffect(() => {
    if (section === "inappropiateworker-reason") {
      setNewName(selectedInappropiateWorkerReason?.name!);
    } else if (section === "inappropiate-reason") {
      setNewName(selectedInappropiateReason?.name!);
    } else if (section === "city") {
      setNewName(selectedCity?.name!);
    } else if (section === "skill") {
      setNewName(selectedSkill?.name!);
    } else if (section === "category") {
      setNewName(selectedCategory?.name!);
    } else if (section === "decline-reason") {
      setNewName(selectedDeclineReason?.name!);
    } else if (section === "declineoffer-reason") {
      setNewName(selectedDeclineOfferReason?.name!);
    } else if (section === "quitwork-reason") {
      setNewName(selectedQuitReason?.name!);
    } else if (section === "endwork-reason") {
      setNewName(selectedEndWorkReason?.name!);
    }
  }, [
    section,
    selectedInappropiateWorkerReason,
    selectedInappropiateReason,
    selectedCity,
    selectedSkill,
    selectedCategory,
    selectedDeclineReason,
    selectedDeclineOfferReason,
    selectedQuitReason,
    selectedEndWorkReason,
  ]);

  return (
    <div>
      <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black z-50">
        <div className="fixed z-50 w-[28%] left-[50%] shadow-xl translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white  ">
          <div className="text-xl font-bold border-b px-8 py-6">
            {section === "category" && (
              <>{type === "delete" ? "Delete category" : "Edit Category"}</>
            )}

            {section === "skill" && (
              <>{type === "delete" ? "Delete skill" : "Edit Skill"}</>
            )}
            {section === "city" && (
              <>{type === "delete" ? "Delete City" : "Edit City"}</>
            )}
            {section === "postcode" && (
              <>{type === "delete" ? "Delete Postcode" : "Edit Postcode"}</>
            )}
            {section === "credit" && (
              <> {type === "delete" ? "Delete Credit" : "Edit Credit"}</>
            )}
            {section === "param" && (
              <>{type === "delete" ? "Delete param" : "Edit Param"}</>
            )}
            {section === "inappropiate-reason" && (
              <>{type === "delete" ? "Delete reason" : "Edit Reason"}</>
            )}
            {section === "inappropiateworker-reason" && (
              <>
                {type === "delete"
                  ? "Delete reason worker"
                  : "Edit Reason Worker"}
              </>
            )}
            {section === "decline-reason" && (
              <>
                {type === "delete"
                  ? "Delete Decline reason"
                  : "Edit Decline Reason"}
              </>
            )}
            {section === "declineoffer-reason" && (
              <>
                {type === "delete"
                  ? "Delete Decline Offer reason"
                  : "Edit Decline Offer Reason"}
              </>
            )}
            {section === "quitwork-reason" && (
              <>
                {type === "delete" ? "Delete Quit Reason" : "Edit Quit Reason"}
              </>
            )}
            {section === "endwork-reason" && (
              <>
                {type === "delete"
                  ? "Delete End Work Reason"
                  : "Edit End Work Reason"}
              </>
            )}
          </div>

          <div className="mx-8 my-6">
            {type === "delete" ? (
              <>
                {section === "category" && (
                  <p>
                    Are you sure you want to delete the category{" "}
                    <span className="font-bold">
                      '{selectedCategory?.name}'
                    </span>{" "}
                    ?
                  </p>
                )}
                {section === "skill" && (
                  <p>
                    Are you sure you want to delete the skill{" "}
                    <span className="font-bold">'{selectedSkill?.name}'?</span>
                  </p>
                )}
                {section === "city" && (
                  <p>
                    Are you sure you want to delete the city{" "}
                    <span className="font-bold">'{selectedCity?.name}'?</span>
                  </p>
                )}
                {section === "postcode" && (
                  <p>
                    Are you sure you want to delete the postcode{" "}
                    <span className="font-bold">
                      {selectedPostcode?.locality}?
                    </span>{" "}
                  </p>
                )}
                {section === "credit" && (
                  <p>
                    Are you sure you want to delete the Credit{" "}
                    <span className="font-bold">{selectedCredit?.name}?</span>
                  </p>
                )}
                {section === "inappropiate-reason" && (
                  <p>
                    Are you sure you want to delete the reason{" "}
                    <span className="font-bold">
                      {selectedInappropiateReason?.name}?
                    </span>
                  </p>
                )}
                {section === "inappropiateworker-reason" && (
                  <p>
                    Are you sure you want to delete the worker reason{" "}
                    <span className="font-bold">
                      {selectedInappropiateWorkerReason?.name}?
                    </span>
                  </p>
                )}
                {section === "decline-reason" && (
                  <p>
                    Are you sure you want to delete the decline reason{" "}
                    <span className="font-bold">
                      {selectedDeclineReason?.name}?
                    </span>
                  </p>
                )}
                {section === "declineoffer-reason" && (
                  <p>
                    Are you sure you want to delete the decline offer reason{" "}
                    <span className="font-bold">
                      {selectedDeclineOfferReason?.name}?
                    </span>
                  </p>
                )}
                {section === "quitwork-reason" && (
                  <p>
                    Are you sure you want to delete the quit reason{" "}
                    <span className="font-bold">
                      {selectedQuitReason?.name}?
                    </span>
                  </p>
                )}
                {section === "endwork-reason" && (
                  <p>
                    Are you sure you want to delete the end work reason{" "}
                    <span className="font-bold">
                      {selectedEndWorkReason?.name}?
                    </span>
                  </p>
                )}
              </>
            ) : (
              <>
                {section === "category" && (
                  <div className="flex flex-col gap-4">
                    <p>Change category name:</p>
                    <Input
                      defaultValue={selectedCategory?.name}
                      className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                      onChange={(e) => setNewName(e.target.value)}
                    />
                  </div>
                )}
                {section === "skill" && (
                  <div className="flex flex-col gap-4">
                    <p>Change skill name:</p>
                    <Input
                      defaultValue={selectedSkill?.name}
                      className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                      onChange={(e) => setNewName(e.target.value)}
                    />
                  </div>
                )}
                {section === "city" && (
                  <div className="flex flex-col">
                    <div className="flex flex-col gap-4">
                      <p>Change city name:</p>
                      <Input
                        defaultValue={selectedCity?.name}
                        className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                        onChange={(e) => setNewName(e.target.value)}
                      />
                    </div>
                    <div className="flex flex-col gap-4 mt-2">
                      <p>Change province name:</p>
                      <Input
                        // disabled
                        // readOnly
                        defaultValue={selectedCity?.province}
                        className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                        // onChange={(e) => setNewName(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                {section === "postcode" && (
                  <div className="flex flex-col">
                    <div className="flex flex-col gap-4">
                      <p>Change locality name:</p>
                      <Input
                        defaultValue={localityInput}
                        className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                        onChange={(e) => setLocalityInput(e.target.value)}
                      />

                      <div className="flex flex-col gap-4">
                        <p>Change postcode</p>
                        <Input
                          defaultValue={postcodeInput}
                          className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                          onChange={(e) => setPostcodeInput(e.target.value)}
                        />
                      </div>
                      <div className="flex flex-col gap-4">
                        <p>Change state/province</p>
                        <Input
                          defaultValue={stateInput}
                          className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                          onChange={(e) => setStateInput(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {section === "credit" && (
                  <div className="flex flex-col">
                    <div className="flex flex-col gap-4">
                      <p>Change Credit Name:</p>
                      <Input
                        defaultValue={creditNameInput}
                        className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                        onChange={(e) => setCreditNameInput(e.target.value)}
                      />
                      <div className="flex flex-col gap-4">
                        <p>Change Credits Number</p>
                        <Input
                          defaultValue={creditNumberInput}
                          className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                          onChange={(e) => setCreditNumberInput(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {section === "param" && (
                  <div className="flex flex-col">
                    <div className="flex flex-col gap-4">
                      <p>Change Param Name:</p>
                      <Input
                        defaultValue={paramNameInput}
                        className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                        onChange={(e) => setParamNameInput(e.target.value)}
                      />
                      <div className="flex flex-col gap-4">
                        <p>Change Param Value:</p>
                        <Input
                          defaultValue={paramNumberInput}
                          className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                          onChange={(e) => setParamNumberInput(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {section === "inappropiate-reason" && (
                  <div className="flex flex-col">
                    <div className="flex flex-col gap-4">
                      <p>Change reason name:</p>
                      <Input
                        defaultValue={selectedInappropiateReason?.name}
                        className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                        onChange={(e) => setNewName(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                {section === "inappropiateworker-reason" && (
                  <div className="flex flex-col">
                    <div className="flex flex-col gap-4">
                      <p>Change reason worker name:</p>
                      <Input
                        defaultValue={selectedInappropiateWorkerReason?.name}
                        className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                        onChange={(e) => setNewName(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                {section === "decline-reason" && (
                  <div className="flex flex-col">
                    <div className="flex flex-col gap-4">
                      <p>Change decline reason name:</p>
                      <Input
                        defaultValue={selectedDeclineReason?.name}
                        className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                        onChange={(e) => setNewName(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                {section === "declineoffer-reason" && (
                  <div className="flex flex-col">
                    <div className="flex flex-col gap-4">
                      <p>Change decline offer reason name:</p>
                      <Input
                        defaultValue={selectedDeclineOfferReason?.name}
                        className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                        onChange={(e) => setNewName(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                {section === "quitwork-reason" && (
                  <div className="flex flex-col">
                    <div className="flex flex-col gap-4">
                      <p>Change Quit Reason name:</p>
                      <Input
                        defaultValue={selectedQuitReason?.name}
                        className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                        onChange={(e) => setNewName(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                {section === "endwork-reason" && (
                  <div className="flex flex-col">
                    <div className="flex flex-col gap-4">
                      <p>Change End Work Reason name:</p>
                      <Input
                        defaultValue={selectedEndWorkReason?.name}
                        className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                        onChange={(e) => setNewName(e.target.value)}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end">
            <Button
              className={cn(buttonVariants({ variant: "ghost" }))}
              // className="bg-none text-[0.9rem] font-semibold w-24 h-10 border border-[#2abeeb] text-[#2abeeb] rounded-3xl hover:bg-[#f1efef] cursor-pointer mt-1 transition duration-300"
              onClick={() =>
                setShowModal((prev: any) => ({
                  ...prev,
                  edit: false,
                  delete: false,
                }))
              }
            >
              Cancel
            </Button>
            {section === "postcode" && type !== "delete" && (
              <Button
                isLoading={isLoading}
                disabled={
                  postcodeInput === "" ||
                  localityInput === "" ||
                  stateInput === ""
                }
                // className="bg-[#2abeeb] border-none font-semibold hover:bg-[#1d90b3] text-white w-[110px] rounded-[20px] text-[0.9rem] px-[1rem] cursor-pointer transition duration-300 h-10"
                onClick={() => handleContinue()}
              >
                Save
              </Button>
            )}
            {section === "credit" && type === "edit" && (
              <Button
                isLoading={isLoading}
                disabled={creditNameInput === "" || creditNumberInput === ""}
                onClick={() => handleContinue()}
              >
                Save
              </Button>
            )}
            {section === "param" && type === "edit" && (
              <Button
                isLoading={isLoading}
                disabled={paramNameInput === "" || paramNumberInput === ""}
                onClick={() => handleContinue()}
              >
                Save
              </Button>
            )}
            {section !== "postcode" &&
              section !== "credit" &&
              section !== "param" &&
              type === "edit" && (
                <Button
                  isLoading={isLoading}
                  disabled={type === "edit" && newName === ""}
                  // className="bg-[#2abeeb] border-none font-semibold hover:bg-[#1d90b3] text-white w-[110px] rounded-[20px] text-[0.9rem] px-[1rem] cursor-pointer transition duration-300 h-10"
                  onClick={() => handleContinue()}
                >
                  Save
                </Button>
              )}
            {section !== "postcode" &&
              section !== "credit" &&
              type === "delete" && (
                <Button
                  isLoading={isLoading}
                  // disabled={type === "edit" ? newName === "" : false}
                  // className="bg-[#2abeeb] border-none font-semibold hover:bg-[#1d90b3] text-white w-[110px] rounded-[20px] text-[0.9rem] px-[1rem] cursor-pointer transition duration-300 h-10"
                  onClick={() => handleContinue()}
                >
                  Delete
                </Button>
              )}
            {section === "postcode" && type === "delete" && (
              <Button isLoading={isLoading} onClick={handleContinue}>
                Delete
              </Button>
            )}

            {section === "credit" && type === "delete" && (
              <Button isLoading={isLoading} onClick={handleContinue}>
                Delete
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalManagement;
