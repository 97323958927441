import { ParamsData } from "src/types/types";
import { ShowItem, ShowModal } from "src/Pages/ManagementOthers";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";
import { ReactComponent as Edit } from "src/assets/img/edit.svg";
import { Trash } from "lucide-react";
import { debounce } from "src/Utils/debounce";

interface CardManagementParamsProps {
  handleParamsChange: (args: string) => void;
  allParams: ParamsData[];
  setShowItem: Dispatch<SetStateAction<ShowItem>>;
  setShowModal: Dispatch<SetStateAction<ShowModal>>;
  showItem: ShowItem;
  getParams: (name: string) => void;
  setSelectedParams: Dispatch<SetStateAction<ParamsData>>;
}

const CardManagementParams = ({
  allParams,
  getParams,
  handleParamsChange,
  setSelectedParams,
  setShowItem,
  setShowModal,
  showItem,
}: CardManagementParamsProps) => {
  const optimisedSearch = useCallback(debounce(handleParamsChange), []);
  const [showAddModal, setShowAddModal] = useState(false);

  return (
    <div className="w-full">
      <div className="flex items-center w-full justify-between mb-4 cursor-pointer">
        <div className="flex items-center">
          <p className="font-bol text-lg">Params</p>
          <Input
            className={`border border-border outline-none text-sm ml-6 h-full w-[205px] bg-white mr-4 px-4 pr-11`}
            placeholder="Search Credits"
            type="text"
            onChange={(e) => optimisedSearch(e.target.value)}
          />
        </div>
        <Button onClick={() => setShowAddModal(true)} type="button">
          + Add a Param Type
        </Button>
      </div>
      <div className="bg-white border flex justify-between rounded dark:bg-background">
        <div className="w-full px-4 py-4 text-sm h-96 overflow-y-auto flex justify-between text-black">
          <div className="flex flex-col w-full overflow-auto jobpost">
            <div className="w-full flex items-center justify-between bg-gray-50 py-2 px-2 rounded-t">
              <div className="flex items-center justify-between w-[80%]">
                <p className="font-medium text-[15px]">Id</p>
                <p className="font-medium text-[15px]">Name</p>
                <p className="font-medium text-[15px]">Value</p>
              </div>
            </div>
            {allParams?.length > 0 &&
              allParams

                ?.sort(function (a, b) {
                  if (Number(a.value) < Number(b.value)) {
                    return -1;
                  }
                  if (Number(a.value) > Number(b.value)) {
                    return 1;
                  }
                  return 0;
                })
                ?.map((param, i) => {
                  return (
                    <div
                      key={param.id}
                      className="hover:bg-slate-100 w-full border-b cursor-pointer rounded py-2 px-2 flex justify-between items-center"
                      onMouseEnter={() => {
                        setShowItem((prev) => ({
                          ...prev,
                          trash: i,
                          edit: i,
                          where: "param",
                        }));
                      }}
                      onMouseLeave={() => {
                        setShowItem((prev) => ({
                          ...prev,
                          trash: -1,
                          edit: -1,
                          where: "",
                        }));
                      }}
                    >
                      <div className="flex items-center justify-between w-[80%]">
                        <p>{param.id}</p>
                        <p className=" ">{param.name}</p>
                        <p className="text-left ">{param.value}</p>
                      </div>
                      <div className="flex items-center gap-3">
                        {showItem.edit === i && showItem.where === "param" && (
                          <div
                            onClick={(prev) => {
                              setSelectedParams(param);
                              setShowModal({
                                ...prev,
                                edit: true,
                                section: "param",
                              });
                            }}
                          >
                            <Edit className="h-[10px] fill-slate-700" />
                          </div>
                        )}
                        {/* {showItem.trash === i && showItem.where === "param" && (
                          <div
                            onClick={(prev) => {
                              setSelectedParams(param);
                              setShowModal({
                                ...prev,
                                delete: true,
                                section: "param",
                                edit: false,
                              });
                            }}
                            className="h-[20px] w-[22px] hover:bg-slate-200 flex items-center justify-center rounded"
                          >
                            <Trash className="h-[14px] fill-slate-700" />
                          </div>
                        )} */}
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
      {/* {showAddModal && (
        <ModalAddManagement
          handleAction={addCredits}
          section="credit"
          setShowModal={setShowAddModal}
          type="add"
        />
      )} */}
    </div>
  );
};

export default CardManagementParams;
