import React from "react";

import AuthenticationSwitch from "./AuthenticationSwitch";

export const Router = () => {
  return (
    <React.Suspense fallback={null}>
      <AuthenticationSwitch />
    </React.Suspense>
  );
};
