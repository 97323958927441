import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, buttonVariants } from "../ui/Button";
import { cn } from "src/lib/utils";
import { Input } from "../ui/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { AllCategories } from "src/types/types";

interface ModalAddManagementProps {
  type: string;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  section: string;
  handleAction: (
    args: string,
    args2?: string,
    args3?: string,
    args4?: string
  ) => void;

  setSelectedCategory?: any;
  allCategories?: AllCategories[];
}

const ModalAddManagement = ({
  section,
  setShowModal,
  type,
  handleAction,
  setSelectedCategory,
  allCategories,
}: ModalAddManagementProps) => {
  const [categoryName, setCategoryName] = useState("");
  const [cityName, setCityName] = useState("");
  const [skillName, setSkillName] = useState("");
  const [provinceName, setProvinceName] = useState("");
  const [localityName, setLocalityName] = useState("");
  const [postcodeName, setPostcodeName] = useState("");
  const [reasonName, setReasonName] = useState("");
  const [reasonWorkerName, setReasonWorkerName] = useState("");
  const [creditsName, setCreditsName] = useState("");
  const [creditsNumber, setCreditsNumber] = useState("");

  const handleContinue = () => {
    if (section === "city") {
      handleAction(cityName, provinceName);
    } else if (section === "postcode") {
      handleAction(postcodeName, localityName, provinceName);
    } else if (section === "category") {
      handleAction(categoryName);
    } else if (section === "skill") {
      handleAction(skillName);
    } else if (section === "inappropiate-reason") {
      handleAction(reasonName);
    } else if (section === "inappropiateworker-reason") {
      handleAction(reasonWorkerName);
    } else if (section === "decline-reason") {
      handleAction(reasonName);
    } else if (section === "declineoffer-reason") {
      handleAction(reasonName);
    } else if (section === "endwork-reason") {
      handleAction(reasonName);
    } else if (section === "quitwork-reason") {
      handleAction(reasonName);
    } else if (section === "endwork-reason") {
      handleAction(reasonName);
    } else if (section === "credit") {
      handleAction(creditsName, creditsNumber);
    }
  };
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black/50 text-black z-50">
      <div className="fixed z-[50] w-[28%] left-[50%] shadow-xl translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white  ">
        <div className="text-xl font-bold border-b px-8 py-6">
          {section === "category" && <>{type === "add" && "Add Category"}</>}
          {section === "skill" && <>{type === "add" && "Add Skill"}</>}
          {section === "city" && <>{type === "add" && "Add City"}</>}
          {section === "postcode" && <>{type === "add" && "Add Postcode"}</>}
          {section === "inappropiate-reason" && (
            <>{type === "add" && "Add Job Reason "}</>
          )}
          {section === "inappropiateworker-reason" && (
            <>{type === "add" && "Add Worker Reason "}</>
          )}
          {section === "decline-reason" && (
            <>{type === "add" && "Add Decline Reason"}</>
          )}
          {section === "declineoffer-reason" && (
            <>{type === "add" && "Add Decline Offer Reason"}</>
          )}
          {section === "endwork-reason" && (
            <>{type === "add" && "Add End Work Reason"}</>
          )}
          {section === "quitwork-reason" && (
            <>{type === "add" && "Add Quit Work Reason"}</>
          )}
          {section === "credit" && <>{type === "add" && "Add Credit"}</>}
        </div>
        <div className="mx-8 my-6">
          {section === "categories" && (
            <Input
              type="text"
              autoFocus
              // ref={catRef}
              className={`border border-[#1d90b3] outline-none text-sm h-full w-[205px] bg-[#F9F7F7] rounded mr-4 px-4 pr-11 `}
              placeholder="Enter a category"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
          )}
          {section === "category" && (
            <div className="flex flex-col">
              <div className="flex flex-col gap-4 mb-4">
                <p>Category name:</p>
                <Input
                  className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                  placeholder="Enter Category"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                />
              </div>
            </div>
          )}
          {section === "skill" && (
            <div className="flex flex-col">
              <div className="flex flex-col gap-4 mb-4">
                <p>Select Category</p>
                <Select onValueChange={(e: any) => setSelectedCategory(e)}>
                  <SelectTrigger className="w-[240px] bg-white">
                    <SelectValue placeholder="Select a category" />
                  </SelectTrigger>
                  <SelectContent>
                    {allCategories?.map((cat) => (
                      //@ts-ignore
                      <SelectItem key={cat.name} value={cat}>
                        {cat.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="flex flex-col gap-4">
                <p>Skill Name</p>
                <Input
                  type="text"
                  autoFocus
                  className={`border border-border w-[205px] px-4 text-sm outline-none h-full bg-white rounded  pr-11 `}
                  placeholder="Enter a skill"
                  value={skillName}
                  onChange={(e) => setSkillName(e.target.value)}
                />
              </div>
            </div>
          )}
          {section === "city" && (
            <div className="flex flex-col">
              <div className="flex flex-col gap-4 mb-4">
                <p>City Name:</p>
                <Input
                  value={cityName}
                  placeholder="Enter City"
                  className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                  onChange={(e) => setCityName(e.target.value)}
                />
              </div>
              <div className="flex flex-col gap-4">
                <p>Province/state Name:</p>
                <Input
                  value={provinceName}
                  placeholder="Enter State/province"
                  className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                  onChange={(e) => setProvinceName(e.target.value)}
                />
              </div>
            </div>
          )}
          {section === "postcode" && (
            <div className="flex flex-col">
              <div className="flex flex-col gap-4 mb-4">
                <p>Province/state name:</p>
                <Input
                  value={provinceName}
                  placeholder="Enter State/province"
                  className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                  onChange={(e) => setProvinceName(e.target.value)}
                />
              </div>{" "}
              <div className="flex flex-col gap-4 mb-4">
                <p>Locality Name:</p>
                <Input
                  value={localityName}
                  placeholder="Enter Locality"
                  className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                  onChange={(e) => setLocalityName(e.target.value)}
                />
              </div>
              <div className="flex flex-col gap-4 mb-4">
                <p>Postcode number:</p>
                <Input
                  type="number"
                  placeholder="Enter Postcode"
                  value={postcodeName}
                  className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                  onChange={(e) => setPostcodeName(e.target.value)}
                />
              </div>
            </div>
          )}

          {section === "credit" && (
            <div className="flex flex-col">
              <div className="flex flex-col gap-4 mb-4">
                <p>Credit Name</p>
                <Input
                  value={creditsName}
                  placeholder="Enter Credits Name"
                  className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                  onChange={(e) => setCreditsName(e.target.value)}
                />
              </div>
              <div className="flex flex-col gap-4 mb-4">
                <p>Credit Number:</p>
                <Input
                  value={creditsNumber}
                  placeholder="Enter Credits Number"
                  className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                  onChange={(e) => setCreditsNumber(e.target.value)}
                />
              </div>
            </div>
          )}
          {section === "inappropiate-reason" && (
            <div className="flex flex-col">
              <div className="flex flex-col gap-4">
                <p>Reason name:</p>
                <Input
                  value={reasonName}
                  placeholder="Enter reason"
                  className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                  onChange={(e) => setReasonName(e.target.value)}
                />
              </div>
            </div>
          )}
          {section === "inappropiateworker-reason" && (
            <div className="flex flex-col">
              <div className="flex flex-col gap-4">
                <p>Reason worker name:</p>
                <Input
                  value={reasonWorkerName}
                  placeholder="Enter worker reason"
                  className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                  onChange={(e) => setReasonWorkerName(e.target.value)}
                />
              </div>
            </div>
          )}
          {section === "decline-reason" && (
            <div className="flex flex-col">
              <div className="flex flex-col gap-4">
                <p>Reason name:</p>
                <Input
                  value={reasonName}
                  placeholder="Enter reason"
                  className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                  onChange={(e) => setReasonName(e.target.value)}
                />
              </div>
            </div>
          )}
          {section === "declineworker-reason" && (
            <div className="flex flex-col">
              <div className="flex flex-col gap-4">
                <p>Reason name:</p>
                <Input
                  value={reasonName}
                  placeholder="Enter reason"
                  className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                  onChange={(e) => setReasonName(e.target.value)}
                />
              </div>
            </div>
          )}
          {section === "declineoffer-reason" && (
            <div className="flex flex-col">
              <div className="flex flex-col gap-4">
                <p>Reason name:</p>
                <Input
                  value={reasonName}
                  placeholder="Enter reason"
                  className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                  onChange={(e) => setReasonName(e.target.value)}
                />
              </div>
            </div>
          )}
          {section === "endwork-reason" && (
            <div className="flex flex-col">
              <div className="flex flex-col gap-4">
                <p>Reason name:</p>
                <Input
                  value={reasonName}
                  placeholder="Enter reason"
                  className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                  onChange={(e) => setReasonName(e.target.value)}
                />
              </div>
            </div>
          )}
          {section === "quitwork-reason" && (
            <div className="flex flex-col">
              <div className="flex flex-col gap-4">
                <p>Reason name:</p>
                <Input
                  value={reasonName}
                  placeholder="Enter a reason"
                  className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                  onChange={(e) => setReasonName(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end">
          <Button
            onClick={() => setShowModal(false)}
            className={cn(buttonVariants({ variant: "ghost" }))}
          >
            Cancel
          </Button>
          {section === "city" && (
            <Button
              disabled={cityName === "" || provinceName === ""}
              onClick={() => handleContinue()}
            >
              Save
            </Button>
          )}
          {section === "postcode" && (
            <Button
              disabled={
                postcodeName === "" ||
                localityName === "" ||
                provinceName === ""
              }
              onClick={() => handleContinue()}
            >
              Save
            </Button>
          )}

          {section === "credit" && (
            <Button
              disabled={creditsName === "" || creditsNumber === ""}
              onClick={() => handleContinue()}
            >
              Save
            </Button>
          )}
          {section === "category" && (
            <Button
              disabled={categoryName === ""}
              onClick={() => handleContinue()}
            >
              Save
            </Button>
          )}
          {section === "skill" && (
            <Button
              disabled={skillName === ""}
              onClick={() => handleContinue()}
            >
              Save
            </Button>
          )}
          {section === "inappropiate-reason" && (
            <Button
              disabled={reasonName === ""}
              onClick={() => handleContinue()}
            >
              Save
            </Button>
          )}
          {section === "inappropiateworker-reason" && (
            <Button
              disabled={reasonWorkerName === ""}
              onClick={() => handleContinue()}
            >
              Save
            </Button>
          )}
          {section === "decline-reason" && (
            <Button
              disabled={reasonName === ""}
              onClick={() => handleContinue()}
            >
              Save
            </Button>
          )}
          {section === "declineoffer-reason" && (
            <Button
              disabled={reasonName === ""}
              onClick={() => handleContinue()}
            >
              Save
            </Button>
          )}
          {section === "endwork-reason" && (
            <Button
              disabled={reasonName === ""}
              onClick={() => handleContinue()}
            >
              Save
            </Button>
          )}
          {section === "quitwork-reason" && (
            <Button
              disabled={reasonName === ""}
              onClick={() => handleContinue()}
            >
              Save
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalAddManagement;
