import React, { useState } from "react";
import Modal from "react-modal";
import useLocalStorage from "../Hooks/useLocalStorage";
import { BASE_URL } from "../Config/api.config";
import { StylesModal } from "../Utils/StylesModal";
import { useEffect } from "react";
import { useRef } from "react";

const ShowCreditsModal = ({ showModal, setShowModal, user }) => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const [creditsUser, setCreditsUser] = useState({ total: 0, history: [] });
  const ref = useRef();
  const getCredits = (id) => {
    fetch(`${BASE_URL}clients.wallet?client_id=${id}&mode=balance`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${adminData.uid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setCreditsUser((prev) => ({ ...prev, total: data.result }));
        }
      })
      .catch((err) => console.log(err));
  };

  const getTransactions = (id) => {
    fetch(`${BASE_URL}clients.wallet?client_id=${id}&mode=detail`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${adminData.uid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setCreditsUser((prev) => ({ ...prev, history: data.result }));
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getCredits(user.id);
    getTransactions(user.id);
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (showModal.credits && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal.credits]);

  const closeModal = () => {
    setShowModal((prev) => ({ ...prev, credits: false }));
  };

  return (
    <Modal isOpen={showModal.credits} style={StylesModal} ariaHideApp={false}>
      <div
        className={`fixed z-50  transition duration-150 w-[650px] left-[50%] shadow translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        <div className="flex flex-col px-8 py-6">
          <div className="flex gap-8 items-center">
            <div className="rounded-full w-14 h-14 flex justify-center items-center border border-black">
              <p className="text-xl">{user?.employer_image.toUpperCase()}</p>
            </div>
            <p className="text-xl">
              <span className="font-semibold">
                {user?.first_name} {user?.last_name} Wallet
              </span>{" "}
            </p>{" "}
          </div>
          <div className="mt-4">
            <p className="font-semibold">Total Credits: {creditsUser?.total}</p>
          </div>
          {creditsUser?.history?.length > 0 && (
            <>
              <div className=" flex w-full mt-8 font-semibold px-3">
                <p className="flex-[0_0_33%] ">ID</p>
                <p className="flex-[0_0_33%] ">Credits</p>
                <p className="flex-[0_0_34%] ">Date</p>
              </div>
              <div className="overflow-auto max-h-[500px] jobpost">
                {creditsUser?.history?.map((transaction) => {
                  return (
                    <div
                      key={transaction.id}
                      className="bg-[#e8e8e8]  rounded-lg"
                    >
                      <div className="flex w-full items-center text-sm px-3 py-2 mt-2">
                        <p className="flex-[0_0_33%] ">{transaction.id}</p>
                        <p className="flex-[0_0_33%] ">{transaction.credits}</p>
                        <p className="flex-[0_0_34%] ">
                          {transaction.date_created}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {creditsUser?.history?.length === 0 && (
            <div className="bg-[#e8e8e8]  rounded-lg mt-4">
              <div className="flex w-full items-center justify-center text-sm px-3 py-2 font-semibold">
                No transactions yet
              </div>
            </div>
          )}

          <div className="w-full mt-8 justify-end flex">
            <p
              className="cursor-pointer font-semibold text-[#1d90b3] hover:underline"
              onClick={() => closeModal()}
            >
              Close
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShowCreditsModal;
