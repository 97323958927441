import { create } from "zustand";
import { combine } from "zustand/middleware"

export const profileTypeToken = undefined;
const accessProfileKey = "adminProfileType";

export const userProfileStore = create(
    combine({ profileTypeToken }, (set) => ({
        setProfile: async (x: any) => {
            try {
                await localStorage.setItem(accessProfileKey, x.profileTypeToken);
            } catch {
                // x;
            }
            set(x);
        },
        loadProfile: async () => {
            try {
                let profileTypeToken: any = await localStorage.getItem(accessProfileKey);
                profileTypeToken = profileTypeToken || "";

                set({ profileTypeToken });
            } catch {
                console.log(profileTypeToken);
            }
        },
    }))
);
