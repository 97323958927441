import React, { useState } from "react";
import { Pie } from "react-chartjs-2";
import { ReactComponent as ArrowDown } from "../../assets/img/arrow-down-solid.svg";
import { ReactComponent as Cross } from "../../assets/img/cross.svg";
import { useJobsStatsData } from "src/Hooks/useJobsStatsData";
import { ClientsData } from "src/types/types";

interface JobsStatsProps {
  statsData: ClientsData;
}

const JobsStats = ({ statsData }: JobsStatsProps) => {
  const [showCompressed, setShowCompressed] = useState(true);
  const { jobsStatusData, jobsTypesData, jobsTimesData, jobsTop10 } =
    useJobsStatsData({ statsData });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          usePointStyle: true,
          padding: 5,
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 50,
        top: 0,
        bottom: 0,
      },
    },
  };
  if (!jobsStatusData.labels) return <>Loading...</>;
  return (
    <>
      <div
        className={`${
          showCompressed && "border-b border-slate-500"
        } py-2 flex items-center justify-between`}
      >
        <p className="text-base mx-6 tracking-wide text-slate-500 font-semibold">
          Jobs
        </p>
        <div className="flex items-center gap-6 mx-6">
          <Cross className="h-3 fill-slate-500" />
          <ArrowDown
            className={`h-3 cursor-pointer fill-slate-500 ${
              showCompressed
                ? "animate-toArrowUp rotate-180"
                : "animate-toArrowDown"
            }`}
            onClick={() => setShowCompressed(!showCompressed)}
          />
        </div>
      </div>
      {showCompressed && (
        <div className="flex justify-around px-4 pl-12">
          <div className="flex flex-col">
            <div className="flex mt-4">
              <div className="flex flex-col items-center">
                <p className="h-fit text-sm font-bold w-fit">Job Status</p>
                <div className="xl:w-[350px] xl:h-[250px] laptop:w-[300px] laptop:h-[225px] w-[350px] h-[270px] py-4  relative">
                  <Pie
                    data={jobsStatusData}
                    //@ts-ignore
                    options={options}
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
              </div>
              <div className="flex flex-col items-center">
                <p className="h-fit text-sm font-bold w-fit">Job Types</p>
                <div className="xl:w-[350px] xl:h-[250px] laptop:w-[300px] laptop:h-[225px] w-[350px] h-[270px] py-4  relative">
                  <Pie
                    data={jobsTypesData}
                    //@ts-ignore
                    options={options}
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
              </div>
            </div>
            <div className="flex mt-4">
              <div className="flex flex-col items-center">
                <p className="h-fit text-sm font-bold w-fit">Job Timing</p>
                <div className="xl:w-[350px] xl:h-[250px] laptop:w-[300px] laptop:h-[225px] w-[350px] h-[270px] py-4  relative">
                  <Pie
                    data={jobsTimesData}
                    //@ts-ignore
                    options={options}
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
              </div>
              <div className="flex flex-col items-center">
                <p className="h-fit text-sm font-bold w-fit">
                  Top 10 Categories
                </p>
                <div className="xl:w-[350px] xl:h-[250px] laptop:w-[300px] laptop:h-[225px] w-[350px] h-[270px] py-4  relative">
                  <Pie
                    data={jobsTop10}
                    //@ts-ignore
                    options={options}
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JobsStats;
