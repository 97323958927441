import React from "react";
import ControlDashboard from "src/Components/Analytics/ControlDashboard";
import VerticalLayout from "src/layout/VerticalLayout";

const DashboardAnalytics = () => {
  return (
    <VerticalLayout>
      <ControlDashboard />
    </VerticalLayout>
  );
};

export default DashboardAnalytics;
