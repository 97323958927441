import React, { useRef } from "react";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { useModalStore } from "src/Hooks/useModalStore";
import { Button, buttonVariants } from "../ui/Button";
import { X } from "lucide-react";

interface ModalSingleJobProps {
  type: string;
  section: string;
  children: React.ReactNode;
  onClick: (args1?: any, args2?: any) => void;
}

const ModalSingleJob = ({
  children,
  onClick,
  section,
  type,
}: ModalSingleJobProps) => {
  const [
    showModalCloseJob,
    setShowModalCloseJob,
    showModalRemoveJob,
    setShowModalRemoveJob,
  ] = useModalStore((state) => [
    state.showModalCloseJob,
    state.setShowModalCloseJob,
    state.showModalRemoveJob,
    state.setShowModalRemoveJob,
  ]);
  const [jobInfo, setJobInfo] = useLocalStorage("jobInfo", "");
  const modalRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={modalRef}
      className="fixed top-0 right-0 w-full h-full bg-black/50 text-black z-50"
    >
      <div className="fixed  z-[50] w-[28%] left-[50%] shadow-xl translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white">
        <div className="flex items-center justify-between border-b px-8 py-6">
          <h1 className="text-xl font-bold">
            {section === "job-close" && <>{type === "close" && "Close job"}</>}
            {section === "remove-job" && (
              <>{type === "remove" && "Remove job"}</>
            )}
          </h1>
          {showModalCloseJob && (
            <Button
              variant="subtle"
              className="h-6 w-6 p-0 rounded-md "
              onClick={() => setShowModalCloseJob(showModalCloseJob)}
            >
              <X aria-label="close modal" className="h-4 w-4" />
            </Button>
          )}
          {showModalRemoveJob && (
            <Button
              variant="subtle"
              className="h-6 w-6 p-0 rounded-md "
              onClick={() => setShowModalRemoveJob(showModalRemoveJob)}
            >
              <X aria-label="close modal" className="h-4 w-4" />
            </Button>
          )}
        </div>
        <div className="mx-8 my-6">{children}</div>
        <div className="mx-8 my-6">
          {section === "job-close" && (
            <Button
              onClick={() => {
                onClick();
                setShowModalCloseJob(showModalCloseJob);
              }}
              className={buttonVariants({
                className: "w-full mt-4 mb-6 ",
              })}
            >
              Close job
            </Button>
          )}
          {section === "remove-job" && (
            <Button
              onClick={() => {
                onClick();
                setShowModalRemoveJob(showModalRemoveJob);
              }}
              className={buttonVariants({
                className: "w-full mt-4 mb-6 ",
                variant: "subtle",
              })}
            >
              Remove job
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalSingleJob;
