import React, { useEffect, useRef, useState, useCallback } from "react";
import { DataTable } from "src/Components/AllCompanies/data-table";
import VerticalLayout from "src/layout/VerticalLayout";
import { columns } from "src/Components/AllCompanies/columns";
import { Input } from "src/Components/ui/Input";
import { debounce } from "src/Utils/debounce";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/Components/ui/select";
import { Checkbox } from "src/Components/ui/Checkbox";
import { useAtom } from "jotai";
import { X } from "lucide-react";
import { citiesFilter, postcodeFilter } from "src/atoms/atoms";

import { AllCities, CompaniesData } from "src/types/types";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { BASE_URL, COMPANIES } from "src/Config/api.config";
import { getCities } from "src/getContent/get-cities";
import SearchPostcodes from "src/Components/searchCommands/SearchPostcodes";
import { Heading } from "src/Components/ui/heading";
import { Separator } from "src/Components/ui/separator";
import { Card } from "src/Components/ui/card";
import { useQuery } from "@tanstack/react-query";

const AllCompanies = () => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const [companies, setCompanies] = useState<CompaniesData[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputCifRef = useRef<HTMLInputElement>(null);
  const [inputSearch, setInputSearch] = useState("");
  const [inputSearchCif, setInputSearchCif] = useState("");

  const [selectedSort, setSelectedSort] = useState("date_created");
  const [sortDirection, setSortDirection] = useState("desc");

  const [activeType, setActiveType] = useState<string>("1");
  const [validateType, setValidateType] = useState<string>("1");
  const [countryCodeType, setCountryCodeType] = useState<string[]>([]);

  const [cities, setCities] = useAtom(citiesFilter);
  const [citiesId, setCitiesId] = useState<string[]>([]);
  const [citiesToShow, setCitiesToShow] = useState<AllCities[]>([]);

  const [postcodeSelected, setPostcodeSelected] = useAtom(postcodeFilter);

  const getAdmCompanies = async () => {
    const data = await fetch(
      `${BASE_URL}${COMPANIES}?city_id_list=${citiesId}&active=${activeType}&validated=${validateType}&order=${selectedSort}&order_sense=${sortDirection}&post_code_id_list=${
        postcodeSelected.id
      }${inputSearch !== "" ? `&company_name=${inputSearch}` : ""}${
        inputSearchCif !== "" ? `&cif=${inputSearchCif}` : ""
      }`,
      {
        headers: {
          Authorization: "Bearer " + adminData.uid,
        },
      }
    )
      .then((res) => res.json())
      // .then((data) => setCompanies(data.result.companies))
      .catch((err) => console.log(err));
    return data.result?.companies;
  };

  const { data: companiesData, isLoading } = useQuery(
    [
      "companies",
      citiesId,
      validateType,
      selectedSort,
      postcodeSelected,
      inputSearch,
      inputSearchCif,
    ],
    getAdmCompanies
  );

  useEffect(() => {
    if (!isLoading) {
      setCompanies(companiesData);
    }
  }, [companiesData]);

  const getAllCities = async () => {
    const data = await getCities("", adminData.uid);
    setCities(data);
  };

  useEffect(() => {
    getAllCities();
  }, []);

  const handleSearchCompany = (e: string) => {
    setInputSearch(e);
  };

  const handleSearchCif = (e: string) => {
    setInputSearchCif(e);
  };

  const optimisedSearch = useCallback(debounce(handleSearchCompany), []);
  const optimisedSearchCif = useCallback(debounce(handleSearchCif), []);

  const handleCompanyCountryCode = (e: string) => {
    if (!countryCodeType?.includes(e)) {
      setCountryCodeType((prev) => [...prev, e]);
    } else {
      setCountryCodeType(countryCodeType.filter((type) => type !== e));
    }
  };

  const handleChangeJobCity = (cityId: string) => {
    if (!citiesId.includes(cityId)) {
      setCitiesId((prev) => [...prev, cityId]);
    } else {
      setCitiesId(citiesId.filter((id) => id !== cityId));
    }
  };

  const handleFilterJobCity = (cityId: string) => {
    const selectedCity: any = cities.find((city) => city.id === cityId);
    setCitiesToShow((prev) => [...prev, selectedCity]);
  };

  const handleRemoveJobCity = (cityId: string) => {
    setCitiesId(citiesId.filter((city) => city !== cityId));
    setCitiesToShow(citiesToShow.filter((city) => city.id !== cityId));
  };

  useEffect(() => {
    console.log("activeType", activeType);
  }, [activeType]);

  return (
    <VerticalLayout>
      <div className="flex-1 space-y-4 p-8 pt-6">
        <Heading
          title="Companies"
          description="Here you can view all the details related to the companies"
        />
        <Separator />
        <Card className="flex items-center gap-10 mb-4  pb-2 mt-4 p-4">
          <div className="">
            <h1 className="text-[15px]  font-medium mb-1">Search Name</h1>

            <Input
              ref={inputRef}
              className="w-[300px] "
              onChange={(e) => optimisedSearch(e.target.value)}
              placeholder="Search company"
            />
          </div>
          <div className="">
            <h1 className="text-[15px] font-medium mb-1">Search Cif</h1>
            <Input
              ref={inputCifRef}
              className="w-[300px] "
              onChange={(e) => optimisedSearchCif(e.target.value)}
              placeholder="Search Cif"
            />
          </div>
        </Card>
        <Card className="p-4">
          <div className="w-[60%] flex items-start gap-14">
            <div className="">
              <h1 className="text-[15px] font-medium mb-1">Sort by</h1>
              <Select
                onValueChange={(e) => {
                  setSelectedSort(e);
                }}
              >
                <SelectTrigger className="w-[200px] bg-white">
                  <SelectValue placeholder="Sort type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="date_created">Date Created</SelectItem>
                  <SelectItem value="company_name">Company Name</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="">
              <h1 className="text-[15px] font-medium mb-1">Sort Direction</h1>
              <Select onValueChange={(e) => setSortDirection(e)}>
                <SelectTrigger className="w-[200px] bg-white">
                  <SelectValue placeholder="Direction" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="ASC">Ascendente</SelectItem>
                  <SelectItem value="DESC">Descendente</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="mb-2">
              <h1 className="text-[15px]  font-medium mb-1">Cities</h1>
              <Select
                onValueChange={(e) => {
                  handleChangeJobCity(e);

                  handleFilterJobCity(e);
                }}
              >
                <SelectTrigger className="bg-white w-[200px]">
                  <SelectValue placeholder="Select City" />
                </SelectTrigger>
                <SelectContent>
                  {cities.map((option) => (
                    <SelectItem key={option.id} value={option.id}>
                      {option.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="mb-2">
              <h1 className="text-[15px] font-medium mb-1">Postcodes</h1>
              <SearchPostcodes className="bg-white" />
            </div>
          </div>
        </Card>
        <Card className=" p-4">
          <div className="flex items-start gap-14">
            <div className="flex flex-col ">
              <h1 className="text-[14px] font-semibold">Active</h1>
              <div className="flex items-center mt-2 gap-4 text-[14px]">
                <Checkbox
                  onCheckedChange={(checked) => {
                    setActiveType("0");
                    // handleCompanyActive("0");
                  }}
                  checked={activeType.includes("0")}
                />
                <p>No Active</p>
              </div>
              <div className="flex items-center mt-2 gap-4 text-[14px]">
                <Checkbox
                  onCheckedChange={(checked) => {
                    setActiveType("1");
                    // handleCompanyActive("1");
                  }}
                  checked={activeType.includes("1")}
                />
                <p>Active</p>
              </div>
            </div>
            <div className="flex flex-col ">
              <h1 className="text-[14px] font-semibold">Validated</h1>
              <div className="flex items-center mt-2 gap-4 text-[14px]">
                <Checkbox
                  onCheckedChange={(checked) => {
                    setValidateType("0");

                    // handleCompanyValidated("0");
                  }}
                  checked={validateType.includes("0")}
                />
                <p>Not Validated</p>
              </div>
              <div className="flex items-center mt-2 gap-4 text-[14px]">
                <Checkbox
                  onCheckedChange={(checked) => {
                    setValidateType("1");
                    // handleCompanyValidated("1");
                  }}
                  checked={validateType.includes("1")}
                />
                <p>Validated</p>
              </div>
            </div>
            <div className="flex flex-col ">
              <h1 className="text-[14px] font-semibold">Country</h1>
              <div className="flex items-center mt-2 gap-4 text-[14px]">
                <Checkbox
                  onCheckedChange={(checked) => {
                    handleCompanyCountryCode("ES");
                  }}
                  checked={countryCodeType.includes("ES")}
                />
                <p>Spain</p>
              </div>
              <div className="flex items-center mt-2 gap-4 text-[14px]">
                <Checkbox
                  onCheckedChange={(checked) => {
                    handleCompanyCountryCode("1");
                  }}
                  checked={validateType.includes("Others")}
                />
                <p>Others</p>
              </div>
            </div>
          </div>
        </Card>
        <Card className="w-full flex items-center justify-start relative mb-2 gap-4">
          {citiesToShow?.length > 0 && (
            <div className="my-5">
              <p className="text-sm mb-2 font-medium">Cities</p>
              <div className="flex flex-wrap items-center gap-4">
                {citiesToShow?.map((city) => (
                  <div
                    onClick={() => handleRemoveJobCity(city.id)}
                    className="bg-gray-200 cursor-pointer px-2 rounded flex items-center gap-2 py-1"
                  >
                    <p className="text-sm">{city.name}</p>
                    <X className="h-4 w-4" />
                  </div>
                ))}
              </div>
            </div>
          )}
        </Card>
        <div className="  mt-10 ">
          <DataTable columns={columns} data={companies} />
        </div>
      </div>
    </VerticalLayout>
  );
};

export default AllCompanies;
