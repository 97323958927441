import VerticalLayout from "src/layout/VerticalLayout";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../Components/ui/select";
import { ReactComponent as Search } from "../assets/img/search.svg";
import { Input } from "src/Components/ui/Input";
import { BASE_URL, PRECLIENT_REGISTER } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/Components/ui/table";
import { Heading } from "src/Components/ui/heading";
import { useNavigate } from "react-router-dom";

const sortOptions = [
  {
    value: "asc",
    label: "ASC",
  },
  {
    value: "desc",
    label: "DESC",
  },
];
const PreRegisters = () => {
  const navigate = useNavigate();
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const [preregisters, setPregisters] = useState<
    {
      client_name: string;
      created_at: string;
      email: string;
      id: string;
    }[]
  >([]);

  const getPregisters = async () => {
    const data = await fetch(`${BASE_URL}${PRECLIENT_REGISTER}`, {
      headers: {
        Authorization: "Bearer " + adminData?.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPregisters(data.result.preclients);
        if (data.error_msg === "Token invalid or expired") {
          navigate("/login");
        }
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
    return data;
  };

  useEffect(() => {
    getPregisters();
  }, []);

  return (
    <VerticalLayout>
      <div className="flex-1 space-y-4 p-8 pt-6">
        {/* <div className="relative">
          <p className="absolute text-xs font-bold bg-white text-[#1d90b3] -top-2 left-3 w-fit px-1">
            PreRegisters
          </p>
          <Search className="h-4 absolute left-[13.5rem] top-[13px] fill-[#1d90b3]" />
          <div className="flex items-center w-1/3 gap-10">
            <Input
              type="text"
              //   onChange={(e) => filterBySearch(e.target.value)}
              placeholder="Search by name"
              // className="bg-white outline-none border border-[#1d90b3] text-sm px-10 py-3 rounded w-[250px]"
            />
            <Select>
              <SelectTrigger>
                <SelectValue placeholder="Sort by" />
              </SelectTrigger>
              <SelectContent>
                {sortOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div> */}
        <Heading
          title="Waitlist"
          description="Here you can view all the details related to the waitlist"
        />
        <Table className="w-full px-8 py-8 justify-between bg-white ">
          <TableHeader className="border-b border-border">
            <TableRow>
              <TableHead>Client</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Registered at</TableHead>
              <TableHead>Id</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {preregisters?.map((client) => (
              <TableRow>
                <TableCell>{client.client_name}</TableCell>
                <TableCell>{client.email}</TableCell>
                <TableCell>{client.created_at}</TableCell>
                <TableCell>{client.id}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </VerticalLayout>
  );
};

export default PreRegisters;
