import { TableCell, TableRow } from "@mui/material";
import { Cpu, MoreHorizontal, XCircleIcon } from "lucide-react";
import { useState } from "react";
import { ServicesData } from "src/types/types";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { BASE_URL } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/useLocalStorage";
import {
  CLOSE_EXPIRE_JOBS,
  CLOSE_HIRED_REQUIRED_JOBS,
  DELETED_CLOSED_JOB,
  SERVICES_LOGOUT_CHAT,
} from "src/apiUrls";
import useServicesProcesses from "src/Hooks/useServicesProcesses";

interface TableRowServiceProps {
  row: ServicesData;
  isItemSelected: boolean;
  labelId: string;
  handleClick: (args: React.MouseEvent<unknown>, name: string) => void;
}

const TableRowService = ({
  handleClick,
  isItemSelected,
  labelId,
  row,
}: TableRowServiceProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const {
    getServicesClosedExpireJobs,
    getServicesClosedHiredRequiredJobs,
    getServicesDeleteCloseJob,
    getServicesDisableToken,
    getServicesLogoutChat,
  } = useServicesProcesses();

  console.log(row);

  return (
    <TableRow
      sx={{
        "& td": {
          fontSize: 13,
          fontWeight: "400",
          // color: "#333",
          fontFamily: "inherit",
          cursor: "pointer",
        },
      }}
      hover
    >
      <TableCell>
        {row.status === "1" ? (
          <>
            <div className="h-7 w-7 shadow rounded-full flex items-center justify-center">
              <div className=" bg-green-500 h-3 w-3 shadow rounded-full shadow-green-500" />
            </div>
          </>
        ) : (
          <XCircleIcon className="text-white bg-red-500 h-5 w-5 rounded-full" />
        )}
      </TableCell>
      <TableCell>
        <div>
          <p className="text-sm font-medium text-[#333]">{row.service_id}</p>
        </div>
      </TableCell>
      <TableCell>
        <div className="flex items-center space-x-2">
          <Cpu className="h-4 w-4 text-gray-400" />
          <p className="font-semibold">{row.name_service}</p>
        </div>
      </TableCell>
      <TableCell>
        <div className="flex items-start gap-1">
          {row.last_results?.split(",").map((result) => {
            if (result === "200")
              return (
                <div
                  key={result}
                  className="h-5 w-[3px] rounded-full bg-green-400"
                />
              );
            else if (result === "400") {
              return (
                <div
                  key={result}
                  className="h-5 w-[3px]  rounded-full bg-gray-400"
                />
              );
            }
          })}
        </div>
      </TableCell>
      <TableCell>
        <p className="text-gray-400 font-semibold">{row.last_failed_date}</p>
      </TableCell>
      <TableCell>
        <p className="bg-gray-100 text-[#333] font-medium w-fit px-6 py-1 rounded">
          {row.last_run_date}
        </p>
      </TableCell>
      <TableCell>
        {/* {openModal && ( */}
        <div className="">
          <DropdownMenu>
            <DropdownMenuTrigger>
              <MoreHorizontal
                onClick={() => setOpenModal(!openModal)}
                className="h-5 w-5"
              />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuLabel>Service Actions</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem>Stop Process</DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  if (row.service_id === "1") {
                    getServicesDisableToken();
                  } else if (row.service_id === "2") {
                    getServicesLogoutChat();
                  } else if (row.service_id === "3") {
                    getServicesDeleteCloseJob();
                  } else if (row.service_id === "4") {
                    getServicesClosedHiredRequiredJobs();
                  } else if (row.service_id === "5") {
                    getServicesClosedExpireJobs();
                  }
                }}
              >
                Run Process
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default TableRowService;
