import { debounce } from "src/Utils/debounce";
import { Input } from "../ui/Input";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { Button } from "../ui/Button";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { CreditsTypes } from "src/types/types";
import { ShowItem, ShowModal } from "src/Pages/ManagementOthers";
import { ACTIONS, BASE_URL } from "src/Config/api.config";
import { toast } from "../ui/use-toast";
import { Trash } from "lucide-react";
import { ReactComponent as Edit } from "src/assets/img/edit.svg";
import ModalAddManagement from "../Modals/ModalAddManagement";
import { decryptArray } from "src/Utils/decryptArray";
import { cn } from "src/lib/utils";
import ModalAddCredits from "../Modals/ModalAddCredits";

interface CardManagementCreditsProps {
  handleCreditsChange: (args: string) => void;
  allCredits: CreditsTypes[];
  setShowItem: Dispatch<SetStateAction<ShowItem>>;
  setShowModal: Dispatch<SetStateAction<ShowModal>>;
  showItem: ShowItem;
  getCredits: (name: string) => void;
  setSelectedCredit: Dispatch<SetStateAction<CreditsTypes>>;
  setCreditsType: Dispatch<SetStateAction<any>>;
  creditsType: any;
}

const CardManagementCredits = ({
  handleCreditsChange,
  allCredits,
  setShowItem,
  showItem,
  getCredits,
  setSelectedCredit,
  setShowModal,
  setCreditsType,
  creditsType,
}: CardManagementCreditsProps) => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const [showAddModal, setShowAddModal] = useState(false);

  const optimisedSearch = useCallback(debounce(handleCreditsChange), []);

  const addCreditsData = new FormData();
  addCreditsData.append("_method", "POST");
  const addCredits = async (
    name: string,
    creditsForCompany?: string,
    creditsForEmployer?: string,
    creditsForWorker?: string
  ) => {
    addCreditsData.append("name", name);
    addCreditsData.append("credits_company", creditsForCompany!);
    addCreditsData.append("credits_employer", creditsForEmployer!);
    addCreditsData.append("credits_worker", creditsForWorker!);

    const response = await fetch(`${BASE_URL}${ACTIONS}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
      body: addCreditsData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          toast({
            title: "Credits Type added",
            description: `Company:${creditsForCompany} Credits, Employer:${creditsForEmployer} Credits, Worker:${creditsForWorker} Credits / Type ${name}`,
            variant: "default",
          });
          setShowAddModal(false);
          getCredits("");
        } else {
          toast({
            title: data.result.error_msg,
            variant: "destructive",
          });
        }
      })
      .catch((err) => console.log(err));

    return response;
  };

  const types = [
    {
      value: "Company",
    },
    {
      value: "Particular",
    },
    {
      value: "Worker",
    },
  ];

  const handleAction = (
    name: string,
    creditsForCompany?: string,
    creditsForEmployer?: string,
    creditsForWorker?: string
  ) => {
    addCredits(name, creditsForCompany, creditsForEmployer, creditsForWorker);
  };
  return (
    <div className="w-full">
      <div className="flex items-center w-full justify-between mb-4 cursor-pointer">
        <div className="flex items-center">
          <p className="font-bol text-lg">Credits</p>
          <Input
            className={`border border-border outline-none text-sm ml-6 h-full w-[205px] bg-white mr-4 px-4 pr-11`}
            placeholder="Search Credits"
            type="text"
            onChange={(e) => optimisedSearch(e.target.value)}
          />
        </div>
        <Button onClick={() => setShowAddModal(true)} type="button">
          + Add a Credit Type
        </Button>
      </div>
      <div className="flex items-center space-x-2 mb-2">
        {types.map((worker) => (
          <button
            key={worker.value}
            onClick={() => setCreditsType(worker.value)}
            className={cn(
              "bg-[#e2e2e2] px-4 py-2 rounded-md text-[#333] transition",
              worker.value.toLowerCase() === creditsType.toLowerCase() &&
                "text-white bg-[#333]"
            )}
          >
            <p className="text-sm ">{worker.value}</p>
          </button>
        ))}
      </div>
      <div className="bg-white border flex justify-between rounded dark:bg-background">
        <div className="w-full px-4 py-4 text-sm h-96 overflow-y-auto flex justify-between text-black">
          <div className="flex flex-col w-full overflow-auto jobpost">
            <div className="w-full flex items-center justify-between bg-gray-50 py-2 px-2 rounded-t">
              <div className="flex items-center justify-between w-[80%]">
                <p className="font-medium text-[15px]">Id</p>
                <p className="font-medium text-[15px]">Name</p>
                <p className="font-medium text-[15px]">Credits</p>
              </div>
            </div>
            {allCredits?.length > 0 &&
              allCredits

                ?.sort(function (a, b) {
                  if (Number(a.action_id) < Number(b.action_id)) {
                    return -1;
                  }
                  if (Number(a.action_id) > Number(b.action_id)) {
                    return 1;
                  }
                  return 0;
                })
                ?.map((credit, i) => {
                  if (
                    creditsType?.toLowerCase() === "company" &&
                    credit.credits_company === null
                  ) {
                    return null;
                  } else if (
                    creditsType?.toLowerCase() === "particular" &&
                    credit.credits_employer === null
                  ) {
                    return null;
                  } else if (
                    creditsType?.toLowerCase() === "worker" &&
                    credit.credits_worker === null
                  ) {
                    return null;
                  }
                  return (
                    <div
                      key={credit.action_id}
                      className="hover:bg-slate-100 w-full border-b cursor-pointer rounded py-2 px-2 flex justify-between items-center"
                      onMouseEnter={() => {
                        setShowItem((prev) => ({
                          ...prev,
                          trash: i,
                          edit: i,
                          where: "credit",
                        }));
                      }}
                      onMouseLeave={() => {
                        setShowItem((prev) => ({
                          ...prev,
                          trash: -1,
                          edit: -1,
                          where: "",
                        }));
                      }}
                    >
                      <div className="flex items-start justify-between w-[80%]">
                        <p>{credit.action_id}</p>
                        <p className="">{credit.name}</p>

                        <p className="text-left">
                          {creditsType.toLowerCase() === "worker" &&
                            credit.credits_worker}
                          {creditsType.toLowerCase() === "company" &&
                            credit.credits_company}
                          {creditsType.toLowerCase() === "particular" &&
                            credit.credits_employer}
                        </p>
                      </div>
                      <div className="flex items-center gap-3">
                        {showItem.edit === i && showItem.where === "credit" && (
                          <div
                            onClick={(prev) => {
                              setSelectedCredit(credit);
                              setShowModal({
                                ...prev,
                                edit: true,
                                section: "credit",
                              });
                            }}
                          >
                            <Edit className="h-[10px] fill-slate-700" />
                          </div>
                        )}
                        {showItem.trash === i &&
                          showItem.where === "credit" && (
                            <div
                              onClick={(prev) => {
                                setSelectedCredit(credit);
                                setShowModal({
                                  ...prev,
                                  delete: true,
                                  section: "credit",
                                  edit: false,
                                });
                              }}
                              className="h-[20px] w-[22px] hover:bg-slate-200 flex items-center justify-center rounded"
                            >
                              <Trash className="h-[14px] fill-slate-700 " />
                            </div>
                          )}
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
      {showAddModal && (
        <ModalAddCredits
          handleAction={handleAction}
          setShowAddModal={setShowAddModal}
        />
        // <ModalAddManagement
        //   handleAction={addCredits}
        //   section="credit"
        //   setShowModal={setShowAddModal}
        //   type="add"
        // />
      )}
    </div>
  );
};

export default CardManagementCredits;
