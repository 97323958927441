import { Box } from "@mui/material";
import React from "react";
import LineChart from "../Charts/LineChart";

type Props = {};

const Line = (props: Props) => {
  return (
    <Box m="20px">
      <Box height="40vh">
        <LineChart />
      </Box>
    </Box>
  );
};

export default Line;
