import React, { useEffect, useState } from "react";
import { ClientsData, PieData } from "src/types/types";

const INITIAL_STATE = {
  datasets: [],
  labels: [""],
};

interface JobsStatsProps {
  statsData: ClientsData;
}

export const useJobsStatsData = ({ statsData }: JobsStatsProps) => {
  const [jobsStatusData, setJobStatusData] = useState<PieData>(INITIAL_STATE);
  const [jobsTypesData, setJobTypesData] = useState<PieData>(INITIAL_STATE);
  const [jobsTimesData, setJobTimesData] = useState<PieData>(INITIAL_STATE);
  const [jobsTop10, setJobsTop10] = useState<PieData>(INITIAL_STATE);

  useEffect(() => {
    setJobStatusData({
      labels: statsData?.jobStatus?.map((jobs) => jobs.status_name),
      datasets: [
        {
          label: "# of Jobs",
          data: statsData?.jobStatus?.map((jobs) => jobs.cant),
          backgroundColor: ["#30638A", "#D0AD6E", "#63B6C0"],
          borderColor: ["#234763", "#a68956", "#48838a"],
          borderWidth: 2,
        },
      ],
    });
    setJobTypesData({
      labels: statsData?.jobsTypes?.map((jobs) => jobs.type_name),
      datasets: [
        {
          label: "# of Jobs",
          data: statsData?.jobsTypes?.map((jobs) => jobs.cant),
          backgroundColor: ["#30638A", "#D0AD6E", "#63B6C0"],
          borderColor: ["#234763", "#a68956", "#48838a"],
          borderWidth: 2,
        },
      ],
    });
    setJobTimesData({
      labels: ["Expired", "On Date"],
      datasets: [
        {
          label: "# of Jobs",
          data: [
            statsData?.jobsTimeState?.expired,
            statsData?.jobsTimeState?.ondate,
          ],
          backgroundColor: ["#30638A", "#D0AD6E"],
          borderColor: ["#234763", "#a68956"],
          borderWidth: 2,
        },
      ],
    });
    setJobsTop10({
      labels: statsData?.top10jobs?.map((jobs) => jobs.name),
      datasets: [
        {
          label: "# of Jobs",
          data: statsData?.top10jobs?.map((jobs) => jobs.cant),
          backgroundColor: [
            "#7B3D45",
            "#30638A",
            "#85C1B8",
            "#63B6C0",
            "#99E5B3",
            "#D9F2EE",
            "#B8E6DB",
            "#3B9699",
            "#D0AD6E",
            "#41705E",
          ],
          borderColor: [
            "#5c2e34",
            "#234763",
            "#68968f",
            "#4d8f96",
            "#7dba92",
            "#b8ccc9",
            "#95bab1",
            "#2b6e70",
            "#a68956",
            "#264238",
          ],
          borderWidth: 2,
        },
      ],
    });
  }, [statsData]);
  return { jobsStatusData, jobsTypesData, jobsTimesData, jobsTop10 };
};
