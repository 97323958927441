import React from "react";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { formatTimeToNow } from "src/lib/utils";
import Line from "../AllJobs/Line";

type Props = {};

const CompanyDetails = (props: Props) => {
  const [employerInfo, setEmployerInfo] = useLocalStorage("employerInfo", "");

  return (
    <div className="h-full flex flex-col col-span-2 space-y-3">
      <div className="w-full bg-white p-4 rounded-sm">
        <div className="flex items-center justify-between">
          <p>
            Posted by {employerInfo.first_name} {employerInfo.last_name}
          </p>
          <p className="text-xs text-zinc-500">{formatTimeToNow(new Date())}</p>
        </div>
        <h1 className="text-xl font-semibold py-2 leading-6 text-gray-900">
          {employerInfo.title}
        </h1>
      </div>
      <div className="bg-white p-4 rounded-sm flex items-center justify-between">
        <div>
          <h1 className="text-sm text-gray-500">Subscription Plan</h1>
          <p>{employerInfo.plan}</p>
        </div>
        <div>
          <h1 className="text-sm text-gray-500">Credits</h1>
          <p>{employerInfo.credits}</p>
        </div>
        <div>
          <h1 className="text-sm text-gray-500">Profile Status</h1>
          <p>Company</p>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-10">
        <div className="bg-white rounded-sm p-4">
          <h1 className="text-sm text-gray-500">Applicants</h1>
          {employerInfo.applicants ?? "0"}
        </div>
        <div className="bg-white rounded-sm p-4">
          <h1 className="text-sm text-gray-500">Messaged</h1>
          {employerInfo.messaged ?? "0"}
        </div>
        <div className="bg-white rounded-sm p-4">
          <h1 className="text-sm text-gray-500">Connections</h1>
          {employerInfo.connections ?? "0"}
        </div>
      </div>
      <div className="bg-white rounded-sm">
        <Line />
      </div>
      <div className="bg-white p-4 rounded-sm">
        <h1 className="text-lg font-semibold">Transaction History</h1>
        <h2>Total Credits: {employerInfo.credits}</h2>
        <div className="flex flex-col mt-4">
          <div className="flex items-center justify-between px-3">
            <h3 className="font-semibold">ID</h3>
            <h3 className="font-semibold">Credits</h3>
            <h3 className="font-semibold">Date Created</h3>
          </div>
          <div className="flex items-center justify-between bg-gray-100 px-3 py-2 mb-1 rounded-sm">
            <div>
              <h4 className="text-sm text-gray-500">{employerInfo.user_id}</h4>
            </div>
            <div>
              <h4 className="text-sm text-gray-500">{employerInfo.credits}</h4>
            </div>
            <div>
              <h4 className="text-sm text-gray-500">
                {employerInfo.date_created}
              </h4>
            </div>
          </div>
          <div className="flex items-center justify-between bg-gray-100 px-3 py-2 mb-1 rounded-sm">
            <div>
              <h4 className="text-sm text-gray-500">{employerInfo.user_id}</h4>
            </div>
            <div>
              <h4 className="text-sm text-gray-500">{employerInfo.credits}</h4>
            </div>
            <div>
              <h4 className="text-sm text-gray-500">
                {employerInfo.date_created}
              </h4>
            </div>
          </div>
          <div className="flex items-center justify-between bg-gray-100 px-3 py-2 mb-1 rounded-sm">
            <div>
              <h4 className="text-sm text-gray-500">{employerInfo.user_id}</h4>
            </div>
            <div>
              <h4 className="text-sm text-gray-500">{employerInfo.credits}</h4>
            </div>
            <div>
              <h4 className="text-sm text-gray-500">
                {employerInfo.date_created}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
