import { atom } from "jotai"
import { AllCategories, AllCities, AllPostcodes, CompaniesData } from "src/types/types"

const INITIAL_POTSCODE_STATE: AllPostcodes = {
    id: "",
    locality: "",
    postcode: "",
    state: ""
}

export const INITIAL_COMPANY_STATE: CompaniesData = {
    id: "",
    name: "",
    active: "",
    cif: "",
    prefix_phone: "",
    phone: "",
    validated: "",
    post_code_id: "",
    company_logo: "",
    country_code: "",
    date_created: "",
    description: "",
    // email: ""
}

const INITIAL_CATEGORY_STATE = {
    category_id: "",
    label: "",
    name: "",
    value: "",
};

export const loadingJobs = atom(false)
export const selectedUserId = atom("")
export const jobTitle = atom("")
export const editFunctionLoading = atom(false)
export const jobTypeFilter = atom<any>("")
export const jobStatusFilter = atom<any>("")
export const categoriesFilter = atom<AllCategories[]>([])
export const citiesFilter = atom<AllCities[]>([])
export const postcodesFilter = atom<AllPostcodes[]>([])
export const applicantsAmountFilter = atom<string>("")
export const appliedJobsAmountFilter = atom<string>("")
export const connectionsAmountFilter = atom<string>("")
export const postcodeFilter = atom<AllPostcodes>(INITIAL_POTSCODE_STATE)
export const companyFilter = atom<CompaniesData>(INITIAL_COMPANY_STATE)


export const userTypeFilter = atom<Array<string>>([""])

export const employerTypeFilter = atom<Array<string>>([""])
export const minMaxCreditsFilter = atom<{ min: string; max: string }>({ min: "0", max: "" })

export const companyActiveFilter = atom<string>("")
export const serviceName = atom<string>("")
export const serviceStatus = atom<string>("")
export const categorySelection = atom<AllCategories>(INITIAL_CATEGORY_STATE)
export const inputCategory = atom<string>("")
export const creditsAtom = atom<string>("company")