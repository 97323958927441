import React, { useEffect, useRef, useState } from "react";
import { Button, buttonVariants } from "../ui/Button";
import { X } from "lucide-react";
import { useModalStore } from "src/Hooks/useModalStore";
import { CompaniesData } from "src/types/types";
import { Input } from "../ui/Input";
import { Form, FormControl, FormField, FormItem, FormLabel } from "../ui/Form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { toast } from "../ui/use-toast";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

interface ModalSingleCompanyProps {
  type: string;
  section: string;
  children?: React.ReactNode;
  onClick: (args1?: any, args2?: any) => void;
  selectedCompany: CompaniesData;
  title: string;
  description: string;
}

const formSchema = z.object({
  cif_number: z.string().min(1),
  phone_number: z.string().min(1),
  company_name: z.string().min(1),
  company_description: z.string().min(1),
});

const ModalSingleCompany = ({
  children,
  type,
  section,
  onClick,
  selectedCompany,
  description,

  title,
}: ModalSingleCompanyProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [
    showModalEditCompany,
    setShowModalEditCompany,
    showModalEditValidation,
    setShowModalEditValidation,
    showModalEditActiveType,
    setShowModalEditActiveType,
  ] = useModalStore((state) => [
    state.showModalEditCompany,
    state.setShowModalEditCompany,
    state.showModalEditValidation,
    state.setShowModalEditValidation,
    state.showModalEditActiveType,
    state.setShowModalEditActiveType,
  ]);

  const [validationType, setValidationType] = useState("");
  const [activeType, setActiveType] = useState("");

  useEffect(() => {
    setValidationType(selectedCompany.validated);
    setActiveType(selectedCompany.active);
  }, [selectedCompany]);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      cif_number: selectedCompany.cif,
      phone_number: selectedCompany.phone,
      company_name: selectedCompany.name,
      company_description: selectedCompany.description,
    },
  });

  const updateCompany = (values: any) => {
    console.log(values, "values");
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      setLoading(true);
      updateCompany(values);
    } catch (error) {
      toast({
        title: "Something went wrong",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    if (type === "edit") {
      setShowModalEditCompany(showModalEditCompany);
    } else if (type === "validation") {
      setShowModalEditValidation(showModalEditValidation);
    } else if (type === "active") {
      setShowModalEditActiveType(showModalEditActiveType);
    }
  };

  return (
    <div
      ref={modalRef}
      className="fixed top-0 right-0 w-full h-full bg-black/50  text-black z-50"
    >
      <div className="fixed  z-[50] w-[28%] left-[50%] transition shadow-xl translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white">
        <div className="flex items-center justify-between border-b px-8 py-6">
          <h1 className="text-xl font-bold">
            {section === "company" && <h1>{type === "edit" && title}</h1>}
            {section === "company" && <h1>{type === "validation" && title}</h1>}
            {section === "company" && <h1>{type === "active" && title}</h1>}
          </h1>
          <Button
            onClick={() => handleClose()}
            variant={"subtle"}
            className="h-6 w-6 rounded-md p-0"
          >
            <X aria-label="close-modal" className="h-4 w-4" />
          </Button>
        </div>
        <h3 className="px-8 py-4">{description}</h3>
        <div className="mx-8 my-6">{children}</div>
        {section === "company" && type === "edit" && (
          <div className="flex flex-col px-8 gap-5">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <FormField
                  control={form.control}
                  name="cif_number"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Cif Number</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          disabled={loading}
                          placeholder="Cif Number"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="phone_number"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Phone Number Number</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          disabled={loading}
                          placeholder="Cif Number"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="company_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Name</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          disabled={loading}
                          placeholder="Company Name"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="company_description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Description</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          disabled={loading}
                          placeholder="Cif Number"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <div className="mx-8 my-6">
                  {section === "company" && (
                    <Button
                      type="submit"
                      className={buttonVariants({
                        className: "w-full mt-4 mb-6 ",
                      })}
                    >
                      Edit Company
                    </Button>
                  )}
                </div>
              </form>
            </Form>
          </div>
        )}

        {section === "company" && type === "validation" && (
          <div className="flex flex-col px-8 gap-5">
            <Select onValueChange={(e: any) => setValidationType(e)}>
              <SelectTrigger>
                <SelectValue
                  placeholder={
                    validationType === "0" ? "Not Validated" : "Validated"
                  }
                />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={"0"}>Not Validated</SelectItem>
                <SelectItem value={"1"}>Validated</SelectItem>
              </SelectContent>
            </Select>
            <div className=" my-6">
              {section === "company" && (
                <div className="flex items-center justify-end w-full gap-4">
                  <Button
                    onClick={() =>
                      setShowModalEditValidation(showModalEditValidation)
                    }
                    variant={"outline"}
                  >
                    Cancel
                  </Button>
                  <Button type="submit">Save</Button>
                </div>
              )}
            </div>
          </div>
        )}

        {section === "company" && type === "active" && (
          <div className="flex flex-col px-8 gap-5">
            <Select onValueChange={(e: any) => setActiveType(e)}>
              <SelectTrigger>
                <SelectValue
                  placeholder={activeType === "0" ? "Not Active" : "Active"}
                />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="0">Not Active</SelectItem>
                <SelectItem value="1">Active</SelectItem>
              </SelectContent>
            </Select>
            <div className=" my-6">
              {section === "company" && (
                <div className="flex items-center justify-end w-full gap-4">
                  <Button
                    onClick={() =>
                      setShowModalEditActiveType(showModalEditActiveType)
                    }
                    variant={"outline"}
                  >
                    Cancel
                  </Button>
                  <Button type="submit">Save</Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalSingleCompany;
