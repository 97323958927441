// import axios from "axios";
import { useState } from "react";
import { Copy, Edit, MoreHorizontal, Trash } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "src/Components/ui/dropdown-menu";
import { CompaniesData } from "src/types/types";
import { Button } from "../ui/Button";
import { AlertModal } from "../Modals/AlertModal";
import { toast } from "../ui/use-toast";
import axios from "axios";
import { BASE_URL, COMPANIES } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { useModalStore } from "src/Hooks/useModalStore";
import { useNavigate } from "react-router-dom";

interface CellActionProps {
  data: CompaniesData;
}

export const CellAction: React.FC<CellActionProps> = ({ data }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const [editModal, setEditModal] = useState(false);
  const [showModalRemoveCompany, setShowModalRemoveCompany] = useModalStore(
    (state) => [state.showModalRemoveCompany, state.setShowModalRemoveCompany]
  );
  const [companyInfo, setCompanyInfo] = useLocalStorage("companyInfo", "");

  const onConfirm = async () => {
    try {
      setLoading(true);
      await axios.delete(`${BASE_URL}${COMPANIES}?company_id=${data.id}`, {
        headers: {
          Authorization: "Bearer " + adminData.uid,
        },
      });
      //   await axios.delete(`/api/${params.storeId}/billboards/${data.id}`);
      toast({
        title: "Company deleted.",
      });
    } catch (error) {
      toast({
        title: "Something went wrong",
        description: `${error}`,
        variant: "destructive",
      });
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };

  const onCopy = (id: string) => {
    navigator.clipboard.writeText(id);
    toast({
      title: "Company Id copied to clipboard",
    });
  };

  return (
    <>
      {/* {showModalRemoveCompany && ( */}
      <AlertModal
        isOpen={showModalRemoveCompany}
        onClose={() => setShowModalRemoveCompany(showModalRemoveCompany)}
        onConfirm={onConfirm}
        loading={loading}
      />
      {/* )} */}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => onCopy(data.id)}>
            <Copy className="mr-2 h-4 w-4" /> Copy Id
          </DropdownMenuItem>
          {/* <DropdownMenuItem onClick={() => setEditModal(!editModal)}> */}
          <DropdownMenuItem
            onClick={() => {
              navigate(`//all-companies/${data.id}`);
              setCompanyInfo(data);
            }}
          >
            <Edit className="mr-2 h-4 w-4" /> Update
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => setShowModalRemoveCompany(showModalRemoveCompany)}
          >
            <Trash className="mr-2 h-4 w-4" /> Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};
