import { DashboardConfig } from "src/types/types";

export const dashboardConfig: DashboardConfig = {
    mainNav: [
        {
            title: "Overview",
            href: "",
            disabled: false,
            icon: "overview",

        },
        {

            title: "Analytics",
            href: "/analytics",
            disabled: false,
            icon: "barchart",
            spacing: true
        },
        {
            title: "Management",
            href: "",
            // href: "/management",
            disabled: false,
            icon: "book",
            options: [
                {
                    title: "General",
                    href: "/management/general",

                },
                {
                    title: "Others",
                    href: "/management/others"
                }
            ]
        },
        // {
        //     title: "Jobs",
        //     href: "/all-jobs",
        //     disabled: false,
        //     icon: "jobs"
        // },
        {
            title: "Jobs",
            href: "/all-jobs",
            disabled: false,
            icon: "jobs"
        },
        {
            title: "Workers",
            href: "/all-users",
            disabled: false,
            icon: "user"
        },
        {
            title: "Employers",
            href: "/all-employers",
            disabled: false,
            icon: "work"
        },
        {
            title: "Waitlist",
            href: "/pre-registers",
            disabled: false,
            icon: "waitlist"
        },
        {
            title: "Companies",
            href: "/all-companies",
            disabled: false,
            icon: "company"
        },
        {
            title: "Services",
            href: "/services",
            disabled: false,
            icon: "robot"
        },
        {
            title: "Credits",
            href: "",
            disabled: false,
            icon: "credits",
            spacing: true,
            options: [
                {
                    title: "Subscriptions",
                    href: "/subscriptions",

                },
                {
                    title: "Credits",
                    href: "/credits"
                }
            ]
        },
        {
            title: "Reports",
            href: "reports",
            options: [
                {
                    title: "Users",
                    href: "/reports/users"
                },
                {
                    title: "Jobs",
                    href: "/reports/jobs"
                }
            ],
            disabled: false,
            icon: "report",
            spacing: true
        },
        {
            title: "Profile",
            href: "profile",
            disabled: true,
            icon: "user"
        },
        {
            title: "Settings",
            href: "/settings",
            disabled: false,
            icon: "settings"
        },
        {
            title: "Geography",
            href: "/geography",
            disabled: false,
            icon: "map"

        },

    ]
} 