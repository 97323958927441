import React from "react";
import Management from "src/Components/Management/Management";
import VerticalLayout from "src/layout/VerticalLayout";

type Props = {};

const DashboardManagement = (props: Props) => {
  return (
    <VerticalLayout>
      <Management />
    </VerticalLayout>
  );
};

export default DashboardManagement;
