let hostDNS = "3f62-88-17-128-148.ngrok.io";
let hostIP = "192.168.1.46";
// http://3f62-88-17-128-148.ngrok.io
// let HostProd = "";
let protocol = "http://";
// let ApiUrl = "/labtest/elite-api-flexihours/v1/";
// let apiEss18 = "/elitesports17-ess18/";

let HOST = "http://192.168.1.80/labtest/flexi/api/";
// let HOST = "http://192.168.1.46/labtest/elite-api-flexihours/v1/";

let DEVELOPMENT = 2;

if (DEVELOPMENT === 1) {
  HOST = "https://api.flexihours.es/";
  protocol = "https://";
  hostIP = "img.flexihours.es";
} else if (DEVELOPMENT === 2) {
  HOST = "https://apitest.flexihours.es/";
  protocol = "https://";
  hostIP = "img.flexihours.es";
}
// 673c-88-17-128-153.ngrok.io
// export let BASE_URL = protocol + hostDNS + ApiUrl;
export let BASE_URL = HOST;
export let BASE_URL_IMG = protocol + hostIP + "/";

// export let BASE_URL_IMG = "https://img.elitesoccer18.com/";
// export const TEAM_DETAILS_PLAYER_DATA_END_POINT = `${BASE_URL}players`;
// export const uid = "0c5444c294af4b15ee08ab176fdc70e0";

export const INAPPROPIATE_JOB_REASONS = "adm.inapropiate.job.reasons";

export const INAPPROPIATE_JOB = "adm.inapropiate.job";

export const JOBS = "adm.jobs";

export const CITIES = "adm.cities";

export const JOB_SKILLS = "adm.job.skills";

export const POSTCODES = "adm.postcodes";

export const INAPROPIATE_WORKER_PROFILE_REASONS =
  "adm.inapropiate.worker.profile.reasons";

export const DECLINE_REASONS = "adm.decline.reasons";

export const QUIT_WORK_REASONS = "adm.quit.work.reasons";

export const END_WORK_REASONS = "adm.end.work.reasons";

export const DECLINE_OFFER_REASONS = "adm.decline.offer.reasons";

export const COMPANIES = "adm.companies";

export const ACTIONS = "adm.actions";

export const GENERAL_PARAMS = "adm.general.params";

export const CLIENTS_WALLET = "clients.wallet";

export const LOGIN = "login";

export const JOB_CATEGORIES = "adm.job.categories";

export const LOGOUT = "logout";

export const FLAGED_JOB = "adm.flaged.job";

export const PRECLIENT_REGISTER = "adm.preclient.register";
