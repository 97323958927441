import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import React, { useState } from "react";

import ReportJobs from "src/Components/Reports/ReportJobs";
import SearchBar from "src/Components/SearchBar";
import { Heading } from "src/Components/ui/heading";
import { Separator } from "src/Components/ui/separator";
import { BASE_URL, INAPPROPIATE_JOB } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { jobTitle } from "src/atoms/atoms";
import VerticalLayout from "src/layout/VerticalLayout";
import { AllInappropiateReasons } from "src/types/types";

type Props = {};

const ReportPage = (props: Props) => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");

  const [sortDirection, setSortDirection] = useState("ASC");
  const [sortType, setSortType] = useState("date_created");
  const [job_title, setJobTitle] = useAtom(jobTitle);
  const [inappropiateJobReasonId, setInappropiateJobReasonId] =
    useState<AllInappropiateReasons>({ id: "", name: "" });

  const getInappropiatedJobs = async () => {
    const data = await fetch(
      `${BASE_URL}${INAPPROPIATE_JOB}?order=${sortType}&order_sense=${sortDirection}&job_title=${job_title}
      `,
      {
        headers: {
          Authorization: "Bearer " + adminData.uid,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: inappropiateJobsData, isFetching } = useQuery(
    ["inapropiateJobs", sortDirection, job_title],
    getInappropiatedJobs
  );

  return (
    <VerticalLayout>
      <div className="flex-1 space-y-4 p-8 pt-6">
        <Heading
          title={`Reported Jobs (${inappropiateJobsData?.length})`}
          description="Manage the reported jobs"
        />
        <Separator />

        <div className="flex w-full">
          <SearchBar />
        </div>
        <ReportJobs
          inappropiateJobReasonId={inappropiateJobReasonId}
          setInappropiateJobReasonId={setInappropiateJobReasonId}
          inappropiateJobs={inappropiateJobsData!}
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
          isLoading={isFetching}
        />
      </div>
    </VerticalLayout>
  );
};

export default ReportPage;
