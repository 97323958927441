import React from "react";
import { Skeleton } from "../ui/Skeleton";

const CardCommentSkeleton = () => {
  return (
    <div className="flex flex-col py-2">
      <div className="flex flex-col gap-4 mx-8 mt-4 mb-2">
        <div className="flex items-center">
          <Skeleton className="h-5 w-1/2 " />
        </div>
        <div>
          <Skeleton className="h-4 w-1/3" />
        </div>
      </div>
    </div>
  );
};

export default CardCommentSkeleton;
