import React, { SetStateAction, useState } from "react";
import { BASE_URL, LOGIN } from "../../Config/api.config";
import RequestErrorModal from "../RequestErrorPopup";
import Account from "../../assets/img/account.svg";
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";
import useLocalStorage from "src/Hooks/useLocalStorage";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/Form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "../ui/use-toast";

interface UsernameProps {
  state: {
    username: string;
    password: string;
  };
  dispatch: React.Dispatch<{ key: string; value: string; type: string }>;
  setShowComponent: React.Dispatch<
    SetStateAction<{ user: boolean; password: boolean }>
  >;
}

const formSchema = z.object({
  username: z.string().min(1),
});

const Username = ({ state, dispatch, setShowComponent }: UsernameProps) => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
    },
  });

  const [warning, setWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let data = new FormData();
  data.append("mode", "username");
  // data.append("username", state.username);

  const checkEmail = (username: string) => {
    data.append("username", username);
    // setIsLoading(true);
    fetch(`${BASE_URL}${LOGIN}`, {
      method: "POST",
      headers: {},
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setAdminData((prev: { id: string; uid: string }) => ({
            ...prev,
            id: data.result.id,
            uid: data.result.token,
          }));
          setShowComponent((prev) => ({
            ...prev,
            user: false,
            password: true,
          }));
        } else {
          toast({
            title: "Something went wrong",
            description: `${data.result.error_msg}`,
            variant: "destructive",
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      setIsLoading(true);
      checkEmail(values.username);
    } catch (error) {
      toast({
        title: "Something went wrong",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="">
      <div className="absolute top-0 left-[50%]">
        <RequestErrorModal warning={warning} />
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="relative w-full mt-3">
            <img
              src={Account}
              alt="account"
              className="absolute top-[45px] left-4"
            />
            <FormField
              control={form.control}
              name="username"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Username</FormLabel>
                  <FormControl>
                    <Input
                      autoFocus
                      disabled={isLoading}
                      {...field}
                      className="w-full outline-none border mt-6 rounded h-10 px-12 text-sm"
                    />
                  </FormControl>
                  {/* <FormMessage /> */}
                </FormItem>
              )}
            />
            {/* <Input
              autoFocus
              type="text"
              value={state.username}
              onChange={(e) =>
                dispatch({
                  type: "UPDATE",
                  value: e.target.value,
                  key: "username",
                })
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  checkEmail();
                }
              }}
              className="w-full outline-none border mt-6 rounded h-10 px-12 text-sm"
              placeholder="Username"
            /> */}
            <div className="pt-6 space-x-2 flex items-center justify-end w-full">
              {/* <Button
                disabled={isLoading}
                variant="outline"
                // onClick={storeModal.onClose}
              >
                Cancel
              </Button> */}
              <Button disabled={isLoading} type="submit">
                Continue
              </Button>
            </div>
          </div>
          {/* <div className="mt-8">
            <Button
              disabled={state.username.length === 0}
              isLoading={isLoading}
              type="button"
              onClick={() => checkEmail()}
              className="px-16 py-1.5 text-white bg-[#2abeeb] hover:bg-[#1d90b3] rounded-full transition duration-300"
            >
              Continue
            </Button>
          </div> */}
        </form>
      </Form>
    </div>
  );
};

export default Username;
