import React from "react";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { formatTimeToNow } from "src/lib/utils";
import Line from "../AllJobs/Line";

const UserDetails = () => {
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");

  const skills = [
    "example",
    "example",
    "example",
    "example",
    "example",
    "example",
    "example",
    "example",
    "example",
    "example",
    "example",
    "example",
    "example",
    "example",
  ];
  return (
    <div className="h-full flex flex-col col-span-2 space-y-3">
      <div className="w-full bg-white p-4 rounded-sm">
        <div className="flex items-center justify-between">
          <p className="max-h-40 truncate text-xs text-gray-500">
            Posted by {userInfo.first_name} {userInfo.last_name}
          </p>
          <p className="text-xs text-zinc-500">{formatTimeToNow(new Date())}</p>
        </div>
        <h1 className="text-xl font-semibold py-2 leading-6 text-gray-900">
          {userInfo.title}
        </h1>
        <h3>{userInfo.overview}</h3>
      </div>
      <div className="bg-white p-4 rounded-sm flex items-center justify-between">
        <div>
          <h1 className="text-sm text-gray-500">Category</h1>
          <p>Waiter</p>
        </div>
        <div>
          <h1 className="text-sm text-gray-500">Job Type</h1>
          <p>Presential</p>
        </div>
        <div>
          <h1 className="text-sm text-gray-500">Profile Status</h1>
          <p>Available</p>
        </div>
      </div>
      <div className="bg-white p-4">
        <h1 className="mb-4 text-sm text-gray-500">Skills</h1>
        <div className="flex flex-wrap items-center gap-4">
          {[...skills].map((skill) => (
            <div
              key={skill}
              className="px-2 py-1 bg-gray-100 rounded-sm text-sm"
            >
              {skill}
            </div>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-3 gap-10">
        <div className="bg-white rounded-sm p-4">
          <h1 className="text-sm text-gray-500">Applied Jobs</h1>
          {userInfo.applied_jobs ?? "0"}
        </div>
        <div className="bg-white rounded-sm p-4">
          <h1 className="text-sm text-gray-500">Messaged</h1>
          {userInfo.messaged ?? "0"}
        </div>
        <div className="bg-white rounded-sm p-4">
          <h1 className="text-sm text-gray-500">Connections</h1>
          {userInfo.connections ?? "0"}
        </div>
      </div>
      <div className="bg-white rounded-sm">
        <Line />
      </div>
    </div>
  );
};

export default UserDetails;
