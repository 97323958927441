import React, { Dispatch, SetStateAction, useState } from "react";
import { ReactComponent as Search } from "../../assets/img/search.svg";
import { ReactComponent as Check } from "../../assets/img/check.svg";
import { ReactComponent as Cross } from "../../assets/img/cross.svg";
import ShowCreditsModal from "../ShowCreditsModal";
import { ClientDataTypes, SortOption, UserTypes } from "src/types/types";
import { Input } from "../ui/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { HandleMonthName } from "src/Utils/HandleMonthName";

interface AllEmployersDataProps {
  clientData: any;
  setClientData: Dispatch<SetStateAction<ClientDataTypes>>;
}

const AllEmployersData = ({
  clientData,
  setClientData,
}: AllEmployersDataProps) => {
  const [showModal, setShowModal] = useState({ credits: false });
  const [clickedUser, setClickedUser] = useState<UserTypes>();
  const filterBySearch = (e: string) => {
    const query = e;
    var updatedList = [...clientData.filteredEmployers];
    updatedList = updatedList.filter((item) => {
      return (
        (
          item.first_name.toLowerCase() +
          " " +
          item.last_name.toLowerCase()
        ).indexOf(query.toLowerCase()) !== -1
      );
    });
    setClientData((prev) => ({ ...prev, employers: updatedList }));
  };

  const sortOptions: SortOption[] = [
    {
      label: "Posted Jobs",
      value: "0",
    },
    {
      label: "Hired Applicants",
      value: "1",
    },
    {
      label: "Reported Msg",
      value: "2",
    },
    {
      label: "Validated",
      value: "3",
    },
    {
      label: "Joined on",
      value: "4",
    },
    {
      label: "Credits",
      value: "5",
    },
  ];

  return (
    <div className="px-6 pb-4">
      <div className="relative">
        <p className="absolute text-xs font-bold bg-white text-[#1d90b3] -top-2 left-3 w-fit px-1">
          Employer
        </p>
        <Search className="h-4 absolute left-[13.5rem] top-[13px] fill-[#1d90b3]" />
        <div className="flex items-center w-1/3 gap-10">
          <Input
            type="text"
            onChange={(e) => filterBySearch(e.target.value)}
            placeholder="Search by name"
            // className="bg-white outline-none border border-[#1d90b3] text-sm px-10 py-3 rounded w-[250px]"
          />
          <Select>
            <SelectTrigger>
              <SelectValue placeholder="Sort by" />
            </SelectTrigger>
            <SelectContent>
              {sortOptions.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="flex w-full mt-8 text-sm font-bold px-3">
        <p className="flex-[0_0_40%]">Employer</p>
        <p className="flex-[0_0_10%]">Posted Jobs</p>
        <p className="flex-[0_0_10%]">HiredApplicants</p>
        <p className="flex-[0_0_10%]">Reported Msg</p>
        <p className="flex-[0_0_10%]">Validated</p>
        <p className="flex-[0_0_10%]">Joined on</p>
        <p className="flex-[0_0_10%]">Credits</p>
      </div>
      <div className="overflow-auto max-h-[500px] jobpost">
        {clientData?.employers?.error_id !== "401" &&
          clientData?.employers?.map((employer: any) => {
            return (
              <div key={employer.id} className="bg-[#e8e8e8]  rounded-lg">
                <div className="flex w-full items-center text-sm px-3 py-2 mt-2">
                  <div className="flex whitespace-nowrap items-center  gap-3 flex-[0_0_40%]">
                    <div className="rounded-full w-7 h-7 flex justify-center items-center border border-black">
                      <p>{employer?.employer_image.toUpperCase()}</p>
                    </div>
                    <p className="font-bold">
                      {employer?.first_name} {employer?.last_name}
                    </p>
                  </div>
                  <p className="flex-[0_0_10%]">3</p>
                  <p className="flex-[0_0_10%]">12</p>
                  <p className="flex-[0_0_10%]">0</p>
                  <div className="flex-[0_0_10%] ">
                    {employer?.validated === "0" ? (
                      <Cross className="fill-red-700 h-4 w-fit" />
                    ) : (
                      <Check className="fill-green-600 h-4 w-fit" />
                    )}
                  </div>{" "}
                  <p className="flex-[0_0_10%]">
                    {HandleMonthName(employer?.date_created.slice(5, 7)).slice(
                      0,
                      3
                    )}{" "}
                    {employer?.date_created.slice(8, 10)},{" "}
                    {employer?.date_created.slice(0, 4)}
                  </p>
                  <div className="flex-[0_0_10%]">
                    <p
                      className=" hover:underline cursor-pointer text-[#1d90b3] hover:font-bold w-fit"
                      onClick={() => {
                        setClickedUser(employer);
                        setShowModal((prev) => ({ ...prev, credits: true }));
                      }}
                    >
                      Show credits
                    </p>
                  </div>
                  {showModal.credits && (
                    <ShowCreditsModal
                      showModal={showModal}
                      setShowModal={setShowModal}
                      user={clickedUser}
                    />
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AllEmployersData;
