import { BASE_URL, JOB_CATEGORIES } from "src/Config/api.config";

export const getCategories = async (name: string, token: string) => {
  const data = await fetch(
    `${BASE_URL}${JOB_CATEGORIES}${name !== "" ? `?name=${name}` : ""}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => res.json());

  return data.result;
};
