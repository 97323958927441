import React, { useState } from "react";
import {
  ACTIONS,
  BASE_URL,
  CITIES,
  COMPANIES,
  DECLINE_OFFER_REASONS,
  DECLINE_REASONS,
  END_WORK_REASONS,
  GENERAL_PARAMS,
  INAPPROPIATE_JOB_REASONS,
  INAPROPIATE_WORKER_PROFILE_REASONS,
  POSTCODES,
  QUIT_WORK_REASONS,
} from "src/Config/api.config";
import useLocalStorage from "src/Hooks/useLocalStorage";
import {
  AllCities,
  AllDeclineOfferReasons,
  AllDeclineReasons,
  AllEndWorkReasons,
  AllInappropiateReasons,
  AllPostcodes,
  AllQuitReasons,
  CompaniesData,
  CreditsTypes,
  ParamsData,
} from "src/types/types";
import { decryptArray } from "src/Utils/decryptArray";

const useManagementData = () => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const [allInappropiateReasons, setAllInappropiateReasons] = useState<
    AllInappropiateReasons[]
  >([]);
  const [allCities, setAllCities] = useState<AllCities[]>([]);
  const [allPostcodes, setAllPostcodes] = useState<AllPostcodes[]>([]);
  const [allInappropiateWorkerReasons, setAllInappropiateWorkerReasons] =
    useState<AllInappropiateReasons[]>([]);
  const [allDeclineReasons, setAllDeclineReasons] = useState<
    AllDeclineReasons[]
  >([]);

  const [allQuitReasons, setAllQuitReasons] = useState<AllQuitReasons[]>([]);
  const [allEndWorkReasons, setAllEndWorkReasons] = useState<
    AllEndWorkReasons[]
  >([]);

  const [allDeclineOfferReasons, setAllDeclineOfferReasons] = useState<
    AllDeclineOfferReasons[]
  >([]);

  const [companies, setCompanies] = useState<CompaniesData[]>([]);

  const [credits, setCredits] = useState<any>([]);

  const [params, setParams] = useState<ParamsData[]>([]);

  const getAdmInappropiateReasons = (name: string) => {
    fetch(`${BASE_URL}${INAPPROPIATE_JOB_REASONS}?name=${name}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllInappropiateReasons(data.result))
      .catch((err) => console.log(err));
  };

  const getCities = (name: string) => {
    fetch(
      `${BASE_URL}${CITIES}?country_code=ES&name=${name}
    `,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${adminData.uid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setAllCities(data.result);
      })
      .catch((err) => console.log(err));
  };

  const getPostcodes = (locality: string) => {
    fetch(`${BASE_URL}${POSTCODES}?country_code=ES&name=${locality}`, {
      headers: {
        Authorization: `Bearer ${adminData.uid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllPostcodes(data.result))
      .catch((err) => console.log(err));
  };

  const getAdmInappropiateWorkerReasons = (name: string) => {
    fetch(`${BASE_URL}${INAPROPIATE_WORKER_PROFILE_REASONS}?name=${name}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllInappropiateWorkerReasons(data.result))
      .catch((err) => console.log(err));
  };

  const getAdmDeclineReasons = (name: string) => {
    fetch(`${BASE_URL}${DECLINE_REASONS}?name=${name}`, {
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllDeclineReasons(data.result))
      .catch((err) => console.log(err));
  };

  const getAdmQuitReasons = (name: string) => {
    fetch(`${BASE_URL}${QUIT_WORK_REASONS}?name=${name}`, {
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllQuitReasons(data.result))
      .catch((err) => console.log(err));
  };

  const getAdmEndWorkReasons = (name: string) => {
    fetch(`${BASE_URL}${END_WORK_REASONS}?name=${name}`, {
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllEndWorkReasons(data.result))
      .catch((err) => console.log(err));
  };

  const getAdmDeclineOfferReasons = (name: string) => {
    fetch(`${BASE_URL}${DECLINE_OFFER_REASONS}?name=${name}`, {
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllDeclineOfferReasons(data.result))
      .catch((err) => console.log(err));
  };

  const getAdmCompanies = (companyName: string) => {
    fetch(`${BASE_URL}${COMPANIES}`, {
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => setCompanies(data.result.companies))
      .catch((err) => console.log(err));
  };

  const getCredits = (creditName: string) => {
    fetch(`${BASE_URL}${ACTIONS}?name=${creditName}`, {
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => setCredits(data.result))
      .catch((err) => {
        console.log(err);
      });
  };

  const getParams = (paramName: string) => {
    fetch(`${BASE_URL}${GENERAL_PARAMS}?name=${paramName}`, {
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => setParams(data.result));
  };

  return {
    allInappropiateReasons,
    allCities,
    allPostcodes,
    allInappropiateWorkerReasons,
    allDeclineReasons,
    allDeclineOfferReasons,
    allQuitReasons,
    allEndWorkReasons,
    companies,
    credits,
    params,
    getPostcodes,
    getCities,
    getAdmInappropiateReasons,
    getAdmInappropiateWorkerReasons,
    getAdmDeclineOfferReasons,
    getAdmDeclineReasons,
    getAdmQuitReasons,
    getAdmEndWorkReasons,
    getAdmCompanies,
    getCredits,
    getParams,
  };
};

export default useManagementData;
