import React from "react";

import ReportUsers from "src/Components/Reports/ReportUsers";
import { Heading } from "src/Components/ui/heading";
import { Separator } from "src/Components/ui/separator";
import VerticalLayout from "src/layout/VerticalLayout";
import { InappropiateUserTypes } from "src/types/types";

type Props = {};

const users: InappropiateUserTypes[] = [
  {
    user_id: "123",
    first_name: "Ismael",
    last_name: "Diaz",
    flagList: [
      {
        countreason: "1",
        inapropiate_user_reason_id: "1",
        reason_name: "Usuario parece falseo",
      },
    ],
  },
  {
    user_id: "123",
    first_name: "Ismael",
    last_name: "Diaz",
    flagList: [
      {
        countreason: "1",
        inapropiate_user_reason_id: "1",
        reason_name: "Usuario parece falseo",
      },
    ],
  },
  {
    user_id: "123",
    first_name: "Ismael",
    last_name: "Diaz",
    flagList: [
      {
        countreason: "1",
        inapropiate_user_reason_id: "1",
        reason_name: "Usuario parece falseo",
      },
    ],
  },
];

const ReportUserPage = (props: Props) => {
  return (
    <VerticalLayout>
      <div className="flex-1 space-y-4 p-8 pt-6">
        <Heading
          title={`Reported Users (${3}) `}
          description="Manage the reported users"
        />
        <Separator />

        <ReportUsers inappropiateUsers={users} />
      </div>
    </VerticalLayout>
  );
};

export default ReportUserPage;
