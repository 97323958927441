/* eslint-disable react-hooks/exhaustive-deps */
import { ReactComponent as People } from "../../assets/img/management_img.svg";

const Management = () => {
  return (
    <div className="pb-12 pt-4 px-12 flex flex-col w-full overflow-x-hidden">
      <div className="flex items-center gap-8 justify-start">
        <People className="h-10 w-fit" />
        <p className="font-bold tracking-wider">MANAGEMENT</p>
      </div>
    </div>
  );
};

export default Management;
