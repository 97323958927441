import React, { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useOnClickOutside } from "src/Hooks/useOnClickOutside";
import VerticalLayout from "src/layout/VerticalLayout";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/Components/ui/select";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { Button } from "src/Components/ui/Button";
import TableAllUsers from "src/Components/AllUsers/TableAllUsers";
import { allUsersContent } from "src/mockData/users";
import FilterUsers from "src/Components/Filters/FilterUsers";
import { useAtom } from "jotai";
import { categoriesFilter, citiesFilter } from "src/atoms/atoms";
import { getCategories } from "src/getContent/get-categories";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { getCities } from "src/getContent/get-cities";
import { Heading } from "src/Components/ui/heading";
import { Separator } from "src/Components/ui/separator";
import { Card } from "src/Components/ui/card";
import { DatePickerWithRange } from "src/Components/ui/date-rangepicker";

type Props = {};

const AllUsers = (props: Props) => {
  const dateRef = useRef(null);
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const [searched, setSearched] = useState<string>("");
  const [showStartDate, setShowStartDate] = useState(false);
  const [startDateValue, setStartDateValue] = useState<Dayjs | null>(
    dayjs("2023-06-29")
  );
  const [showEndDate, setShowEndData] = useState(false);
  const [endDateValue, setEndDateValue] = useState<Dayjs | null>(
    dayjs("2023-06-29")
  );
  const [categories, setCategories] = useAtom(categoriesFilter);
  const [categoriesIds, setCategoryIds] = useState<string[]>([]);

  const [cities, setCities] = useAtom(citiesFilter);
  const [citiesId, setCitiesId] = useState<string[]>([]);

  useOnClickOutside(dateRef, () => {
    setShowStartDate(false);
    setShowEndData(false);
  });
  const handleChangeJobCategory = (categoryId: string) => {
    if (!categoriesIds.includes(categoryId)) {
      setCategoryIds((prev) => [...prev, categoryId]);
    } else {
      setCategoryIds(categoriesIds.filter((id) => id !== categoryId));
    }
  };

  const getAllCategories = async () => {
    const data = await getCategories("", adminData.uid);

    setCategories(data);
  };
  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCities = async () => {
    const data = await getCities("", adminData.uid);
    setCities(data);
  };

  useEffect(() => {
    getAllCities();
  }, []);

  const handleChangeJobCity = (cityId: string) => {
    if (!citiesId.includes(cityId)) {
      setCitiesId((prev) => [...prev, cityId]);
    } else {
      setCitiesId(citiesId.filter((id) => id !== cityId));
    }
  };

  return (
    <VerticalLayout>
      <div className="flex-1 space-y-4 p-8 pt-6">
        <Heading
          title="Users"
          description="Here you can view all the details of the users"
        />
        <Separator />
        {/* <div className="flex flex-col items-start ">
          <div className="mb-8">
            <h1 className="text-3xl font-medium">Manage workers</h1>
          </div>
        </div> */}
        <Card className="p-4 ">
          <div className="flex w-full items-center justify-start relative  gap-4">
            {/* <div className="mb-2 w-[250px]">
              <h1 className="text-[15px] font-medium mb-1">Sort by</h1>
              <Select>
                <SelectTrigger className="w-[200px] bg-white">
                  <SelectValue placeholder="Sort type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="0">Title</SelectItem>
                  <SelectItem value="1">Category</SelectItem>
                  <SelectItem value="2">Date Created</SelectItem>
                  <SelectItem value="3">Applied Jobs</SelectItem>
                  <SelectItem value="4">Messaged</SelectItem>
                  <SelectItem value="5">Connections</SelectItem>
                </SelectContent>
              </Select>
            </div> */}
            <div className="mb-2">
              <h1 className="text-[15px]  font-medium mb-1">Categories</h1>
              <Select onValueChange={(e) => handleChangeJobCategory(e)}>
                <SelectTrigger className="bg-white w-[200px]">
                  <SelectValue placeholder="Select Category" />
                </SelectTrigger>
                <SelectContent>
                  {categories.map((option) => (
                    <SelectItem
                      key={option.category_id}
                      value={option.category_id}
                    >
                      {option.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="mb-2">
              <h1 className="text-[15px]  font-medium mb-1">Cities</h1>
              <Select onValueChange={(e) => handleChangeJobCity(e)}>
                <SelectTrigger className="bg-white w-[200px]">
                  <SelectValue placeholder="Select City" />
                </SelectTrigger>
                <SelectContent>
                  {cities.map((option) => (
                    <SelectItem key={option.id} value={option.id}>
                      {option.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            {/* <div ref={dateRef} className="relative w-[250px] mb-2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <h1 className="text-[15px] font-medium mb-1">Date</h1>
                <div className="flex items-center gap-2">
                  <Button
                    onClick={() => setShowStartDate(!showStartDate)}
                  >
                    {startDateValue?.format("DD/MM/YYYY")}
                  </Button>
                  <div className="absolute top-10 right-0 z-10 bg-white">
                    {showStartDate && (
                      <DateCalendar
                        value={startDateValue}
                        onChange={(newValue) => setStartDateValue(newValue)}
                      />
                    )}
                  </div>
                  <Button
                    onClick={() => setShowEndData(!showEndDate)}
                  >
                    {endDateValue?.format("DD/MM/YYYY")}
                  </Button>
                  <div className="absolute top-10 right-0 z-10 bg-white">
                    {showEndDate && (
                      <DateCalendar
                        value={endDateValue}
                        onChange={(newValue) => setEndDateValue(newValue)}
                      />
                    )}
                  </div>
                </div>
              </LocalizationProvider>
            </div> */}
            <DatePickerWithRange />
          </div>
          <FilterUsers />

          <TableAllUsers data={allUsersContent} />
        </Card>
      </div>
    </VerticalLayout>
  );
};

export default AllUsers;
