import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { BASE_URL } from "src/Config/api.config";
import { categorySelection, inputCategory } from "src/atoms/atoms";
import useLocalStorage from "./useLocalStorage";

const useManagementGeneral = () => {
  const [selectedCategory, setSelectedCategory] = useAtom(categorySelection);
  const [categoryInput, setCategoryInput] = useAtom(inputCategory);

  const [adminData, setAdminData] = useLocalStorage("adminData", "");

  const getSkills = async () => {
    const data = await fetch(
      `${BASE_URL}adm.job.skills?category_id=${selectedCategory.category_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${adminData.uid}`,
          // Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      //   .then((data) => {
      //     convertToSelect(data.result, "skills");
      //   })
      .catch((err) => console.log(err));
    return data.result;
  };
  const { data: skillsCategory, refetch: getSkillsFromCategory } = useQuery(
    ["skills", selectedCategory],
    getSkills
  );

  const getCategories = async () => {
    const data = await fetch(
      `${BASE_URL}adm.job.categories${
        categoryInput !== "" ? `?name=${categoryInput}` : ""
      }`,
      {
        headers: {
          Authorization: `Bearer ${adminData.uid}`,
          // Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result;
  };
  const { data: categories, refetch: getAllCategories } = useQuery(
    ["categories"],
    getCategories
  );
  return {
    skillsCategory,
    getSkillsFromCategory,
    categories,
    getAllCategories,
  };
};

export default useManagementGeneral;
