import React, { useReducer, useState } from "react";
import Password from "../Components/Login/Password";
import Username from "../Components/Login/Username";
import { ReactComponent as Logo } from "../assets/img/logo_2.svg";
// import HomePage from "../assets/img/home-bg.jpg";

const initialState = {
  username: "",
  password: "",
};

const AdminAccess = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showComponent, setShowComponent] = useState({
    user: true,
    password: false,
  });

  function reducer(
    state: { username: string; password: string },
    action: { key: string; value: string; type: string }
  ) {
    switch (action.type) {
      case "UPDATE":
        return {
          ...state,
          [action.key]: action.value,
        };
      default:
        return state;
    }
  }

  return (
    <div
      className={`h-screen bg-[url('../assets/img/home-bg.jpg')]  w-screen overflow-hidden items-center justify-center`}
    >
      <div className="bg-black/20 h-full w-full absolute top-0 bottom-0 z-0" />
      {/* <img src={HomePage} /> */}
      <div className="flex flex-col h-[calc(100%-100px)] items-center w-full justify-center ">
        <Logo className="h-16 w-fit mb-16" />
        <div className="flex flex-col border  rounded-lg w-[470px] items-center py-6 px-12 bg-white z-20">
          <p className="text-3xl font-medium">Admin Access</p>
          {showComponent.user && (
            <Username
              state={state}
              dispatch={dispatch}
              setShowComponent={setShowComponent}
            />
          )}

          {showComponent.password && (
            <Password state={state} dispatch={dispatch} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminAccess;
