import { useAtom } from "jotai";
import React, { useEffect, useReducer, useState } from "react";
import { ReactComponent as People } from "../assets/img/management_img.svg";

import CardManagementDeclineOfferReasons from "src/Components/Management/CardManagementDeclineOffersReason";
import CardManagementDeclineReasons from "src/Components/Management/CardManagementDeclineReasons";
import CardManagementEndWorkReasons from "src/Components/Management/CardManagementEndWorkReasons";
import CardManagementQuitReasons from "src/Components/Management/CardManagementQuitReasons";
import CardManagementReasons from "src/Components/Management/CardManagementReasons";
import CardManagementWorkerReasons from "src/Components/Management/CardManagementWorkerReasons";
import ModalManagement from "src/Components/Modals/ModalManagement";
import RequestErrorModal from "src/Components/RequestErrorPopup";
import { toast } from "src/Components/ui/use-toast";
import {
  BASE_URL,
  DECLINE_REASONS,
  END_WORK_REASONS,
  INAPPROPIATE_JOB_REASONS,
  INAPROPIATE_WORKER_PROFILE_REASONS,
  QUIT_WORK_REASONS,
} from "src/Config/api.config";
import useManagementData from "src/api/useManagementData";
import { editFunctionLoading } from "src/atoms/atoms";
import VerticalLayout from "src/layout/VerticalLayout";
import {
  AllCategories,
  AllDeclineOfferReasons,
  AllDeclineReasons,
  AllEndWorkReasons,
  AllInappropiateReasons,
  AllQuitReasons,
} from "src/types/types";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { Heading } from "src/Components/ui/heading";
import { Separator } from "src/Components/ui/separator";
import { DECLINE_OFFER_REASONS } from "src/Config/api.config";

const initialState = {
  newSkill: "",
  categorySkill: "",
  newCategory: "",
  showSkillInput: "",
  showCatInput: "",
  showCitiesInput: "",
  newCity: "",
  newProvince: "",
  newPostcode: "",
  newLocality: "",
  showPostcodesInput: "",
  showInappropiateReasonInput: "",
  newInappropiateReason: "",
  showInappropiateReasonWorkerInput: "",
  newIappropiateWorkerReason: "",
};

export const colourStyles = {
  control: () => ({
    border: "1px solid #1d90b3",
    display: "flex",
    fontWeight: "300",
    color: "black",
    letterSpacing: "0.05em",
    backgroundColor: "#F9F7F7",
    padding: "0rem",
    paddingLeft: "0.5rem",
    width: "200px",
    // maxWidth: "400px",
    borderRadius: "5px",
    height: "40px",
  }),
};

const INITIAL_CATEGORY_STATE = {
  category_id: "",
  label: "",
  name: "",
  value: "",
};

const INITIAL_INAPPROPIATE_REASON = {
  id: "",
  name: "",
};

const INITIAL_QUIT_REASON = {
  id: "",
  name: "",
};

const INITIAL_END_WORK_REASON = {
  id: "",
  name: "",
};

const INITIAL_DECLINE_REASON = {
  id: "",
  name: "",
};

const INITIAL_DECLINE_OFFER_REASON = {
  id: "",
  name: "",
};

export type ShowModal = {
  edit: boolean;
  delete?: boolean;
  section: string;
};

export type ShowItem = {
  edit: number;
  trash: number;
  where: string;
};

const ManagementOthers = () => {
  //CALLS
  const {
    allInappropiateReasons,
    allInappropiateWorkerReasons,
    allDeclineReasons,
    allDeclineOfferReasons,
    allEndWorkReasons,
    allQuitReasons,
    getAdmInappropiateReasons,
    getAdmInappropiateWorkerReasons,
    getAdmDeclineReasons,
    getAdmQuitReasons,
    getAdmDeclineOfferReasons,
    getAdmEndWorkReasons,
  } = useManagementData();

  //SELECTED
  const [selectedCategory, setSelectedCategory] = useState<AllCategories>(
    INITIAL_CATEGORY_STATE
  );

  const [selectedInappropiateReason, setSelectedInappropiateReason] =
    useState<AllInappropiateReasons>(INITIAL_INAPPROPIATE_REASON);
  const [
    selectedInappropiateWorkerReason,
    setSelectedInappropiateWorkerReason,
  ] = useState<AllInappropiateReasons>(INITIAL_INAPPROPIATE_REASON);
  const [selectedQuitReason, setSelectedQuitReason] =
    useState<AllQuitReasons>(INITIAL_QUIT_REASON);
  const [selectedEndWorkReason, setSelectedEndWorkReason] =
    useState<AllEndWorkReasons>(INITIAL_END_WORK_REASON);
  const [selectedDeclineReason, setSelectedDeclineReason] =
    useState<AllDeclineReasons>(INITIAL_DECLINE_REASON);
  const [selectedDeclineOfferReason, setSelectedDeclineOfferReason] =
    useState<AllDeclineOfferReasons>(INITIAL_DECLINE_OFFER_REASON);

  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const [showItem, setShowItem] = useState<ShowItem>({
    edit: -1,
    trash: -1,
    where: "",
  });
  const [alert, setAlert] = useState(false);
  const [typeOfAlert, setTypeOfAlert] = useState("");
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showModal, setShowModal] = useState<ShowModal>({
    edit: false,
    delete: false,
    section: "",
  });

  //INPUT

  const [inappropiateReasonInput, setInappropiateReasonInput] = useState("");
  const [inappropiateWorkerReasonInput, setInappropiateWorkerReasonInput] =
    useState("");
  const [quitReasonInput, setQuitReasonInput] = useState("");
  const [endWorkReasonInput, setEndWorkReasonInput] = useState("");
  const [declineReasonInput, setDeclineReasonInput] = useState("");
  const [declineReasonOfferInput, setDeclineReasonOfferInput] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const [warning, setWarning] = useState<string | boolean>(false);
  const [loading, setLoading] = useAtom(editFunctionLoading);

  function reducer(state: any, action: any) {
    switch (action.type) {
      case "ADD":
        return {
          ...state,
          [action.key]: action.value,
        };
      case "TOGGLE_INPUT":
        return {
          ...state,
          [action.key]: action.value,
        };
      default:
        return state;
    }
  }

  const handleReasonSearch = (e: string) => {
    setInappropiateReasonInput(e);
  };
  const handleWorkerReasonSearch = (e: string) => {
    setInappropiateWorkerReasonInput(e);
  };
  const handleQuitReasonSearch = (e: string) => {
    setQuitReasonInput(e);
  };
  const handleEndWorkReasonSearch = (e: string) => {
    setEndWorkReasonInput(e);
  };

  const handleDeclineReasonSearch = (e: string) => {
    setDeclineReasonInput(e);
  };
  const handleDeclineOfferReasonSearch = (e: string) => {
    setDeclineReasonOfferInput(e);
  };

  useEffect(() => {
    // dispatch({ type: "TOGGLE_INPUT", value: false, key: "showSkillInput" });
    dispatch({ type: "TOGGLE_INPUT", value: false, key: "showCatInput" });
  }, [selectedCategory]);

  useEffect(() => {
    if (inappropiateReasonInput !== "") {
      getAdmInappropiateReasons(inappropiateReasonInput);
    } else {
      getAdmInappropiateReasons("");
    }
  }, [inappropiateReasonInput]);

  useEffect(() => {
    if (inappropiateWorkerReasonInput !== "") {
      getAdmInappropiateWorkerReasons(inappropiateWorkerReasonInput);
    } else {
      getAdmInappropiateWorkerReasons("");
    }
  }, [inappropiateWorkerReasonInput]);

  useEffect(() => {
    if (declineReasonInput !== "") {
      getAdmDeclineReasons(declineReasonInput);
    } else {
      getAdmDeclineReasons("");
    }
  }, [declineReasonInput]);

  useEffect(() => {
    if (declineReasonOfferInput !== "") {
      getAdmDeclineOfferReasons(declineReasonOfferInput);
    } else {
      getAdmDeclineOfferReasons("");
    }
  }, [declineReasonOfferInput]);
  useEffect(() => {
    if (quitReasonInput !== "") {
      getAdmQuitReasons(quitReasonInput);
    } else {
      getAdmQuitReasons("");
    }
  }, [quitReasonInput]);

  useEffect(() => {
    if (endWorkReasonInput !== "") {
      getAdmEndWorkReasons(endWorkReasonInput);
    } else {
      getAdmEndWorkReasons("");
    }
  }, [endWorkReasonInput]);

  //EDIT

  let editInappropiateReasonData = new FormData();
  editInappropiateReasonData.append("_method", "PUT");

  const editInappropiateReason = (reasonId: string, name: string) => {
    setLoading(true);
    editInappropiateReasonData.append("inapropiate_job_reason_id", reasonId);
    editInappropiateReasonData.append("name", name);

    fetch(`${BASE_URL}${INAPPROPIATE_JOB_REASONS}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
      body: editInappropiateReasonData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.reasonUpdated === "ok") {
          toast({
            title: "Razón actualizada",
            description: `Razón ${reasonId}`,
          });
          setRefreshData(true);
          getAdmInappropiateReasons("");
        } else {
          toast({
            title: `${data.result.error_msg}`,
            description: "Unable to edit. An error happened!",
            variant: "destructive",
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  let editInappropiateWorkerReasonData = new FormData();
  editInappropiateWorkerReasonData.append("_method", "PUT");

  const editInappropiateWorkerReason = (reasonId: string, name: string) => {
    setLoading(true);
    editInappropiateWorkerReasonData.append(
      "inapropiate_worker_profile_reason_id",
      reasonId
    );
    editInappropiateWorkerReasonData.append("name", name);

    fetch(`${BASE_URL}${INAPROPIATE_WORKER_PROFILE_REASONS}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
      body: editInappropiateWorkerReasonData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.reasonUpdated === "ok") {
          toast({
            title: "Razón actualizada",
            description: `Razón ${reasonId}`,
          });
          setRefreshData(true);
          getAdmInappropiateWorkerReasons("");
        } else {
          toast({
            title: `${data.result.error_msg}`,
            description: "Unable to edit. An error happened!",
            variant: "destructive",
          });
          // setWarning(data.result.error_msg);
          // setTimeout(() => {
          //   setWarning(false);
          // }, 3800);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  let editDeclineReasonData = new FormData();
  editDeclineReasonData.append("_method", "PUT");

  const editDeclineReason = (reasonId: string, name: string) => {
    setLoading(true);
    editDeclineReasonData.append("decline_reason_id", reasonId);
    editDeclineReasonData.append("name", name);
    fetch(`${BASE_URL}${DECLINE_REASONS}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
      body: editDeclineReasonData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.reasonUpdated === "ok") {
          toast({
            title: `Razón actualizada`,
            description: `Razón ${reasonId}`,
          });
          setRefreshData(true);
          getAdmDeclineReasons("");
        } else {
          toast({
            title: `${data.result.error_msg}`,
            description: "Unable to edit. An error happened",
            variant: "destructive",
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  let editDeclineOfferReasonData = new FormData();
  editDeclineOfferReasonData.append("_method", "PUT");

  const editDeclineOfferReason = (reasonId: string, name: string) => {
    setLoading(true);
    editDeclineOfferReasonData.append("decline_offer_reason_id", reasonId);
    editDeclineOfferReasonData.append("name", name);

    fetch(`${BASE_URL}${DECLINE_OFFER_REASONS}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
      body: editDeclineOfferReasonData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.reasonUpdated === "ok") {
          toast({
            title: `Decline Reason actualizada`,
            description: `Razón ${reasonId}`,
          });
          setRefreshData(true);
          getAdmDeclineOfferReasons("");
        } else {
          toast({
            title: `${data.result.error_msg}`,
            description: "Unable to edit. An error happened",
            variant: "destructive",
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  let editQuitReasonData = new FormData();
  editQuitReasonData.append("_method", "PUT");
  const editQuitReason = (reasonId: string, name: string) => {
    setLoading(true);
    editQuitReasonData.append("quit_work_reason_id", reasonId);
    editQuitReasonData.append("name", name);

    fetch(`${BASE_URL}${QUIT_WORK_REASONS}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
      body: editQuitReasonData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.reasonUpdated === "ok") {
          toast({
            title: `Quit Reason actualizada`,
            description: `Razón ${reasonId}`,
          });
          setRefreshData(true);
          getAdmQuitReasons("");
        } else {
          toast({
            title: `${data.result.error_msg}`,
            description: "Unable to edit. An error happened!",
            variant: "destructive",
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  let editEndWorkReasonData = new FormData();
  editEndWorkReasonData.append("_method", "PUT");
  const editEndWorkReason = (reasonId: string, name: string) => {
    setLoading(true);
    editEndWorkReasonData.append("end_work_reason_id", reasonId);
    editEndWorkReasonData.append("name", name);

    fetch(`${BASE_URL}${END_WORK_REASONS}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
      body: editEndWorkReasonData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.reasonUpdated === "ok") {
          toast({
            title: `End Work Reason actualizada`,
            description: `Razón ${reasonId}`,
          });
          setRefreshData(true);
          getAdmEndWorkReasons("");
        } else {
          toast({
            title: `${data.result.error_msg}`,
            description: "Unable to edit. An error happened!",
            variant: "destructive",
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  //DELETE

  const deleteInappropiateReason = (reasonId: string) => {
    setLoading(true);
    fetch(
      `${BASE_URL}${INAPPROPIATE_JOB_REASONS}?inapropiate_job_reason_id=${reasonId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + adminData.uid,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setRefreshData(true);
          getAdmInappropiateReasons("");
          toast({
            title: `Reason ${reasonId} deleted!`,
            description: "Deleted Successfully.",
          });
        } else {
          toast({
            title: `${data.result.error_msg}`,
            description: "Unable to delete reason. An Error Ocurred!",
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteInappropiateWorkerReason = (reasonId: string) => {
    setLoading(true);
    fetch(
      `${BASE_URL}${INAPROPIATE_WORKER_PROFILE_REASONS}?inapropiate_worker_profile_reason_id=${reasonId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + adminData.uid,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setRefreshData(true);
          getAdmInappropiateWorkerReasons("");
          toast({
            title: `Reason ${reasonId} deleted!`,
            description: "Deleted Successfully.",
          });
        } else {
          toast({
            title: `${data.result.error_msg}`,
            description: "Unable to delete reason. An Error Ocurred!",
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteDeclineReason = (reasonId: string) => {
    setLoading(true);
    fetch(`${BASE_URL}${DECLINE_REASONS}?decline_reason_id=${reasonId}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setRefreshData(true);
          getAdmDeclineReasons("");
          toast({
            title: `Reason ${reasonId} deleted!`,
            description: "Deleted Successfully.",
          });
        } else {
          toast({
            title: `${data.result.error_msg}`,
            description: "Unable to delete reason. An error ocurred!",
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteDeclineOfferReason = (reasonId: string) => {
    setLoading(true);
    fetch(
      `${BASE_URL}${DECLINE_OFFER_REASONS}?decline_offer_reason_id=${reasonId}`,
      {
        method: "DELETE",

        headers: {
          Authorization: "Bearer " + adminData.uid,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setRefreshData(true);
          getAdmDeclineOfferReasons("");
          toast({
            title: `Reason ${reasonId} deleted!`,
            description: "Deleted Successfully.",
          });
        } else {
          toast({
            title: `${data.result.error_msg}`,
            description: "Unable to delete reason. An error ocurred!",
            variant: "destructive",
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };
  const deleteQuitReason = (reasonId: string) => {
    setLoading(true);
    fetch(`${BASE_URL}${QUIT_WORK_REASONS}?quit_work_reason_id=${reasonId}`, {
      method: "DELETE",

      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setRefreshData(true);
          getAdmQuitReasons("");
          toast({
            title: `Reason ${reasonId} deleted!`,
            description: "Deleted Successfully.",
          });
        } else {
          toast({
            title: `${data.result.error_msg}`,
            description: "Unable to delete reason. An error ocurred!",
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };
  const deleteEndWorkReason = (reasonId: string) => {
    setLoading(true);
    fetch(`${BASE_URL}${END_WORK_REASONS}?end_work_reason_id=${reasonId}`, {
      method: "DELETE",

      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setRefreshData(true);
          getAdmEndWorkReasons("");
        } else {
          toast({
            title: `${data.result.error_msg}`,
            description: "Unable to delete reason. An error ocurred!",
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <VerticalLayout>
      <div className="flex-1 space-y-4 p-8 pt-6">
        <div
          className={`flex justify-center transition-opacity duration-500 ${
            !alert ? "opacity-0 " : "opacity-100 "
          }`}
        >
          <div className="bg-green-600 text-white font-bold text-sm rounded-lg py-3.5 tracking-wide px-6 mt-6 fixed items-start shadow-lg">
            <div className="flex justify-between gap-[78px] items-center">
              <div className="flex gap-4 items-center">
                <p>{typeOfAlert}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute top-7 left-[50%]">
          <RequestErrorModal warning={warning} />
        </div>
        <Heading
          title="Management"
          description="Manage reasons related to other aspects, such as reports, end work, decline offers, quit work reasons, etc..."
        />
        <Separator />
        {/* <div className="flex items-center gap-8 justify-start">
          <People className="h-10 w-fit" />
          <p className="font-bold tracking-wider">OTHERS MANAGEMENT</p>
        </div> */}

        <div className=" mt-12 flex xl:flex-row flex-col gap-12">
          <CardManagementReasons
            allInappropiateReasons={allInappropiateReasons}
            setRefreshData={setRefreshData}
            selectedInappropiateReason={selectedInappropiateReason}
            setSelectedInappropiateReason={setSelectedInappropiateReason}
            setShowItem={setShowItem}
            showItem={showItem}
            setShowModal={setShowModal}
            handleReasonSearch={handleReasonSearch}
            setSelectedCategory={setSelectedCategory}
            getAdmInappropiateReasons={getAdmInappropiateReasons}
          />
          <CardManagementWorkerReasons
            allInappropiateWorkerReasons={allInappropiateWorkerReasons}
            setRefreshData={setRefreshData}
            selectedInappropiateWorkerReason={selectedInappropiateWorkerReason}
            setSelectedCategory={setSelectedCategory}
            setSelectedInappropiateWorkerReason={
              setSelectedInappropiateWorkerReason
            }
            setShowItem={setShowItem}
            setShowModal={setShowModal}
            showItem={showItem}
            handleReasonWorkerSearch={handleWorkerReasonSearch}
            getAdmInappropiateWorkerReasons={getAdmInappropiateWorkerReasons}
          />
        </div>
        <div className=" mt-12 flex xl:flex-row flex-col gap-12 ">
          <CardManagementDeclineReasons
            allDeclineReasons={allDeclineReasons}
            getAdmDeclineReasons={getAdmDeclineReasons}
            handleReasonSearch={handleDeclineReasonSearch}
            selectedDeclineReason={selectedDeclineReason}
            setSelectedDeclineReason={setSelectedDeclineReason}
            setRefreshData={setRefreshData}
            setSelectedCategory={setSelectedCategory}
            setShowItem={setShowItem}
            setShowModal={setShowModal}
            showItem={showItem}
          />
          <CardManagementDeclineOfferReasons
            allDeclineOfferReasons={allDeclineOfferReasons}
            getAdmDeclineOfferReasons={getAdmDeclineOfferReasons}
            handleReasonSearch={handleDeclineOfferReasonSearch}
            selectedDeclineOfferReason={selectedDeclineOfferReason}
            setRefreshData={setRefreshData}
            setSelectedCategory={setSelectedCategory}
            setSelectedDeclineOfferReason={setSelectedDeclineOfferReason}
            setShowItem={setShowItem}
            setShowModal={setShowModal}
            showItem={showItem}
          />
        </div>
        <div className="px-4 mt-12 flex xl:flex-row flex-col gap-12">
          <CardManagementQuitReasons
            allQuitReasons={allQuitReasons}
            getAdmQuitReasons={getAdmQuitReasons}
            handleReasonSearch={handleQuitReasonSearch}
            selectedQuitReason={selectedQuitReason}
            setSelectedQuitReason={setSelectedQuitReason}
            setRefreshData={setRefreshData}
            setSelectedCategory={setSelectedCategory}
            setShowItem={setShowItem}
            setShowModal={setShowModal}
            showItem={showItem}
          />
          <CardManagementEndWorkReasons
            allEndWorkReasons={allEndWorkReasons}
            getAdmEndWorkReasons={getAdmEndWorkReasons}
            handleReasonSearch={handleEndWorkReasonSearch}
            selectedEndWorkReason={selectedEndWorkReason}
            setRefreshData={setRefreshData}
            setSelectedCategory={setSelectedCategory}
            setSelectedEndWorkReason={setSelectedEndWorkReason}
            setShowItem={setShowItem}
            setShowModal={setShowModal}
            showItem={showItem}
          />
        </div>

        {showModal.edit && showModal.section === "inappropiate-reason" && (
          <ModalManagement
            section="inappropiate-reason"
            type={"edit"}
            showModal={showModal}
            setShowModal={setShowModal}
            handleAction={editInappropiateReason}
            selectedInappropiateReason={selectedInappropiateReason}
          />
        )}
        {showModal.delete && showModal.section === "inappropiate-reason" && (
          <ModalManagement
            section="inappropiate-reason"
            type={"delete"}
            showModal={showModal}
            setShowModal={setShowModal}
            handleAction={deleteInappropiateReason}
            selectedInappropiateReason={selectedInappropiateReason}
          />
        )}

        {showModal.edit &&
          showModal.section === "inappropiateworker-reason" && (
            <ModalManagement
              section="inappropiateworker-reason"
              type={"edit"}
              showModal={showModal}
              setShowModal={setShowModal}
              handleAction={editInappropiateWorkerReason}
              selectedInappropiateWorkerReason={
                selectedInappropiateWorkerReason
              }
            />
          )}
        {showModal.delete &&
          showModal.section === "inappropiateworker-reason" && (
            <ModalManagement
              section="inappropiateworker-reason"
              type={"delete"}
              showModal={showModal}
              setShowModal={setShowModal}
              handleAction={deleteInappropiateWorkerReason}
              selectedInappropiateWorkerReason={
                selectedInappropiateWorkerReason
              }
            />
          )}

        {showModal.edit && showModal.section === "decline-reason" && (
          <ModalManagement
            section="decline-reason"
            type={"edit"}
            showModal={showModal}
            setShowModal={setShowModal}
            handleAction={editDeclineReason}
            selectedDeclineReason={selectedDeclineReason}
          />
        )}
        {showModal.delete && showModal.section === "decline-reason" && (
          <ModalManagement
            section="decline-reason"
            type={"delete"}
            showModal={showModal}
            setShowModal={setShowModal}
            handleAction={deleteDeclineReason}
            selectedDeclineReason={selectedDeclineReason}
          />
        )}
        {showModal.edit && showModal.section === "declineoffer-reason" && (
          <ModalManagement
            section="declineoffer-reason"
            type={"edit"}
            showModal={showModal}
            setShowModal={setShowModal}
            handleAction={editDeclineOfferReason}
            selectedDeclineOfferReason={selectedDeclineOfferReason}
          />
        )}
        {showModal.delete && showModal.section === "declineoffer-reason" && (
          <ModalManagement
            section="declineoffer-reason"
            type={"delete"}
            showModal={showModal}
            setShowModal={setShowModal}
            handleAction={deleteDeclineOfferReason}
            selectedDeclineOfferReason={selectedDeclineOfferReason}
          />
        )}

        {showModal.edit && showModal.section === "quitwork-reason" && (
          <ModalManagement
            section="quitwork-reason"
            type="edit"
            showModal={showModal}
            setShowModal={setShowModal}
            handleAction={editQuitReason}
            selectedQuitReason={selectedQuitReason}
          />
        )}
        {showModal.delete && showModal.section === "quitwork-reason" && (
          <ModalManagement
            section="quitwork-reason"
            type="delete"
            showModal={showModal}
            setShowModal={setShowModal}
            handleAction={deleteQuitReason}
            selectedQuitReason={selectedQuitReason}
          />
        )}
        {showModal.edit && showModal.section === "endwork-reason" && (
          <ModalManagement
            section="endwork-reason"
            type="edit"
            showModal={showModal}
            setShowModal={setShowModal}
            handleAction={editEndWorkReason}
            selectedEndWorkReason={selectedEndWorkReason}
          />
        )}
        {showModal.delete && showModal.section === "endwork-reason" && (
          <ModalManagement
            section="endwork-reason"
            type="delete"
            showModal={showModal}
            setShowModal={setShowModal}
            handleAction={deleteEndWorkReason}
            selectedEndWorkReason={selectedEndWorkReason}
          />
        )}
      </div>
    </VerticalLayout>
  );
};

export default ManagementOthers;
