import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { Button } from "../ui/Button";
import { Input } from "../ui/Input";
import { ReactComponent as Edit } from "../../assets/img/edit.svg";
import { ReactComponent as Trash } from "../../assets/img/trash-can.svg";
import { BASE_URL, JOB_CATEGORIES } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { AllCategories } from "src/types/types";
import ModalAddManagement from "../Modals/ModalAddManagement";
import { toast } from "../ui/use-toast";
import { debounce } from "src/Utils/debounce";
import { ShowItem, ShowModal } from "src/Pages/ManagementOthers";

interface CardManagementCategoriesProps {
  allCategories: AllCategories[];
  setRefreshData: Dispatch<SetStateAction<boolean>>;
  selectedCategory: AllCategories;
  setSelectedCategory: Dispatch<SetStateAction<AllCategories>>;
  setShowItem: Dispatch<SetStateAction<ShowItem>>;
  showItem: ShowItem;
  setShowModal: Dispatch<SetStateAction<ShowModal>>;
  handleCategorySearch: (args: string) => void;
}
const CardManagementCategories = ({
  allCategories,
  setRefreshData,
  selectedCategory,
  setSelectedCategory,
  setShowItem,
  setShowModal,
  showItem,
  handleCategorySearch,
}: CardManagementCategoriesProps) => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const [showAddModal, setShowAddModal] = useState(false);

  const optimisedSearch = useCallback(debounce(handleCategorySearch), []);

  let createCategoryData = new FormData();
  createCategoryData.append("_method", "POST");

  const createNewCategory = (category: string) => {
    createCategoryData.append("name", category);
    fetch(`${BASE_URL}${JOB_CATEGORIES}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${adminData.uid}`,
      },
      body: createCategoryData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.categoryAdded === "ok") {
          toast({
            title: "Categoria Añadida",
            description: `${category} añadido a la lista`,
            variant: "default",
          });
          setRefreshData(true);
          setShowAddModal(false);
        } else {
          toast({
            title: data.result.error_msg,
            variant: "destructive",
          });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="w-full">
      <div className="flex items-center w-full justify-between mb-4 cursor-pointer">
        <div className="flex items-center">
          <p className="font-bold text-lg">Categories</p>
          <Input
            className={`border border-border outline-none text-sm ml-6 h-full w-[205px] bg-white mr-4 px-4 pr-11 `}
            placeholder="Search Category"
            type="text"
            onChange={(e) => optimisedSearch(e.target.value)}
          />
        </div>
        <Button onClick={() => setShowAddModal(true)} type="button">
          + Add a category
        </Button>
      </div>
      <div className="bg-white border flex justify-between rounded dark:bg-background">
        <div className="w-full px-4  py-4 text-sm h-96 overflow-y-auto flex justify-between text-black">
          <div className="flex flex-col w-full max-h-[1000px] overflow-auto jobpost">
            {allCategories
              ?.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
              })
              .map((category, i) => {
                return (
                  <div
                    key={category.category_id}
                    className={`hover:bg-slate-100 w-full ${
                      selectedCategory?.name === category?.name &&
                      "bg-slate-100 font-bold"
                    } cursor-pointer py-2 border-b px-2 flex justify-between items-center`}
                    onClick={() => {
                      // dispatch({
                      //   type: "TOGGLE_INPUT",
                      //   value: false,
                      //   key: "showSkillInput",
                      // });
                      // getSkillsFromCategory(category.category_id);
                      setSelectedCategory(category);
                    }}
                    onMouseEnter={() => {
                      setShowItem((prev) => ({
                        ...prev,
                        trash: i,
                        edit: i,
                        where: "cat",
                      }));
                    }}
                    onMouseLeave={() => {
                      setShowItem((prev) => ({
                        ...prev,
                        trash: -1,
                        edit: -1,
                        where: "",
                      }));
                    }}
                  >
                    <p>{category.name}</p>
                    <div className="flex items-center gap-3">
                      {showItem.edit === i && showItem.where === "cat" && (
                        <div className="h-[20px] w-[22px] hover:bg-slate-200 flex items-center justify-center rounded">
                          <div
                            onClick={() =>
                              setShowModal((prev) => ({
                                ...prev,
                                edit: true,
                                section: "category",
                              }))
                            }
                            className=" hover:bg-gray-100"
                          >
                            <Edit className="h-[10px] fill-slate-700" />
                          </div>
                        </div>
                      )}
                      {showItem.trash === i && showItem.where === "cat" && (
                        <div
                          onClick={() => {
                            setShowModal((prev) => ({
                              ...prev,
                              delete: true,
                              section: "category",
                            }));
                          }}
                          className="h-[20px] w-[22px] hover:bg-slate-200 flex items-center justify-center rounded"
                        >
                          <Trash className="h-[14px] fill-slate-700" />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {showAddModal && (
        <ModalAddManagement
          handleAction={createNewCategory}
          section="category"
          setShowModal={setShowAddModal}
          type="add"
          setSelectedCategory={setSelectedCategory}
        />
      )}
    </div>
  );
};

export default CardManagementCategories;
