import { ChevronLeftIcon, HomeIcon } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import JobDetails from "src/Components/AllJobs/JobDetails";
import ModalSingleJob from "src/Components/Modals/ModalSingleJob";
import { Button, buttonVariants } from "src/Components/ui/Button";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { useModalStore } from "src/Hooks/useModalStore";
import VerticalLayout from "src/layout/VerticalLayout";

type Props = {};

const SingleJob = (props: Props) => {
  const [jobInfo, setJobInfo] = useLocalStorage("jobInfo", "");
  const navigate = useNavigate();
  const [
    showModalCloseJob,
    setShowModalCloseJob,
    showModalRemoveJob,
    setShowModalRemoveJob,
  ] = useModalStore((state) => [
    state.showModalCloseJob,
    state.setShowModalCloseJob,
    state.showModalRemoveJob,
    state.setShowModalRemoveJob,
  ]);

  const closeJob = () => console.log("close job");
  const removeJob = () => console.log("remove job");
  return (
    <VerticalLayout>
      <div className="container max-w-6xl mx-auto h-full pt-12">
        <div
          onClick={() => {
            navigate("/all-jobs");
          }}
          className="flex items-center my-10 cursor-pointer"
        >
          <ChevronLeftIcon className="h-4 w-4" />
          <h3>Back</h3>
        </div>
        <h1 className="font-bold text-3xl md:text-4xl">{jobInfo.title}</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-y-4 md:gap-x-4 py-6">
          <JobDetails />
          <div className="overflow-hidden h-fit rounded-lg border border-gray-200 bg-white order-first md:order-last">
            <div className="bg-emerald-100 px-6 py-4">
              <p className="font-semibold py-3 flex items-center gap-1.5">
                <HomeIcon className="h-4 w-4" />
                Home
              </p>
            </div>
            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
              <div className="flex justify-between gap-x-4 py-3">
                <p className="text-zinc-500">
                  Come here to check the information about the job.
                </p>
              </div>

              <Button
                onClick={() => setShowModalCloseJob(showModalCloseJob)}
                className={buttonVariants({
                  className: "w-full mt-4 mb-6",
                })}
              >
                Close job
              </Button>
              <Button
                onClick={() => setShowModalRemoveJob(showModalRemoveJob)}
                className={buttonVariants({
                  className: "w-full mt-4 mb-6",
                  variant: "subtle",
                })}
              >
                Remove job
              </Button>
            </dl>
          </div>
        </div>
      </div>
      {showModalCloseJob && (
        <ModalSingleJob type="close" section="job-close" onClick={closeJob}>
          <div>
            <p>
              Are you sure you want to close the job{" "}
              <span className="font-medium">{jobInfo.title}?</span>
            </p>
            <p className="mt-2">
              Once the job is closed, there is no going back.
            </p>
          </div>
        </ModalSingleJob>
      )}
      {showModalRemoveJob && (
        <ModalSingleJob type="remove" section="remove-job" onClick={removeJob}>
          <div>
            <p>Are you sure you want to remove the job </p>
            <span className="font-medium">{jobInfo.title}?</span>
            <p>Once the job is deleted, there is no going back.</p>
          </div>
        </ModalSingleJob>
      )}
    </VerticalLayout>
  );
};

export default SingleJob;
