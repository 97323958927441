import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
// import { BASE_URL } from "src/Config/api.config";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import image from "../../assets/img/shadow.png";

import { ReactComponent as Check } from "../../assets/img/check.svg";
import { ReactComponent as Cross } from "../../assets/img/cross.svg";
import { ReactComponent as Search } from "../../assets/img/search.svg";

import { HandleMonthName } from "src/Utils/HandleMonthName";
import { getComparator, stableSort } from "src/lib/tableUtils";
import { Input } from "../ui/Input";
import { BASE_URL_IMG } from "src/Config/api.config";
type Order = "asc" | "desc";

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

interface Data {
  id: string;
  worker: string;
  //   closed_jobs: string;
  applied_jobs: string;
  email: string;
  validated: string;
  joined_on: string;
}

interface HeadCell {
  disabledPadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    disabledPadding: false,
    numeric: false,
    label: "Image",
  },
  {
    id: "worker",
    disabledPadding: false,
    numeric: false,
    label: "Worker",
  },
  {
    id: "applied_jobs",
    disabledPadding: false,
    numeric: false,
    label: "Applied Jobs",
  },
  {
    id: "email",
    disabledPadding: false,
    label: "Email",
    numeric: false,
  },
  {
    id: "validated",
    disabledPadding: false,
    label: "Validated Account",
    numeric: false,
  },
  {
    id: "joined_on",
    disabledPadding: false,
    label: "Joined on",
    numeric: false,
  },
];

const TableWorkersData = ({ data }: any) => {
  const [rows, setRows] = useState(data);

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("id");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [animationParent] = useAutoAnimate<any>();
  const [searched, setSearched] = useState<string>("");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  useEffect(() => {
    setRows(data);
  }, [data]);

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const requestSearch = (searchVal: string) => {
    setSearched(searchVal);
    const filteredRows = data.filter((row: any) => {
      return row.first_name.toLowerCase().includes(searchVal.toLowerCase());
    });

    setRows(filteredRows);
  };

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, rowCount, onRequestSort, numSelected } = props;

    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow
          sx={{
            "& th": {
              fontSize: 14,
              fontWeight: "600",
              color: "rgba(96, 96, 96)",
              fontFamily: "inherit",
            },
          }}
        >
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disabledPadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <div className=" w-full flex items-center justify-start relative">
          <Search className="h-4 absolute left-[17rem] top-[28px] fill-[#1d90b3]" />

          <Input
            className="w-[300px] mt-4 ml-2"
            value={searched}
            onChange={(e) => requestSearch(e.target.value)}
            placeholder="Search Worker"
          />
          {/* <SearchBar
            className="w-[300px]"
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            placeholder={"Search Worker"}
          /> */}
        </div>
        {/* <div className="mt-20"></div> */}
        <TableContainer
          sx={{
            marginTop: 2,
            maxHeight: 840,
            "&::-webkit-scrollbar": {
              width: 10,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "cyan",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "lightblue",
              borderRadius: 2,
            },
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            <TableBody>
              {/* <TableBody ref={animationParent}> */}
              {stableSort(rows as any, getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row: any, index) => {
                  const isItemSelected = isSelected(row.id as any);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      sx={{
                        "& td": {
                          fontSize: 13,
                          fontWeight: "400",
                          color: "#333",
                          fontFamily: "inherit",
                        },
                      }}
                      hover
                      onClick={(event) => handleClick(event, row.id as any)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell>
                        {row.profile_image === null ? (
                          <img
                            loading="lazy"
                            src={image}
                            alt=""
                            width={60}
                            height={60}
                            className="w-12  object-cover rounded-full"
                          />
                        ) : (
                          <img
                            width={60}
                            height={60}
                            src={`${BASE_URL_IMG}${row.profile_image}`}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; //prevents looping
                              currentTarget.src = image;
                            }}
                            className="w-12 object-cover rounded-full"
                            alt=""
                          />
                        )}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.first_name} {row.last_name}
                      </TableCell>
                      <TableCell>3</TableCell>
                      {/* <TableCell>{row.nacionalidad}</TableCell> */}
                      {/* <TableCell>12</TableCell> */}
                      <TableCell>{row.email}</TableCell>
                      <TableCell>
                        {row?.validated === "0" ? (
                          <Cross className="fill-red-700 h-4 w-fit" />
                        ) : (
                          <Check className="fill-green-600 h-4 w-fit" />
                        )}
                      </TableCell>
                      <TableCell>
                        {HandleMonthName(row?.date_created.slice(5, 7)).slice(
                          0,
                          3
                        )}{" "}
                        {row?.date_created.slice(8, 10)},{" "}
                        {row?.date_created.slice(0, 4)}
                      </TableCell>
                      {/* <TableCell>
                        <div className="flex items-center">
                          <div className="p-2 rounded-full hover:bg-gray-100 transform transition-all duration-150 ease-in-out cursor-pointer hover:translate-y-2">
                            <EditIcon
                              onClick={() => navigate(`/player/${row.id}`)}
                            />
                          </div>
                          <div className="p-2 rounded-full hover:bg-red-200 transform transition-all duration-150 ease-in-out cursor-pointer hover:translate-y-2">
                            <TrashIcon
                              onClick={() => showHiddenDeleteComponent(row.id)}
                            />
                          </div>
                        </div>
                      </TableCell> */}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default TableWorkersData;
