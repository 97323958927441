import { ChevronLeftIcon, HomeIcon } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import ModalReportsManagement from "src/Components/Modals/ModalReportsManagement";
import HorizontalNavBar from "src/Components/NavBars/HorizontalNavBar";
import VerticalNavBar from "src/Components/NavBars/VerticalNavBar";
import JobPostFeed from "src/Components/Reports/JobPostFeed";
import { Button, buttonVariants } from "src/Components/ui/Button";
import { BASE_URL, FLAGED_JOB, INAPPROPIATE_JOB } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { useModalStore } from "src/Hooks/useModalStore";

import { toast } from "src/Components/ui/use-toast";
import { useQuery } from "@tanstack/react-query";

type Props = {};

const JobPage = (props: Props) => {
  const [selectedTab, setSelectedTab] = useLocalStorage("tabSelected", "");
  const navigate = useNavigate();
  const [jobInfo, setJobInfo] = useLocalStorage("jobInfo", "");
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const [
    showModalCloseJob,
    setShowModalCloseJob,
    showModalDismissFlag,
    setShowModalDismissFlag,
    showModalFlagUser,
    setShowModalFlagUser,
  ] = useModalStore((state) => [
    state.showModalCloseJob,
    state.setShowModalCloseJob,
    state.showModalDismissFlag,
    state.setShowModalDismissFlag,
    state.showModalFlagUser,
    state.setShowModalFlagUser,
  ]);

  const getFlaggedJobs = async () => {
    const data = await fetch(
      `${BASE_URL}${FLAGED_JOB}?job_id=${jobInfo.job_id}`,
      {
        headers: {
          Authorization: `Bearer ${adminData.uid}`,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));
    return data.result;
  };

  const {
    data: infoJob,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(["reportsData"], getFlaggedJobs);

  console.log(infoJob);

  const deleteFlaggedJob = (userId: string) => {
    fetch(
      `${BASE_URL}${FLAGED_JOB}?job_id=${jobInfo.job_id}&client_id_worker=${userId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + adminData.uid,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setShowModalDismissFlag(showModalDismissFlag);
          refetch();
          toast({
            title: `Flag Removed`,
            description: `${userId} Report Dismissed`,
          });
        } else {
          toast({
            title: `${data.result.error_msg}`,
            variant: "destructive",
          });
        }
      });
  };

  const closeReportedJob = () => {
    fetch(`${BASE_URL}${INAPPROPIATE_JOB}?job_id=${jobInfo.job_id}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setShowModalCloseJob(showModalCloseJob);
          navigate("/reports/jobs");
          toast({
            title: `Job ${jobInfo.job_id} Closed`,
            description: `Users notified! ${jobInfo.title} Closed. `,
          });
        } else {
          toast({
            title: `${data.result.error_msg}`,
            variant: "destructive",
          });
        }
      });
  };

  return (
    <div className="xl:flex-row flex flex-col bg-[#F3F3F4] min-h-screen h-full w-screen">
      <div className="xl:flex flex-[0_0_15%] hidden  h-full w-full  overflow-hidden">
        <VerticalNavBar
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
      <div className="xl:hidden w-full">
        <HorizontalNavBar
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
      <div className="container max-w-6xl mx-auto h-full pt-12 ">
        <div
          onClick={() => {
            navigate("/reports/jobs");
            // setTimeout(() => {
            //   setSelectedTab("reports");
            // }, 1000);
          }}
          className="flex items-center my-10 cursor-pointer"
        >
          <ChevronLeftIcon className="h-4 w-4" />
          <h3>Back</h3>
        </div>
        <h1 className="font-bold text-3xl md:text-4xl">Reported Post</h1>
        {/* <div className="flex items-center"> */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-y-4 md:gap-x-4 py-6 ">
          <JobPostFeed isLoading={isFetching} infoJob={infoJob} />
          <div className="overflow-hidden h-fit rounded-lg border border-gray-200 bg-white order-first md:order-last">
            <div className="bg-emerald-100 px-6 py-4">
              <p className="font-semibold py-3 flex items-center gap-1.5">
                <HomeIcon className="h-4 w-4" />
                Home
              </p>
            </div>
            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
              <div className="flex justify-between gap-x-4 py-3">
                <p className="text-zinc-500">
                  This job has been reported. Come here to check the users that
                  reported this post.
                </p>
              </div>

              <Button
                onClick={() => setShowModalCloseJob(showModalCloseJob)}
                // onClick={() => setShowModal(!showModal)}
                className={buttonVariants({
                  className: "w-full mt-4 mb-6",
                })}
              >
                Close job
              </Button>
            </dl>
          </div>
        </div>
      </div>
      {showModalCloseJob && (
        <ModalReportsManagement
          onClick={closeReportedJob}
          section="job-close"
          type="close"
        >
          <div>
            <p>
              Are you sure you want to close the job{" "}
              <span className="font-medium">{jobInfo.title}?</span>
            </p>
            <p className="text-gray-500 text-sm mt-4">
              Once the job is closed, there is no going back.
            </p>
          </div>
        </ModalReportsManagement>
      )}
      {showModalDismissFlag && (
        <ModalReportsManagement
          onClick={deleteFlaggedJob}
          section="dismiss-flag"
          type="dismiss"
        >
          <div>Are you sure you want dimiss this report ?</div>
          <p className="text-gray-500 text-sm mt-4">
            This user's flag will have no effect in the post
          </p>
        </ModalReportsManagement>
      )}
      {showModalFlagUser && (
        <ModalReportsManagement
          onClick={() => console.log("hhello")}
          section="report-user"
          type="report"
        >
          <div>Are you sure you want to delete the user's report ?</div>
          <p className="text-gray-500 text-sm mt-4">
            This user's report will be eliminated. There is no going back
          </p>
        </ModalReportsManagement>
      )}
    </div>
  );
};

export default JobPage;
