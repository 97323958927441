//@ts-nocheck

import "./App.css";
import { Toaster } from "./Components/ui/toaster";
import { Router } from "./Routing/Router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ColorModeContext, useMode } from "./theme";
import { ThemeProvider } from "@mui/material";

function App() {
  const [theme, colorMode] = useMode();
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster />
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <Router />
        </ThemeProvider>
      </ColorModeContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
