import React, { useRef } from "react";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { Button, buttonVariants } from "../ui/Button";
import { useModalStore } from "src/Hooks/useModalStore";
import { X } from "lucide-react";
import { selectedUserId } from "src/atoms/atoms";
import { useAtom } from "jotai";
import { useOnClickOutside } from "src/Hooks/useOnClickOutside";

interface ModalReportsManagementProps {
  type: string;
  section: string;
  children: React.ReactNode;
  onClick: (args1?: any, args2?: any) => void;
}

const ModalReportsManagement = ({
  section,
  type,
  onClick,
  children,
}: ModalReportsManagementProps) => {
  const [jobInfo, setJobInfo] = useLocalStorage("jobInfo", "");
  const [selectWorker, setSelectWorkerId] = useAtom(selectedUserId);
  const modalRef = useRef<HTMLDivElement>(null);

  const [
    showModalCloseJob,
    setShowModalCloseJob,
    showModalDismissFlag,
    setShowModalDismissFlag,
    showModalFlagUser,
    setShowModalFlagUser,
  ] = useModalStore((state) => [
    state.showModalCloseJob,
    state.setShowModalCloseJob,
    state.showModalDismissFlag,
    state.setShowModalDismissFlag,
    state.showModalFlagUser,
    state.setShowModalFlagUser,
  ]);

  useOnClickOutside(modalRef, () => {
    setShowModalCloseJob(showModalCloseJob);
    setShowModalDismissFlag(showModalDismissFlag);
    setShowModalFlagUser(showModalFlagUser);
  });

  return (
    <div
      ref={modalRef}
      className="fixed top-0 w-full h-full bg-black/50 text-black z-50"
    >
      <div className="fixed  z-[50] w-[28%] left-[50%] shadow-xl translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white">
        <div className="flex items-center justify-between border-b px-8 py-6">
          <h1 className="text-xl font-bold">
            {section === "job-close" && <>{type === "close" && "Close job"}</>}
            {section === "dismiss-flag" && (
              <>{type === "dismiss" && "Dismiss Report job"}</>
            )}
            {section === "report-user" && (
              <>{type === "report" && "Report user"}</>
            )}
          </h1>
          {showModalCloseJob && (
            <Button
              variant="subtle"
              className="h-6 w-6 p-0 rounded-md "
              onClick={() => setShowModalCloseJob(showModalCloseJob)}
            >
              <X aria-label="close modal" className="h-4 w-4" />
            </Button>
          )}
          {showModalDismissFlag && (
            <Button
              variant="subtle"
              className="h-6 w-6 p-0 rounded-md "
              onClick={() => setShowModalDismissFlag(showModalDismissFlag)}
            >
              <X aria-label="close modal" className="h-4 w-4" />
            </Button>
          )}
          {showModalFlagUser && (
            <Button
              variant="subtle"
              className="h-6 w-6 p-0 rounded-md "
              onClick={() => setShowModalFlagUser(showModalFlagUser)}
            >
              <X aria-label="close modal" className="h-4 w-4" />
            </Button>
          )}
          {/* <CloseModal setShowModal={setShowModalCloseJob(showModalCloseJob)} /> */}
        </div>
        <div className="mx-8 my-6">{children}</div>
        <div className="mx-8 my-6">
          {section === "job-close" && (
            <Button
              onClick={() => {
                onClick();
                setShowModalCloseJob(showModalCloseJob);
              }}
              className={buttonVariants({
                className: "w-full mt-4 mb-6 ",
              })}
            >
              Close job
            </Button>
          )}
          {section === "dismiss-flag" && (
            <Button
              onClick={() => {
                onClick(selectWorker);

                setShowModalDismissFlag(showModalDismissFlag);
              }}
              className={buttonVariants({
                className: "w-full mt-4 mb-6 ",
                variant: "subtle",
              })}
            >
              Dismiss report
            </Button>
          )}
          {section === "report-user" && (
            <Button
              className={buttonVariants({
                className: "w-full mt-4 mb-6 ",
                variant: "destructive",
              })}
            >
              Report user
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalReportsManagement;
