import React, { useState } from "react";
import { Pie } from "react-chartjs-2";
import { ReactComponent as ArrowDown } from "../../assets/img/arrow-down-solid.svg";
import { ReactComponent as Cross } from "../../assets/img/cross.svg";
import { ClientsData } from "src/types/types";
import { useClientsData } from "src/Hooks/useClientsData";

interface ClientsProps {
  statsData: ClientsData;
}
export const options: any = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "right",
      labels: {
        boxWidth: 14,
        boxHeight: 14,
        usePointStyle: true,
        padding: 5,
      },
    },
  },
  layout: {
    padding: {
      left: 0,
      top: 0,
      bottom: 0,
    },
  },
};

const Clients = ({ statsData }: ClientsProps) => {
  const [showCompressed, setShowCompressed] = useState(true);
  const { activeStatusData, data, topCitiesData, validatedStatusData } =
    useClientsData({ statsData });

  if (!activeStatusData.labels) return <>Loading...</>;
  return (
    <div className="w-full">
      <div
        className={`${
          showCompressed && "border-b border-slate-500"
        } py-2 flex items-center justify-between`}
      >
        <p className="text-base mx-6 tracking-wide font-semibold text-slate-500">
          Clients
        </p>
        <div className="flex items-center gap-6 mx-6">
          <Cross className="h-3 fill-slate-500" />
          <ArrowDown
            className={`h-3 cursor-pointer fill-slate-500 ${
              showCompressed
                ? "animate-toArrowUp rotate-180"
                : "animate-toArrowDown"
            }`}
            onClick={() => setShowCompressed(!showCompressed)}
          />
        </div>
      </div>
      {showCompressed && (
        <div className="flex justify-around w-full px-4">
          <div className="flex flex-col">
            <div className="flex mt-4">
              <div className="flex flex-col items-center">
                <p className="h-fit text-sm font-bold w-fit">Type of clients</p>
                <div className="xl:w-[350px] xl:h-[250px] laptop:w-[300px] laptop:h-[225px] w-[350px] h-[270px] py-4  relative ">
                  <Pie data={data} options={options as any} />
                </div>
              </div>
              <div className="flex flex-col items-center">
                <p className="h-fit text-sm font-bold w-fit">
                  Activated Accounts
                </p>
                <div className="xl:w-[350px] xl:h-[250px] laptop:w-[300px] laptop:h-[225px] w-[350px] h-[270px] py-4  relative">
                  <Pie data={activeStatusData} options={options as any} />
                </div>
              </div>
            </div>
            <div className="flex mt-4">
              <div className="flex flex-col items-center">
                <p className="h-fit text-sm font-bold w-fit">
                  Validated Accounts
                </p>
                <div className="xl:w-[350px] xl:h-[250px] laptop:w-[300px] laptop:h-[225px] w-[350px] h-[270px] py-4  relative">
                  <Pie data={validatedStatusData} options={options as any} />
                </div>
              </div>
              <div className="flex flex-col items-center">
                <p className="h-fit text-sm font-bold w-fit">
                  Most common locations
                </p>
                <div className="xl:w-[350px] xl:h-[250px] laptop:w-[300px] laptop:h-[225px] w-[350px] h-[270px] py-4  relative">
                  <Pie data={topCitiesData} options={options as any} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Clients;
