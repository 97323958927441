import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { AllInappropiateReasons, JobInappropiatePost } from "src/types/types";
import ReportJobCard from "./ReportJobCard";
import { useAtom } from "jotai";
import { jobTitle } from "src/atoms/atoms";
import { debounce } from "src/Utils/debounce";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { BASE_URL, INAPPROPIATE_JOB_REASONS } from "src/Config/api.config";
import { Card } from "../ui/card";
import { DatePickerWithRange } from "../ui/date-rangepicker";

interface ReportJobsProps {
  inappropiateJobs: JobInappropiatePost[];
  setSortDirection: Dispatch<SetStateAction<string>>;
  sortDirection: string;
  isLoading: boolean;
  inappropiateJobReasonId: AllInappropiateReasons;
  setInappropiateJobReasonId: Dispatch<SetStateAction<AllInappropiateReasons>>;
}

const ReportJobs = ({
  inappropiateJobs,
  setSortDirection,
  isLoading,
}: ReportJobsProps) => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const [allInappropiateReasons, setAllInappropiateReasons] = useState<
    AllInappropiateReasons[]
  >([]);

  const [job_title, setJobTitle] = useAtom(jobTitle);

  const handleSearchJob = (e: string) => {
    setJobTitle(e);
  };

  const optimisedSearch = useCallback(debounce(handleSearchJob), []);

  const getAdmInappropiateReasons = (name: string) => {
    fetch(`${BASE_URL}${INAPPROPIATE_JOB_REASONS}?name=${name}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + adminData.uid,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllInappropiateReasons(data.result))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAdmInappropiateReasons("");
  }, []);

  return (
    <div className=" flex flex-col w-full pb-12 pt-4">
      {/* <div className="flex items-center justify-start gap-20 my-6">
        <Input
          placeholder="Enter job post"
          className="bg-white w-[205px]"
          onChange={(e) => optimisedSearch(e.target.value)}
        />
      </div> */}
      <Card className="flex items-center gap-10 p-4">
        <div className="py-4">
          <h1 className="text-[15px] mb-4 font-medium">Filter by</h1>
          <Select>
            <SelectTrigger className="w-[300px] bg-white">
              <SelectValue placeholder="Select a reason" />
            </SelectTrigger>
            <SelectContent>
              {allInappropiateReasons.map((reason) => (
                <SelectItem value={reason.id}>{reason.name}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="py-4">
          <h1 className="text-[15px] mb-4 font-medium">Sort by</h1>
          <Select onValueChange={(e) => setSortDirection(e)}>
            <SelectTrigger className="w-[300px] bg-white">
              <SelectValue placeholder="Order Date Created" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="ASC">Ascendente</SelectItem>
              <SelectItem value="DESC">Descendente</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="py-4">
          <h1 className="text-[15px] mb-4 font-medium">Period Date</h1>
          <DatePickerWithRange />
        </div>
      </Card>

      <div className="grid grid-cols-1 md:grid-cols-5  gap-10 my-10">
        {inappropiateJobs?.map((job: JobInappropiatePost) => (
          <ReportJobCard key={job.job_id} job={job} isLoading={isLoading} />
        ))}
      </div>
    </div>
  );
};

export default ReportJobs;
