import { DollarSign } from "lucide-react";
import {
  User,
  AlertTriangle,
  Settings,
  ArrowRight,
  BarChart,
  Album,
  FileText,
  Map,
  Moon,
  Sun,
  SquareSlash,
  Briefcase,
  Building2,
  View,
  Bot,
  ClipboardList,
} from "lucide-react";

export const Icons = {
  user: User,
  error: AlertTriangle,
  settings: Settings,
  arrowright: ArrowRight,
  barchart: BarChart,
  book: Album,
  report: FileText,
  map: Map,
  moon: Moon,
  sun: Sun,
  credits: DollarSign,
  jobs: SquareSlash,
  work: Briefcase,
  company: Building2,
  overview: View,
  robot: Bot,
  waitlist: ClipboardList,
};
