import { useQuery } from "@tanstack/react-query";
import debounce from "lodash.debounce";
import { FC, useCallback, useEffect, useRef, useState } from "react";

import { Briefcase } from "lucide-react";
import { useOnClickOutside } from "src/Hooks/useOnClickOutside";
import { useNavigate } from "react-router-dom";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./ui/Command";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { JobInappropiatePost } from "src/types/types";
import { BASE_URL } from "src/Config/api.config";

interface SearchBarProps {}

const SearchBar: FC<SearchBarProps> = ({}) => {
  const [input, setInput] = useState<string>("");
  const commandRef = useRef<HTMLDivElement>(null);
  const [adminData, setAdminData] = useLocalStorage("adminData", "");

  const navigate = useNavigate();

  useOnClickOutside(commandRef, () => {
    setInput("");
  });

  const request = debounce(async () => {
    refetch();
  }, 1000);

  const debounceRequest = useCallback(() => {
    request();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: jobSearchResults,
    refetch,
    isFetched,
    isFetching,
  } = useQuery({
    queryFn: async () => {
      if (!input) return [];

      const data = await fetch(
        `${BASE_URL}adm.inapropiate.job?job_title=${input}`,
        {
          headers: {
            Authorization: "Bearer " + adminData.uid,
          },
        }
      ).then((res) => res.json());

      return data.result as JobInappropiatePost[];
    },
    queryKey: ["search-query"],
    enabled: false,
  });

  useEffect(() => {
    setInput("");
  }, []);

  return (
    <Command
      ref={commandRef}
      className="relative rounded-lg border max-w-lg z-50 overflow-visible"
    >
      <CommandInput
        isLoading={isFetching}
        onValueChange={(text) => {
          setInput(text);
          debounceRequest();
        }}
        value={input}
        className="outline-none border-none focus:border-none focus:outline-none ring-0"
        placeholder="Search reported jobs..."
      />

      {input.length > 0 && (
        <CommandList className="absolute bg-white top-full inset-x-0 shadow rounded-b-md">
          {isFetched && <CommandEmpty>No results found.</CommandEmpty>}
          {(jobSearchResults?.length ?? 0) > 0 ? (
            <CommandGroup heading="Reported Jobs">
              {jobSearchResults?.map((post) => (
                <CommandItem
                  onSelect={(e) => {
                    navigate(`//reports/jobs/${post.job_id}`);
                  }}
                  key={post.job_id}
                  value={post.title}
                >
                  <Briefcase className="mr-2 h-4 w-4" />
                  <a href={`//reports/jobs/${post.job_id}`}>{post.title}</a>
                </CommandItem>
              ))}
            </CommandGroup>
          ) : null}
        </CommandList>
      )}
    </Command>
  );
};

export default SearchBar;
