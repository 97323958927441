import { Dispatch, SetStateAction, useState } from "react";
import { Input } from "../ui/Input";
import { Button, buttonVariants } from "../ui/Button";
import { cn } from "src/lib/utils";

interface Props {
  handleAction: (
    name: string,
    creditsForCompany?: string,
    creditsForEmployer?: string,
    creditsForWorker?: string
  ) => void;

  setShowAddModal: Dispatch<SetStateAction<boolean>>;
}

const ModalAddCredits = ({ handleAction, setShowAddModal }: Props) => {
  const [creditName, setCreditsName] = useState("");
  const [creditsCompany, setCreditsCompany] = useState("");
  const [creditsEmployer, setCreditsEmployer] = useState("");
  const [creditsWorker, setCreditsWorker] = useState("");
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black/50 text-black z-50">
      <div className="fixed z-[50] w-[28%] left-[50%] shadow-xl translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white  ">
        <div className="text-xl font-bold border-b px-8 py-6">
          Add Flexicoins Use Type
        </div>
        <div className="mx-8 my-6 flex flex-col space-y-4">
          <p>Credits Name:</p>
          <Input
            type="text"
            autoFocus
            // ref={catRef}
            className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
            placeholder="Enter credit type name"
            value={creditName}
            onChange={(e) => setCreditsName(e.target.value)}
          />
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col gap-4 mb-4">
              <p>Value for Company:</p>
              <Input
                value={creditsCompany}
                placeholder="Enter Value"
                className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                onChange={(e) => setCreditsCompany(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-4">
              <p>Value for Employer:</p>
              <Input
                value={creditsEmployer}
                placeholder="Enter Value"
                className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                onChange={(e) => setCreditsEmployer(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-4">
              <p>Value for Worker:</p>
              <Input
                value={creditsWorker}
                placeholder="Enter Value"
                className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                onChange={(e) => setCreditsWorker(e.target.value)}
              />
            </div>
          </div>
          <div className="flex items-center space-x-2 justify-end">
            <Button
              className={cn(buttonVariants({ variant: "ghost" }))}
              // className="bg-none text-[0.9rem] font-semibold w-24 h-10 border border-[#2abeeb] text-[#2abeeb] rounded-3xl hover:bg-[#f1efef] cursor-pointer mt-1 transition duration-300"
              onClick={() => setShowAddModal(false)}
            >
              Cancel
            </Button>
            <Button
              disabled={creditName === ""}
              onClick={() =>
                handleAction(
                  creditName,
                  creditsCompany,
                  creditsEmployer,
                  creditsWorker
                )
              }
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddCredits;
