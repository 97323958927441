import { useEffect, useState } from "react";

import { Button } from "../ui/Button";
import { Modal } from "../ui/Modal";
import ModalCompany from "./ModalCompany";

interface AlertModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading: boolean;
}

export const AlertModal: React.FC<AlertModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  loading,
}) => {
  if (!isOpen) return null;
  return (
    <ModalCompany
      // <ModalCompany
      title="Are you sure?"
      description="This action cannot be undone."
      isOpen={isOpen}
      //   onClose={onClose}
    >
      <div className="pt-6 pb-2 space-x-2 flex items-center justify-end pr-2 w-full">
        <Button disabled={loading} variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={loading} onClick={onConfirm}>
          Continue
        </Button>
      </div>
    </ModalCompany>
  );
};
