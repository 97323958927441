import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { AllCategories, AllDeclineOfferReasons } from "src/types/types";
import { debounce } from "src/Utils/debounce";
import { Input } from "../ui/Input";
import { ReactComponent as Edit } from "../../assets/img/edit.svg";
import { ReactComponent as Trash } from "../../assets/img/trash-can.svg";
import { BASE_URL, DECLINE_OFFER_REASONS } from "src/Config/api.config";
import { toast } from "../ui/use-toast";
import ModalAddManagement from "../Modals/ModalAddManagement";
import { ShowItem, ShowModal } from "src/Pages/ManagementOthers";
import { Button } from "../ui/Button";

interface CardManagementDeclineOfferReasonsProps {
  allDeclineOfferReasons: AllDeclineOfferReasons[];
  setRefreshData: Dispatch<SetStateAction<boolean>>;
  selectedDeclineOfferReason: AllDeclineOfferReasons;
  setSelectedDeclineOfferReason: Dispatch<
    SetStateAction<AllDeclineOfferReasons>
  >;
  setShowItem: Dispatch<SetStateAction<ShowItem>>;
  showItem: ShowItem;
  setShowModal: Dispatch<SetStateAction<ShowModal>>;
  handleReasonSearch: (args: string) => void;
  setSelectedCategory: Dispatch<SetStateAction<AllCategories>>;
  getAdmDeclineOfferReasons: (args: string) => void;
}

const CardManagementDeclineOfferReasons = ({
  allDeclineOfferReasons,
  getAdmDeclineOfferReasons,
  handleReasonSearch,
  selectedDeclineOfferReason,
  setRefreshData,
  setSelectedCategory,
  setSelectedDeclineOfferReason,
  setShowItem,
  setShowModal,
  showItem,
}: CardManagementDeclineOfferReasonsProps) => {
  const [adminData, setAdminData] = useLocalStorage("adminData", "");

  const [showAddModal, setShowAddModal] = useState(false);

  const optimisedSearch = useCallback(debounce(handleReasonSearch), []);

  let createDeclineReasonData = new FormData();
  createDeclineReasonData.append("_method", "POST");

  const createDeclineOfferReason = (reason: string) => {
    createDeclineReasonData.append("name", reason);
    fetch(`${BASE_URL}${DECLINE_OFFER_REASONS}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${adminData.uid}`,
      },
      body: createDeclineReasonData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          toast({
            title: "Decline Offer Reason añadida",
            description: `${reason} añadido a la lista`,
          });
          setRefreshData(true);
          getAdmDeclineOfferReasons("");
          setShowAddModal(false);
        } else {
          toast({
            title: `${data.result.error_msg}`,
            variant: "destructive",
          });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="w-full">
      <div className="flex items-center w-full justify-between mb-4 cursor-pointer">
        <div className="flex items-center">
          <p className="font-bold text-lg">Decline Offer Reasons</p>
          <Input
            className={`border border-border outline-none text-sm ml-6 h-full w-[205px] bg-white mr-4 px-4 pr-11 `}
            placeholder="Search reason"
            type="text"
            onChange={(e) => optimisedSearch(e.target.value)}
          />
        </div>
        <Button onClick={() => setShowAddModal(true)}>+ Add New</Button>
      </div>
      <div className="bg-white border flex justify-between rounded dark:bg-background">
        <div className="w-full px-4  py-4 text-sm h-96 overflow-y-auto flex justify-between text-black">
          <div className="flex flex-col w-full max-h-[1000px] overflow-auto jobpost">
            {allDeclineOfferReasons
              ?.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
              })
              .map((reason, i) => {
                return (
                  <div
                    key={reason.id}
                    className={`hover:bg-slate-100 w-full ${
                      selectedDeclineOfferReason?.name === reason?.name &&
                      "bg-slate-100 font-bold"
                    } cursor-pointer py-2 border-b px-2 flex justify-between items-center`}
                    onClick={() => {
                      setSelectedDeclineOfferReason(reason);
                    }}
                    onMouseEnter={() => {
                      setShowItem((prev) => ({
                        ...prev,
                        trash: i,
                        edit: i,
                        where: "declineoffer-reason",
                      }));
                    }}
                    onMouseLeave={() => {
                      setShowItem((prev) => ({
                        ...prev,
                        trash: -1,
                        edit: -1,
                        where: "",
                      }));
                    }}
                  >
                    <p>{reason.name}</p>
                    <div className="flex items-center gap-3">
                      {showItem.edit === i &&
                        showItem.where === "declineoffer-reason" && (
                          <div className="h-[20px] w-[22px] hover:bg-slate-200 flex items-center justify-center rounded">
                            <div
                              onClick={() =>
                                setShowModal((prev) => ({
                                  ...prev,
                                  edit: true,
                                  section: "declineoffer-reason",
                                }))
                              }
                              className=" hover:bg-gray-100"
                            >
                              <Edit className="h-[10px] fill-slate-700" />
                            </div>
                          </div>
                        )}
                      {showItem.trash === i &&
                        showItem.where === "declineoffer-reason" && (
                          <div
                            onClick={() => {
                              setShowModal((prev) => ({
                                ...prev,
                                delete: true,
                                section: "declineoffer-reason",
                              }));
                            }}
                            className="h-[20px] w-[22px] hover:bg-slate-200 flex items-center justify-center rounded"
                          >
                            <Trash className="h-[14px] fill-slate-700" />
                          </div>
                        )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {showAddModal && (
        <ModalAddManagement
          handleAction={createDeclineOfferReason}
          section="declineoffer-reason"
          setShowModal={setShowAddModal}
          type="add"
          setSelectedCategory={setSelectedCategory}
        />
      )}
    </div>
  );
};

export default CardManagementDeclineOfferReasons;
