import React from "react";
import { Checkbox } from "../ui/Checkbox";
import { useAtom } from "jotai";
import {
  applicantsAmountFilter,
  connectionsAmountFilter,
  employerTypeFilter,
  minMaxCreditsFilter,
} from "src/atoms/atoms";
import { Input } from "../ui/Input";
import { DatePickerWithRange } from "../ui/date-rangepicker";

const FilterEmployers = () => {
  const [applicantsAmount, setApplicantAmount] = useAtom(
    applicantsAmountFilter
  );
  const [connectionsAmount, setConnectionAmount] = useAtom(
    connectionsAmountFilter
  );
  const [employerType, setEmployerType] = useAtom(employerTypeFilter);
  const [minMaxInput, setMinMaxInput] = useAtom(minMaxCreditsFilter);

  const handleEmployerType = (e: string) => {
    if (employerType.includes(e)) {
      setEmployerType((prev: string[]) => [...prev, e]);
    }
  };

  const removeEmployerType = (e: string) => {
    setEmployerType(employerType.filter((type) => type !== e));
  };
  return (
    <div className="flex items-start justify-start relative bg-white border-r py-2 gap-14 mr-3 shadow">
      <div className="flex flex-col pl-4 py-2">
        <h1 className="text-[14px] font-semibold">Employer Type</h1>
        <div className="flex items-center mt-2 gap-4 text-[14px]">
          <Checkbox
            onCheckedChange={(checked) => {
              if (checked) {
                handleEmployerType("1");
              } else {
                removeEmployerType("1");
              }
            }}
          />
          <p>Company</p>
        </div>
        <div className="flex items-center mt-2 gap-4 text-[14px]">
          <Checkbox
            onCheckedChange={(checked) => {
              if (checked) {
                handleEmployerType("2");
              } else {
                removeEmployerType("2");
              }
            }}
          />
          <p>Individual</p>
        </div>
      </div>

      <div className="flex flex-col justify-center  pl-4 rounded py-2">
        <h1 className="text-[14px] font-semibold">Subscription Plan</h1>
        <div className="flex items-center mt-2 gap-4 text-[14px]">
          <Checkbox />
          <p>Anual</p>
        </div>
        <div className="flex items-center mt-2 gap-4 text-[14px]">
          <Checkbox />
          <p>Trimestral</p>
        </div>
        <div className="flex items-center mt-2 gap-4 text-[14px]">
          <Checkbox />
          <p>Mensual</p>
        </div>
      </div>
      <div className="flex flex-col justify-center pl-4 rounded py-2">
        <h1 className="text-[14px] font-semibold">Number of Credits</h1>
        <div>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <div className="w-[40px] ">
              <h3 className="text-sm">From</h3>
            </div>
            <Input
              className="w-[100px]"
              type="number"
              value={minMaxInput.min}
              onChange={(e) => {
                setMinMaxInput((prev) => ({
                  ...prev,
                  min: e.target.value,
                }));
              }}
            />
          </div>
          <div className="flex items-center mt-2 gap-4 text-[14px]">
            <div className="w-[40px]">
              <h3 className="text-sm">To</h3>
            </div>
            <Input
              className="w-[100px]"
              type="number"
              value={minMaxInput.max}
              onChange={(e) => {
                setMinMaxInput((prev) => ({
                  ...prev,
                  max: e.target.value,
                }));
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center  pl-4 rounded  py-2">
        <h1 className="text-[14px] font-semibold">Applicants</h1>
        <div className="flex items-center mt-2 gap-4 text-[14px]">
          <Checkbox
            value={"20"}
            color="white"
            onCheckedChange={(checked) => {
              if (checked) {
                setApplicantAmount("1");
              }
            }}
          />
          <p>1 - 3 </p>
        </div>
        <div className="flex items-center mt-2 gap-4 text-[14px]">
          <Checkbox
            onCheckedChange={(checked) => {
              if (checked) {
                setApplicantAmount("3");
              }
            }}
          />
          <p>3 - 5 </p>
        </div>
        <div className="flex items-center mt-2 gap-4 text-[14px]">
          <Checkbox
            onCheckedChange={(checked) => {
              if (checked) {
                setApplicantAmount("5");
              }
            }}
          />
          <p>5 - 10 </p>
        </div>
        <div className="flex items-center mt-2 gap-4 text-[14px]">
          <Checkbox
            onCheckedChange={(checked) => {
              if (checked) {
                setApplicantAmount("10");
              }
            }}
          />
          <p>Más de 10 </p>
        </div>
      </div>

      <div className="flex flex-col justify-center  pl-4 rounded py-2">
        <h1 className="text-[14px] font-semibold">Connections</h1>
        <div className="flex items-center mt-2 gap-4 text-[14px]">
          <Checkbox
            onCheckedChange={(checked) => {
              if (checked) {
                setConnectionAmount("0");
              }
            }}
          />
          <p>0 - 2 </p>
        </div>
        <div className="flex items-center mt-2 gap-4 text-[14px]">
          <Checkbox
            onCheckedChange={(checked) => {
              if (checked) {
                setConnectionAmount("2");
              }
            }}
          />
          <p>2 - 5 </p>
        </div>
        <div className="flex items-center mt-2 gap-4 text-[14px]">
          <Checkbox
            onCheckedChange={(checked) => {
              if (checked) {
                setConnectionAmount("5");
              }
            }}
          />
          <p>5 - 10 </p>
        </div>
        <div className="flex items-center mt-2 gap-4 text-[14px]">
          <Checkbox
            onCheckedChange={(checked) => {
              if (checked) {
                setConnectionAmount("10");
              }
            }}
          />
          <p>10 - 20 </p>
        </div>
        <div className="flex items-center mt-2 gap-4 text-[14px]">
          <Checkbox
            onCheckedChange={(checked) => {
              if (checked) {
                setConnectionAmount("0");
              }
            }}
          />
          <p>Más de 20 </p>
        </div>
      </div>
      <div className="mt-2">
        <DatePickerWithRange />
      </div>
    </div>
  );
};

export default FilterEmployers;
