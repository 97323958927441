import React, { useState } from "react";
import { JobData } from "./columns";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "src/Hooks/useLocalStorage";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Button } from "../ui/Button";
import { Copy, Edit, MoreHorizontal, Trash } from "lucide-react";
import { toast } from "../ui/use-toast";

interface CellActionProps {
  data: JobData;
}

const CellAction = ({ data }: CellActionProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [adminData, setAdminData] = useLocalStorage("adminData", "");
  const [jobInfo, setJobInfo] = useLocalStorage("jobInfo", "");

  const onCopy = (id: string) => {
    navigator.clipboard.writeText(id);
    toast({
      title: "Company Id copied to clipboard",
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant={"ghost"} className="h-8 w-8 p-0">
          <span className="sr-only">Open menu</span>
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <DropdownMenuItem onClick={() => onCopy(data.job_id)}>
          <Copy className="mr-2 h-4 w-4" />
          Copy Id
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            navigate(`//all-jobs/${data.job_id}`);
            setJobInfo(data);
          }}
        >
          <Edit className="mr-2 h-4 w-4" /> Update
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => console.log("Delete")}>
          <Trash className="mr-2 h-4 w-4" /> Delete
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default CellAction;
