import { AllEmployersData } from "src/types/types";

export const allEmployersContent: AllEmployersData[] = [
    {
        user_id: "1",
        company_id: "1",
        overview: "Business Account",
        date_created: "2023-06-30",
        credits: "33",
        plan: "Premium",
        title: "Ismael's Company",
        email: "ismael-company@gmail.com"
    },
    {
        user_id: "1",
        company_id: "1",
        overview: "Business Account",
        date_created: "2023-06-30",
        credits: "33",
        plan: "Premium",
        title: "Ismael's Company",
        email: "ismael-company@gmail.com"
    },
    {
        user_id: "1",
        company_id: "1",
        overview: "Business Account",
        date_created: "2023-06-30",
        credits: "33",
        plan: "Premium",
        title: "Ismael's Company",
        email: "ismael-company@gmail.com"
    },
    {
        user_id: "1",
        company_id: "1",
        overview: "Business Account",
        date_created: "2023-06-30",
        credits: "33",
        plan: "Premium",
        title: "Ismael's Company",
        email: "ismael-company@gmail.com"
    },
    {
        user_id: "1",
        company_id: "1",
        overview: "Business Account",
        date_created: "2023-06-30",
        credits: "33",
        plan: "Premium",
        title: "Ismael's Company",
        email: "ismael-company@gmail.com"
    }
]