import React from "react";
import useLocalStorage from "src/Hooks/useLocalStorage";
import { Icons } from "../ui/Icons";
import { FlaggedUserReports } from "src/types/types";
import UserReport from "./UserReport";

type Props = {};

const infoUser: FlaggedUserReports[] = [
  {
    client_id_worker: "422",
    date_created: "2023-06-23 17:01:00",
    first_name: "Xin",
    user_id: "974",
    user_first_name: "Ismael",
    user_last_name: "Diaz",
    note: "",
    notified: "0",
    reason_name: "Usuario parece una estafa",
  },
  {
    client_id_worker: "422",
    date_created: "2023-06-23 17:01:00",
    first_name: "Xin",
    user_id: "974",
    user_first_name: "Ismael",
    user_last_name: "Diaz",
    note: "",
    notified: "0",
    reason_name: "Usuario parece una estafa",
  },
  {
    client_id_worker: "422",
    date_created: "2023-06-23 17:01:00",
    first_name: "Xin",
    user_id: "974",
    user_first_name: "Ismael",
    user_last_name: "Diaz",
    note: "",
    notified: "0",
    reason_name: "Usuario parece una estafa",
  },
];

const UserProfile = (props: Props) => {
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");
  return (
    <div className="h-full flex flex-col col-span-2 space-y-6">
      <div className="w-full bg-white p-4 rounded-sm">
        <p className="max-h-40 mt-1 truncate text-xs text-gray-500">
          Created by
          {/* {userInfo.email} */}
        </p>
        <h1 className="text-xl font-semibold py-2 leading-6 text-gray-900">
          {userInfo.first_name} {userInfo.last_name}
        </h1>
        <div className="flex flew-wrap items-center gap-4">
          {userInfo.flagList.map(
            (reason: {
              countreason: string;
              inappropiate_user_reason_id: string;
              reason_name: string;
            }) => (
              <div className="bg-gray-100 text-gray-800 px-2 py-1 rounded flex items-center">
                <p className="text-sm">{reason.reason_name}</p>
                <div className="flex items-center px-2 gap-1 text-gray-500">
                  <p className="text-sm">{reason.countreason}</p>
                  <Icons.error className="h-5 w-4" />
                </div>
              </div>
            )
          )}
        </div>
        <div className="flex flex-col gap-y-4 mt-4">
          <hr className="w-full h-px my-6" />
          <div className="flex flex-col gap-y-6 mt-4">
            {infoUser.map((report) => (
              <div
                key={report.client_id_worker}
                className="ml-2 py-2 pl-4 border-l-2 border-zinc-200"
              >
                <UserReport key={report.client_id_worker} report={report} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
